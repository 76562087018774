import Vue from 'vue';
import Vuex from 'vuex';

// Cookie Modules
import localStoragePlugin from './plugins/local-storage-plugin';
// State Modules
import mapStore from './modules/map-store';
import mapLayers from './modules/map-layers';
import modalStore from './modules/modal-store';
import animation from './modules/animation-control';
import eyePathStore from './modules/eye-path-store';
import utilityStore from './modules/utility-store';
import locationStore from './modules/locations-store';

// State Watchers
import synchronizingLayersPlugin from './plugins/synchronizing-layers-plugin';
import layerWatchPlugin from './plugins/layers-watch-plugin';
import eyePathPlugin from './plugins/eye-path-plugin';
import flyoutMenuUtilPlugin from './plugins/flyout-menu-util-plugin';
import mapPlugin from './plugins/map-plugin';

Vue.use(Vuex);

export default new Vuex.Store({
	// Modules store state information
	modules: {
		mapStore,
		mapLayers,
		modalStore,
		animation,
		eyePathStore,
		utilityStore,
		locationStore,
	},
	plugins: [
		localStoragePlugin,
		synchronizingLayersPlugin,
		layerWatchPlugin,
		eyePathPlugin,
		flyoutMenuUtilPlugin,
		mapPlugin,
	],
});
