export const insertNewElementAfter = (referenceElement, newElement) => {
	referenceElement.parentNode.insertBefore(
		newElement,
		referenceElement.nextSibling
	);
};

export const createSubMenuOption = (classname, text) => {
	const div = document.createElement('div');
	const p = document.createElement('p');
	p.className = `flyout-menu__content-subItems ${classname}`;
	const pText = document.createTextNode(`${text}`);
	p.appendChild(pText);
	div.appendChild(p);
	return div;
};

export const hideMapLabels = (map, hide) => {
	let visible = 'visible';
	!hide ? (visible = 'visible') : (visible = 'none');

	const standardMapLayers = map
		.getStyle()
		.layers.filter(
			(layer) => layer.source === 'composite' && layer.type === 'symbol'
		);

	standardMapLayers.forEach((l) =>
		map.setLayoutProperty(l.id, 'visibility', visible)
	);
};

export const notifyOfEnvironmentChange = (devEnv, toast) => {
	toast(
		`You are now using ${devEnv ? 'DEVELOPMENT' : 'PRODUCTION'} Environment`,
		{
			position: 'top-center',
			timeout: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			draggablePercent: 0.6,
			closeButton: 'button',
			icon: true,
			rtl: false,
		}
	);
};
