import { accuWeatherLayerApiKey } from '../../../utils/api-keys';
import {
	getFirstSymbolLayer,
	latLonToValidString,
} from '../../../utils/helpers';
import {
	searchBoxFeatures,
	selectedFeatureValuesAvg,
} from './temperature-map-utils';
import { createTemperaturePopUp } from './temperature-popup';

// temperature Contour layer
export const addContoursLayer = (map, frames, layerId) => {
	// Adding the contour raster to the map
	map.addLayer(
		{
			id: `${layerId}-layer`,
			source: {
				tiles: [`${frames}`],
				type: 'raster',
				tileSize: 256,
			},
			type: 'raster',
			paint: {
				'raster-opacity': 0.7,
			},
		},
		getFirstSymbolLayer(map)
	);
};
// temperature data values layer
export const addTemperatureValuesLayer = (map, isVisible, layerId) => {
	let opacity = isVisible ? 1 : 0;
	// Adding the data values to the map
	map.addSource(`${layerId}-data-points-source`, {
		type: 'geojson',
		data: {
			type: 'FeatureCollection',
			features: [],
		},
	});

	map.addLayer(
		{
			id: `${layerId}-data-points-layer`,
			type: 'symbol',
			source: `${layerId}-data-points-source`,
			layout: {
				'text-field': ['get', 'temperature'],
				'text-size': 18,
				'text-font': ['Solis Bold', 'Arial Unicode MS Regular'],
				'text-ignore-placement': !isVisible,
			},
			paint: {
				'text-color': '#fff',
				'text-halo-width': 1,
				'text-halo-color': '#333A42',
				'text-opacity': opacity,
			},
		},
		'settlement-label'
	);
};
// Everytime map is moved or zoomed the data values will be re-rendered within a bounding box
export async function updateDataPoints(map, layerId) {
	// setting max and min for lat lng for error handling
	const bounds = map.getBounds();
	const northWest = latLonToValidString(bounds.getNorthWest(), true);
	const southEast = latLonToValidString(bounds.getSouthEast(), false);

	await dataPoints(map, northWest, southEast).then((data) => {
		if (data) {
			map.getSource(`${layerId}-data-points-source`).setData(data);
		}
	});
}

// Gets the response from the temperature data point call to be used as the 'temperature-data-points' source data for the map.AddLayer()
async function dataPoints(map, nW, sE) {
	const minWeight = map.getZoom() <= 5 ? 200 : 50;
	let limit = 500;
	const response = await fetch(
		`https://api.accuweather.com/currentconditions/v1/stations/points?upperLeft=${nW}&lowerRight=${sE}&minWeight=${minWeight}&limit=${limit}&apikey=${accuWeatherLayerApiKey}`
	);
	return await response.json();
}

// Gets the data for the icon to display temperature to user
export async function getDataPoints(map, layerId, event) {
	// Find features inside the bounding box.
	let selectedFeatures = searchBoxFeatures(event, layerId, map, 10);
	if (selectedFeatures.length > 0) {
		let value = selectedFeatureValuesAvg(selectedFeatures);
		createTemperaturePopUp(map, event.lngLat, Math.round(value));
	} else {
		let newValue = 11;
		while (selectedFeatures.length < 1) {
			selectedFeatures = searchBoxFeatures(event, layerId, map, newValue);
			newValue += 1;
		}
		let value = selectedFeatureValuesAvg(selectedFeatures);
		createTemperaturePopUp(map, event.lngLat, Math.round(value));
	}
}
