<template>
	<div
		v-if="menuVisible"
		class="impacts-menu"
	>
		<span
			v-for="type in impactTypes"
			:key="type.id"
			class="impacts-menu-item"
			@click="selectItem"
		>{{ type.replaceAll('_', ' ') }}</span>
	</div>
</template>
<script>
export default {
	data() {
		return {
			menuVisible: false,
			menuItems: document.querySelectorAll('.impacts-menu-item'),
			impactTypes: [
				'RISK_TO_LIFE_PROPERTY',
				'MAXIMUM_SUSTAINED_WINDS',
				'MAXIMUM_WIND_GUSTS',
				'STORM_SURGE',
				'RAINFALL',
			],
		};
	},
	created() {
		this.menuDisplay = this.$store.watch(
			(state, getters) => getters.getEyePathLayers,
			(layers) => {
				layers.includes('tropicalImpacts')
					? (this.menuVisible = true)
					: (this.menuVisible = false);
			}
		);
	},
	beforeDestroy() {
		this.menuDisplay();
	},
	methods: {
		selectItem(el) {
			const activeClass = 'active';
			const menuItems = document.querySelectorAll('.impacts-menu-item');
			el = el.target;
			if (!el.classList.contains(activeClass)) {
				menuItems.forEach((l) => l.classList.remove(activeClass));
				el.classList.add(activeClass);
			} else if (el.classList.contains(activeClass)) {
				el.classList.remove(activeClass);
			}
			this.$store.dispatch('selectImpactType', el.innerText);
		},
	},
};
</script>
