<template>
	<div class="temperature-legend">
		<div class="legend-title">
			<b>{{ title }}</b>
		</div>
		<div class="legend-item">
			<span
				v-for="colorBx in colorBoxes"
				:key="colorBx"
				class="color-rectangle"
				:class="colorBx"
			></span>
		</div>
		<div class="legend-footer">
			<span
				v-for="count in colorText"
				:key="count"
				class="footer-text"
			>{{
				count
			}}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TemperatureLegend',
	data() {
		return {
			title: 'All values are displayed in Fahrenheit',
			colorBoxes: this.legendColorBoxes(),
			colorText: this.legendColorText(),
		};
	},
	methods: {
		legendColorBoxes() {
			let count;
			let countArray = [];
			count = 19;
			for (let index = 1; index <= count; index++) {
				countArray.push(`tmp-${index}`);
			}
			return countArray;
		},
		legendColorText() {
			return ['-50°F', '-20°', '10°', '40°', '70°', '100°', '130°F'];
		},
	},
};
</script>

<style lang="scss">
// Style imports
@import '@/assets/styles/mixins.scss';

@mixin color-fill-mixin($colors, $name) {
	@for $i from 1 through length($colors) {
		.#{$name}-#{$i} {
			background-color: nth($colors, $i);
		}
	}
}

.temperature-legend {
	width: 100%;
	display: flex;
	flex-direction: column;
	font-size: 0.6rem;
	color: #000000;

	@include tablet {
		font-size: 0.8rem;
	}

	.legend-title {
		display: flex;
		justify-content: center;
		margin-bottom: 2px;
		color: #aaafc2;
	}

	.legend-footer {
		color: #878787;
		display: flex;
		justify-content: space-between;
	}

	.legend-item {
		display: flex;
		margin-bottom: 0.2rem;
		/* Temperature Colors */
		$colors: (
			#480c42,
			#7a1570,
			#50376f,
			#614b80,
			#2f3e87,
			#020096,
			#144bed,
			#257fc2,
			#039385,
			#479702,
			#1b8103,
			#ac881f,
			#9e7500,
			#a15711,
			#a83b0e,
			#ba0808,
			#7e0e0e,
			#690e0e,
			#4d0e0e
		);
		@include color-fill-mixin($colors, 'tmp');

		.color-rectangle {
			width: calc(100% / 19);
			height: 0.5rem;
		}
	}
}
</style>
