import { accuWeatherLayerApiKey } from '../../../utils/api-keys';
import { getFirstSymbolLayer } from '../../../utils/helpers';
import VectorLayerLegacy from '../parent-layers/vector-layer-legacy';

export default class PastSnowfallLayer extends VectorLayerLegacy {
	constructor() {
		super();
		this.layerId = 'past-snowfall';
		this.name = 'Past Snowfall';
		this.category = 'wx';
		this.animates = true;
		this.frames = [];
		this.legend = true;
		this.changeOpacity = true;
		this.opacity = 0.7;
		this.type = 'Vector';
	}
	async addToMap(map) {
		await super
			.vectorCallback({
				url: `current/snowfall/accumulated/totals/vector/data/tiles.json?apikey=${accuWeatherLayerApiKey}`,
			})
			.then((layerMeta) => {
				this.apiURL = layerMeta.tiles[0];
				this.frames = layerMeta.vector_layers.sort((a, b) =>
					a.description > b.description ? 1 : +1
				);

				const layerMaxZoom = layerMeta.maxzoom;

				let dupChars = this.frames.filter((f) => {
					return f.id.includes('point');
				});
				super.sendToStore(dupChars);

				this.frames.forEach((frame, index) => {
					if (frame.id.includes('contour')) {
						map.addLayer(
							{
								id: `${this.layerId}-${frame.description}-contour`,
								source: {
									type: 'vector',
									tiles: [`${this.apiURL}?apikey=${accuWeatherLayerApiKey}`],
									maxzoom: layerMaxZoom
								},
								'source-layer': frame.id,
								type: 'fill',
								paint: {
									'fill-opacity': index === 0 || index === 1 ? 0.7 : 0,
									'fill-color': [
										'step',
										['get', 'index'],
										'#d2e4ff',
										1,
										'#b3d2ff',
										2,
										'#89baff',
										3,
										'#6eaaff',
										4,
										'#4a93ff',
										5,
										'#2481ff',
										6,
										'#186cff',
										7,
										'#1d5beb',
										8,
										'#164bc3',
										9,
										'#0e3fa3',
									],
								},
							},
							getFirstSymbolLayer(map)
						);
					} else {
						map.addLayer({
							id: `${this.layerId}-${frame.description}`,
							source: {
								type: 'vector',
								tiles: [`${this.apiURL}?apikey=${accuWeatherLayerApiKey}`],
								maxzoom: layerMaxZoom,
							},
							'source-layer': frame.id,
							type: 'symbol',
							minzoom: 3,
							layout: {
								'text-size': 18,
								'text-font': ['Solis Bold', 'Arial Unicode MS Regular'],
								'text-field': [
									'concat',
									[
										'number-format',
										['get', 'value'],
										{ 'min-fraction-digits': 1, 'max-fraction-digits': 1 },
									],
									'"',
								],
								visibility: index === 0 || index === 1 ? 'visible' : 'none',
								'text-allow-overlap': true,
								'text-ignore-placement': true,
							},
							paint: {
								'text-color': '#fff',
								'text-halo-width': 1,
								'text-halo-color': '#000',
								'text-opacity': ['step', ['get', 'value'], 0, 0.1, 1],
							},
						});
					}

					if (
						!this.active &&
						frame.description ===
							this.store.getters.getSynchedData[
								this.store.getters.getCurrentFrameIndex
							]
					) {
						map.setLayoutProperty(
							`${this.layerId}-${frame.description}`,
							`visibility`,
							'visible'
						);
						map.setPaintProperty(
							`${this.layerId}-${frame.description}-contour`,
							`fill-opacity`,
							0.7
						);
					}
				});
			});
	}

	removeFromMap(map) {
		map.getStyle().layers.forEach((layer) => {
			if (layer.id.includes(`${this.layerId}`)) {
				map.removeLayer(layer.id);
				map.removeSource(layer.source);
			}
		});
		super.removeLayer(this.layerId);
	}

	// Animation Control
	previousLayer(map, time) {
		if (
			map.getStyle().layers.some((l) => l.id.includes(`${this.layerId}`)) &&
			this.frames.find((t) => t.description.includes(time))
		) {
			map.setLayoutProperty(`${this.layerId}-${time}`, 'visibility', 'none');
			map.setPaintProperty(
				`${this.layerId}-${time}-contour`,
				'fill-opacity',
				0
			);
		}
	}

	currentLayer(map, time) {
		if (
			map.getStyle().layers.some((l) => l.id.includes(`${this.layerId}`)) &&
			this.frames.find((t) => t.description.includes(time))
		) {
			map.setLayoutProperty(`${this.layerId}-${time}`, 'visibility', 'visible');
			map.setPaintProperty(
				`${this.layerId}-${time}-contour`,
				'fill-opacity',
				this.store.getters.getOpacity
			);
		}
	}
}
