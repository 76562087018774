import { tryChangeFrame } from "../../services/animation-engine";

export default (store) => {
  store.subscribe((mutation, state) => {
    if (mutation.type === "SET_BASE_TIME_FRAMES") {
      state.animation.baseTimeFrames = [];
      state.animation.baseTimeFrames = mutation.payload.timeFrames;
	  // Sort the array
	  const sortedTimeFrames = state.animation.baseTimeFrames.sort(compareTimeFrames);
      store.commit("SET_SYNCHED_FRAMES", sortedTimeFrames);
    }

    if (mutation.type === "SET_TOP_TIME_FRAMES") {
      // removing currently painted layer to avoid double painting layer on map
      state.mapLayers.activeLayers[0].previousLayer(
        window.mapObj,
        state.animation.synchedData[state.animation.currentFrameIndex]
      );

      // This is the lightning and radar combo restricted time frame down to 30 min or whatever is visible for lightning data.
      if (
        (state.mapLayers.activeLayers[0].name === "Lightning" ||
          state.mapLayers.activeLayers[0].name === "Global Radar") &&
        (state.mapLayers.overlayLayer[0].name === "Lightning" ||
          state.mapLayers.overlayLayer[0].name === "Global Radar")
      ) {
        let synchedData = [];
        let lightningLayer = state.mapLayers.wxLayers.find(
          (l) => l.name === "Lightning"
        );
        lightningLayer.frames.forEach((t) => synchedData.push(t.description));

        // Sort the array
        const sortedTimeFrames = synchedData.sort(compareTimeFrames);
        store.commit("SET_SYNCHED_FRAMES", synchedData);
        tryChangeFrame(state.animation.currentFrameIndex);

        if (state.animation.currentFrameIndex >= synchedData.length) {
          store.commit("SET_FRAME_INDEX", synchedData.length - 1);
          tryChangeFrame(state.animation.currentFrameIndex);
        }
        return;
      }

      state.animation.overlayTimeFrames = mutation.payload.timeFrames;

      let synchedData = state.animation.synchedData;
      if (state.animation?.overlayTimeFrames) {
        state.animation.overlayTimeFrames.forEach((time) => {
          if (synchedData.indexOf(time) === -1) {
            synchedData.push(time);
          }
        });

        if (state.animation.currentFrameIndex >= synchedData.length) {
          store.commit("SET_FRAME_INDEX", synchedData.length - 1);
          tryChangeFrame(state.animation.currentFrameIndex);
        }

        // Sort the array
        const sortedTimeFrames = synchedData.sort(compareTimeFrames);
        store.commit("SET_SYNCHED_FRAMES", synchedData);
        state.mapLayers.activeLayers[0].currentLayer(
          window.mapObj,
          synchedData[state.animation.currentFrameIndex]
        );
      }
    }

    if (mutation.type === "REMOVE_BASE_FRAMES") {
      state.animation.synchedData,
        state.animation.baseTimeFrames,
        (state.animation.overlayTimeFrames = []);
    }

    if (mutation.type === "REMOVE_TOP_FRAMES") {
      // removing currently painted layer to avoid double painting layer on map
      state.mapLayers.activeLayers[0].previousLayer(
        window.mapObj,
        state.animation.synchedData[state.animation.currentFrameIndex]
      );

      state.animation.overlayTimeFrames = [];
      let synchedData = [];
      state.mapLayers.activeLayers[0].frames.forEach((f) =>
        synchedData.push(f.timestamp || f.description)
      );

      if (state.animation.currentFrameIndex >= synchedData.length) {
        store.commit("SET_FRAME_INDEX", synchedData.length - 1);
        state.mapLayers.activeLayers[0].currentLayer(
          window.mapObj,
          synchedData[state.animation.currentFrameIndex]
        );
      }

      // Sort the array
      const sortedTimeFrames = synchedData.sort(compareTimeFrames);
      store.commit("SET_SYNCHED_FRAMES", synchedData);
      tryChangeFrame(state.animation.currentFrameIndex);
    }
  });
};

// Custom comparison function
const compareTimeFrames = (timeFrameA, timeFrameB) => {
  return new Date(timeFrameA) - new Date(timeFrameB);
};
