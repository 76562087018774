import { accuWeatherLayerApiKey } from '../../components/utils/api-keys';
/*
  Below sets up the settings needed and customized
  settings used to make the calls from the SDK.
*/
const sdk = require('accunodesdk');
const publicApiSettings = new sdk.ApiSettings(
	accuWeatherLayerApiKey
	// 'https://mockapidev.azurewebsites.net/'
);
publicApiSettings.timeoutPerTry = 5000; // increase default timeout
const publicServiceProvider = sdk.ServiceModule.create(
	new sdk.SdkSettings(publicApiSettings)
);
const publicTropicalService = publicServiceProvider.tropicalService;

// Internal
const sdkI = require('accunodeinternalsdk');
const internalApiSettings = new sdkI.ApiSettings(
	accuWeatherLayerApiKey
	// 'https://mockapidev.azurewebsites.net/'
);
internalApiSettings.timeoutPerTry = 5000; // increase default timeout
// const internalServiceProvider = sdkI.ServiceModule.create(
// 	new sdkI.SdkSettings(internalApiSettings)
// );
// const internalTropicalService = internalServiceProvider.tropicalService;

const internalServiceProvider = sdkI.ServiceInternalModule.create(
	new sdkI.SdkSettings(internalApiSettings)
);
const internalTropicalService = internalServiceProvider.tropicalService;

/*
  Below are the methods which will get the Tropical Storms,
  Current Position and Information, Historical Track and
  Forecast Track as well.
*/

// Get the Tropical Storms
export async function activeStormsAsync() {
	const request = new sdk.ActiveStormsRequest();
	request.language = 'en-us';
	const response = await publicTropicalService.getActiveStorms(request);
	if (response.hasError) {
		return response.error;
	}
	return response.data;
}

// Get the storms positions
export async function stormPositionsAsync(storm) {
	const request = new sdk.StormPositionRequest();
	request.language = 'en-us';
	request.basinId = storm.basinId;
	request.year = storm.year;
	request.stormId = storm.govId;
	request.details = true;
	request.includeRadiiGeometry = true;
	request.includeLandmarks = true;
	const response = await publicTropicalService.getStormPositions(request);
	if (response.hasError) {
		return response.error;
	}
	return response.data;
}

// Get the storms current position
export async function stormCurrentPositionAsync(storm) {
	const request = new sdk.StormPositionRequest();
	request.language = 'en-us';
	request.basinId = storm.basinId;
	request.year = storm.year;
	request.stormId = storm.govId;
	request.details = true;
	request.includeRadiiGeometry = true;
	request.includeLandmarks = true;
	const response = await publicTropicalService.getCurrentStormPosition(request);
	if (response.hasError) {
		return response.error;
	}
	return response.data;
}

// Get the storms forecast
export async function stormsForecastAsync(storm) {
	const request = new sdk.StormByIdRequest();
	request.language = 'en-us';
	request.basinId = storm.basinId;
	request.year = storm.year;
	request.stormId = storm.govId;
	request.details = true;
	const response = await publicTropicalService.getStormForecasts(request);
	if (response.hasError) {
		return response.error;
	}
	return response.data;
}

// Get the storms by the year
export async function getStormsByYearAsync(storm) {
	const request = new sdk.StormsByYearRequest();
	request.language = 'en-us';
	request.year = storm.year;
	request.details = true;
	const response = await publicTropicalService.getStormsByYear(request);
	if (response.hasError) {
		return response.error;
	}
	return response.data;
}

// Get the storms forecast from INTERNAL
export async function internalStormsForecastAsync(storm) {
	const request = new sdkI.StormByIdRequest();
	request.language = 'en-us';
	request.basinId = storm.basinId;
	request.year = storm.year;
	request.stormId = storm.govId;
	request.details = true;
	request.windowGeometry = true;
	request.radiiGeometry = true;
	const response = await internalTropicalService.getStormForecasts(request);
	if (response.hasError) {
		return [];
	}
	return response.data;
}

// Get the storms ATCF Tracks from INTERNAL
export async function internalStormATCFTracksAsync(storm, modelTime) {
	const request = new sdkI.AtcfStormRequest();
	request.language = 'en-us';
	request.basinId = storm.basinId;
	request.year = storm.year;
	request.depressionNumber = storm.govId;
	request.initializationTime = new Date(modelTime);
	const response = await internalTropicalService.getAtcfStorm(request);
	if (response.hasError) {
		return [];
	}
	return response.data;
}

// Get the storms ATCF Storms from INTERNAL
export async function internalATCFStormsAsync() {
	const request = new sdkI.AtcfActiveStormsRequest();
	request.language = 'en-us';
	const response = await internalTropicalService.getAtcfActiveStorms(request);
	if (response.hasError) {
		return [];
	}
	return response.data;
}

// Get the Tropical Storms
export async function internalActiveStormsAsync() {
	const request = new sdkI.ActiveStormsInternalRequest();
	request.language = 'en-us';
	request.source = 1;
	const response = await internalTropicalService.getActiveStorms(request);
	if (response.hasError) {
		return response.error;
	}
	return response.data;
}

export default {
	stormPositionsAsync,
	stormCurrentPositionAsync,
	stormsForecastAsync,
	activeStormsAsync,
	getStormsByYearAsync,

	internalStormsForecastAsync,
	internalStormATCFTracksAsync,
	internalATCFStormsAsync,
	internalActiveStormsAsync,
};
