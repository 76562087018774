import { accuWeatherLayerApiKey } from '../../../utils/api-keys';
import RasterLayer from '../parent-layers/raster-layer-legacy';
import {
	addContoursLayer,
	addAQIValuesLayer,
	updateDataPoints,
} from './air-quality-map-layers';

let eventPassedMove;

export default class AirQualityCurrentLayer extends RasterLayer {
	constructor() {
		super();
		this.layerId = 'aq-current';
		this.name = 'Air Quality';
		this.category = 'wx';
		this.legend = true;
		this.opacity = 0.7;
	}

	async addToMap(map) {
		await super
			.getApiData({
				url: `current/airquality/PlumeIndex/preferred_product_frames?apikey=${accuWeatherLayerApiKey}`,
			})
			.then((layerMeta) => {
				layerMeta.frames.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
				this.aqFrames = `${layerMeta.url}zxy/${layerMeta.frames[0]}/{z}/{x}/{y}.png?apikey=${accuWeatherLayerApiKey}`;
				// Adding contour map layer
				addContoursLayer(map, this.aqFrames, this.layerId);
				// Adding the data point values layer
				addAQIValuesLayer(map, true, this.layerId);
			});

		// Getting the data points on layer creation
		await updateDataPoints(map, this.layerId);
		// Call when map moves
		eventPassedMove = () => updateDataPoints(map, this.layerId);
		map.on('moveend', eventPassedMove);
	}

	removeFromMap(map) {
		map.off('moveend', eventPassedMove);
		map.getStyle().layers.forEach((layer) => {
			if (layer.id.includes(`${this.layerId}`)) {
				map.removeLayer(layer.id);
				map.removeSource(layer.source);
			}
		});
		super.removeLayer(this.layerId);
	}
}
