
import "./registerServiceWorker";
import Vue from "vue";
import App from "./App.vue";
import store from "./store/index";
import "./assets/styles/site.scss";
import { addLayerObjects } from "./components/map-layers/layers";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import hotkeys from "hotkeys-js";

Vue.config.productionTip = false;

addLayerObjects(store);

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 8,
  newestOnTop: true,
});

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");

// Hot Keys
// Toggle UI Visibility
hotkeys("alt+u", function (event, handler) {
  store.dispatch("toggleUIVisibilityState");
});
// Toggle UI Visibility - AccuLogo
hotkeys("alt+k", function (event, handler) {
  store.dispatch("toggleUIVisibilityStateLogo");
});
// Toggle the Radar layer
hotkeys("alt+r", function (event, handler) {
  store.dispatch('callWXLayerObject', 'Global Radar');
});
// Toggle the Satellite layer
hotkeys("alt+s", function (event, handler) {
  store.dispatch('callWXLayerObject', 'RealVue');
});