const mapUtility = {
	hideFlyOutMenu: false,
	devEnvironment: false,
	apiEnvironmentURL: 'api.accuweather.com',
	appVersion: process.env.VUE_APP_VERSION || '0',
	toggledUIVisibilityState: false,
	toggledUIVisibilityStateLogo: false,
};

// Mutations
const mutations = {
	SET_FLYOUT_MENU_DISPLAY(state, hideMenu) {
		state.hideFlyOutMenu = hideMenu;
	},
	SET_PROJECTION_ICON() {
		return;
	},
	SET_API_ENVIRONMENT(state) {
		state.devEnvironment = !state.devEnvironment;
	},
	SET_UI_VISIBILITY_STATE(state) {
		state.toggledUIVisibilityState = !state.toggledUIVisibilityState;
	},
	SET_UI_VISIBILITY_STATE_LOGO(state) {
		state.toggledUIVisibilityStateLogo = !state.toggledUIVisibilityStateLogo;
	},
};

const actions = {
	setFlyOutMenuHidden: ({ commit }, bool) => commit('SET_FLYOUT_MENU_DISPLAY', bool),
	setProjectionIconState: ({ commit }) => commit('SET_PROJECTION_ICON'),
	setAPIEnvironment: ({ commit }) => commit('SET_API_ENVIRONMENT'),
	toggleUIVisibilityState: ({ commit }) => commit('SET_UI_VISIBILITY_STATE'),
	toggleUIVisibilityStateLogo: ({ commit }) => commit('SET_UI_VISIBILITY_STATE_LOGO'),
};

const getters = {
	getFlyOutMenuHidden: (state) => state.hideFlyOutMenu,
	getAPIEnvironmentURL: (state) => state.apiEnvironmentURL,
	getDevEnvironment: (state) => state.devEnvironment,
	getAppVersion: (state) => state.appVersion,
	getUIVisibilityState: (state) => state.toggledUIVisibilityState,
};

export default {
	state: mapUtility,
	mutations,
	actions,
	getters,
};
