const modalState = {
	settingsBoxShow: false,
	zoomLevelBoxShow: false,
	shareBoxShow: false,
	customMapBoxShow: false,
};

// Mutations
const mutations = {
	SHOW_SETTINGS_MODAL(state) {
		state.settingsBoxShow = !state.settingsBoxShow;
	},
	SHOW_ZOOM_LEVEL_MODAL(state) {
		state.zoomLevelBoxShow = !state.zoomLevelBoxShow;
	},
	SHOW_SHARE_MODAL(state, visibility) {
		if (visibility !== undefined) {
			state.shareBoxShow = false;
		} else {
			state.shareBoxShow = !state.shareBoxShow;
		}
	},
	SHOW_CUSTOM_MAP_MODAL(state, visibility) {
		if (visibility !== undefined) {
			state.customMapBoxShow = false;
		} else {
			state.customMapBoxShow = !state.customMapBoxShow;
		}
	},
};

const actions = {
	setSettingsModalVisibility: ({ commit }) => commit('SHOW_SETTINGS_MODAL'),
	setShareModalVisibility: ({ commit }, visibility) =>
		commit('SHOW_SHARE_MODAL', visibility),
	setCustomMapModalVisibility: ({ commit }, visibility) =>
		commit('SHOW_CUSTOM_MAP_MODAL', visibility),
	setZoomLevelModalVisibility: ({ commit }) => commit('SHOW_ZOOM_LEVEL_MODAL'),
};

const getters = {
	getSettingsModalVisibility: (state) => state.settingsBoxShow,
	getZoomLevelModalVisibility: (state) => state.zoomLevelBoxShow,
	getShareModalVisibility: (state) => state.shareBoxShow,
	getCustomMapModalVisibility: (state) => state.customMapBoxShow,
};

export default {
	state: modalState,
	mutations,
	actions,
	getters,
};
