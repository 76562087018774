<template>
	<div>
		<AppUpdateNotification />
		<BaseMap />
		<FlyOutMenu />
		<ControlCenter />
		<LocationSearchBar />
		<TropicalImpactSubMenu />
		<SettingsModal />
		<ShareModal />
		<CustomMapModalVue />
		<DevelopmentEnvModal />
		<ZoomLevelModal />
	</div>
</template>

<script>
import AppUpdateNotification from './components/app-update-notification/AppUpdateNotification.vue';
import BaseMap from './components/BaseMap.vue';
import FlyOutMenu from './components/flyout-menu/FlyOutMenu.vue';
import TropicalImpactSubMenu from './components/flyout-menu/TropicalImpactSubMenu.vue';
import LocationSearchBar from './components/location/LocationSearchBar.vue';
import SettingsModal from './components/modals/SettingsModal.vue';
import ShareModal from './components/modals/ShareModal.vue';
import CustomMapModalVue from './components/modals/CustomMapModal.vue';
import ControlCenter from './components/control-center/ControlCenter.vue';
import DevelopmentEnvModal from './components/modals/DevelopmentEnvModal.vue';
import ZoomLevelModal from './components/modals/ZoomLevelModal.vue';

export default {
	name: 'App',
	components: {
		AppUpdateNotification,
		BaseMap,
		FlyOutMenu,
		TropicalImpactSubMenu,
		LocationSearchBar,
		SettingsModal,
		ShareModal,
		CustomMapModalVue,
		ControlCenter,
		DevelopmentEnvModal,
		ZoomLevelModal,
	},
};
</script>
