import SatelliteLayer from '../satellite-layer';

export default class EnhancedRealVueSatelliteLayer extends SatelliteLayer {
	constructor(frames, apiKey) {
		super(apiKey);
		this.layerId = 'enhanced-realvue';
		this.frames = frames || [];

		this.name = 'Enhanced RealVue';
	}
	async addToMap(map) {
		await super.addToMap({
			map: map,
			url: `satellite/globalColor/preferred_product_frames?apikey=${this.apiKey}&attribute=False`,
			urlEnd: `&display_mode=20`,
		});
	}

	async removeFromMap(map) {
		await super.removeFromMap(map);
	}
}
