<template>
	<div
		ref="legend"
		class="legend"
	>
		<component
			:is="legendType"
			:sat-type="satType"
			:winter-type="winterType"
			:impact-type="impactType"
		/>
	</div>
</template>

<script>
import RadarLegend from './legends/RadarLegend';
import SatelliteLegend from './legends/SatelliteLegend';
import AirQualityLegend from './legends/AirQualityLegend';
import WindFlowLegend from './legends/WindFlowLegend';
import TemperatureLegend from './legends/TemperatureLegend';
import SmokeLegend from './legends/SmokeLegend';
import DynamicWWALegend from './legends/DynamicWWALegend';
import ImpactsLegend from './legends/TropicalImpactsLegend';
import WinterLegend from './legends/WinterLegend';

export default {
	name: 'LegendComponent',
	components: {
		RadarLegend,
		SatelliteLegend,
		AirQualityLegend,
		WindFlowLegend,
		TemperatureLegend,
		SmokeLegend,
		DynamicWWALegend,
		ImpactsLegend,
		WinterLegend,
	},
	props: {
		activeLayers: Array,
		tropicalImpacts: Boolean,
		tropicalImpactSelected: String,
	},
	data() {
		return {
			legendType: null,
			satType: '',
			winterType: '',
			impactType: '',
		};
	},
	watch: {
		activeLayers() {
			if (this.activeLayers?.[0]?.legend) {
				switch (this.activeLayers?.[0]?.name) {
					case 'Global Radar':
					case 'Future Radar':
						this.legendType = RadarLegend;
						this.$refs.legend.className = 'legend';
						break;
					case 'Air Quality':
						this.legendType = AirQualityLegend;
						this.$refs.legend.className = 'legend-no_playbar';
						break;
					case 'Water Vapor':
						this.legendType = SatelliteLegend;
						this.satType = 'water-vapor';
						this.$refs.legend.className = 'legend';
						break;
					case 'RealVue':
						this.legendType = SatelliteLegend;
						this.satType = 'real-vue';
						this.$refs.legend.className = 'legend';
						break;
					case 'Enhanced RealVue':
						this.legendType = SatelliteLegend;
						this.satType = 'enhanced-real-vue';
						this.$refs.legend.className = 'legend';
						break;
					case 'Visible':
						this.legendType = SatelliteLegend;
						this.satType = 'visible';
						this.$refs.legend.className = 'legend';
						break;
					case 'Infrared':
						this.legendType = SatelliteLegend;
						this.satType = 'infrared';
						this.$refs.legend.className = 'legend';
						break;
					case 'Wind Flow':
						this.legendType = WindFlowLegend;
						this.$refs.legend.className = 'legend';
						break;
					case 'Temperature':
						this.legendType = TemperatureLegend;
						this.$refs.legend.className = 'legend-no_playbar';
						break;
					case 'Surface Smoke':
						this.legendType = SmokeLegend;
						this.$refs.legend.className = 'legend';
						break;
					case 'Severe Weather':
						this.legendType = DynamicWWALegend;
						this.$refs.legend.className = 'legend';
						break;
					case 'Snow Forecast':
					case 'Ice Forecast':
					case 'Past Snowfall':
						this.legendType = WinterLegend;
						this.winterType = this.activeLayers?.[0]?.layerId;
						this.$refs.legend.className = 'legend';
						break;
				}

				this.showLegend = true;
			} else {
				this.showLegend = false;
				this.legendType = null;
			}
		},
		tropicalImpacts() {
			if (!this.tropicalImpacts) {
				this.impactType = '';
			}
		},
		tropicalImpactSelected() {
			if (this.tropicalImpactSelected && this.tropicalImpacts === true) {
				this.legendType = ImpactsLegend;
				this.impactType = this.tropicalImpactSelected;
				this.$refs.legend.className = 'legend-no_playbar';
			} else {
				this.impactType = '';
				this.legendType = '';
			}
		},
	},
};
</script>

<style lang="scss">
.legend {
	display: flex;
	justify-content: space-around;
	font-size: 0.8rem;
	position: relative;
	z-index: 2;
	padding: 0.5rem;
	width: 94%;
	left: 3%;
}
.legend-no_playbar {
	display: flex;
	font-size: 0.8rem;
	position: relative;
	z-index: 2;
	padding: 0.5rem;
	width: 100%;
}
</style>
