const animationState = {
	timeFrames: [],
	baseTimeFrames: [],
	overlayTimeFrames: [],
	synchedData: [],
	currentFrameIndex: 0,
	opacity: 0.7,
};

const mutations = {
	// Setting base and top layer time frames
	SET_BASE_TIME_FRAMES(state, payload) {
		return payload;
	},
	SET_TOP_TIME_FRAMES(state, payload) {
		return payload;
	},
	// This is the sychronized frames that we use for playbar and animating wx layers
	SET_SYNCHED_FRAMES(state, payload) {
		state.synchedData = payload;
	},
	// Removing base and top layer time frames
	REMOVE_BASE_FRAMES(state, payload) {
		return payload;
	},
	REMOVE_TOP_FRAMES(state, payload) {
		return payload;
	},
	// Frame index is used to know what time we are at in the synchedData when animating
	SET_FRAME_INDEX(state, payload) {
		state.currentFrameIndex = payload;
	},
	// Clear the frame index for a fresh start to animation
	CLEAR_FRAME_INDEX(state) {
		state.currentFrameIndex = 0;
	},
	// WX layer opacity control
	SET_MAP_LAYER_OPACITY(state, payload) {
		state.opacity = payload;
	},
};

const actions = {
	// Setting base and top layer time frames
	setBaseFrameTimes({ commit }, data) {
		commit('SET_BASE_TIME_FRAMES', data);
	},
	setTopFrameTimes({ commit }, data) {
		commit('SET_TOP_TIME_FRAMES', data);
	},
	// Removing base and top layer time frames
	removeBaseFrames({ commit }, data) {
		commit('REMOVE_BASE_FRAMES', data);
	},
	removeTopFrames({ commit }, data) {
		commit('REMOVE_TOP_FRAMES', data);
	},
	// Frame index is used to know what time we are at in the synchedData when animating
	setFrameIndex({ commit }, data) {
		commit('SET_FRAME_INDEX', data);
	},
	// Clear the frame index for a fresh start to animation
	clearFrameIndex({ commit }) {
		commit('CLEAR_FRAME_INDEX');
	},
	// WX layer opacity control
	setMapLayerOpacity({ commit }, data) {
		commit('SET_MAP_LAYER_OPACITY', data);
	},
};

const getters = {
	getSynchedData(state) {
		return state.synchedData;
	},
	getCurrentFrameIndex(state) {
		return state.currentFrameIndex;
	},
	getOpacity(state) {
		return state.opacity;
	},
};

export default {
	state: animationState,
	mutations,
	actions,
	getters,
};
