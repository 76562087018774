import SatelliteLayer from '../satellite-layer';

export default class VisibleSatelliteLayer extends SatelliteLayer {
	constructor(frames, apiKey) {
		super(apiKey);
		this.layerId = 'visible';
		this.frames = frames || [];

		this.name = 'Visible';
	}
	async addToMap(map) {
		await super.addToMap({
			map: map,
			url: `satellite/globalVIS/preferred_product_frames?apikey=${this.apiKey}&attribute=False`,
		});
	}

	async removeFromMap(map) {
		await super.removeFromMap(map);
	}
}