const mapState = {
	mapStyleObj: [],
	mapStyleName: '',
	hideMapLabels: false,
	projection: localStorage.getItem('mapProjection') || 'mercator',
};

// Mutations
const mutations = {
	/*
		Initial map Style Layer created
	*/
	MAP_STYLE_MOUNT(state, layer) {
		state.mapStyleObj.push(layer);
	},
	MAP_STYLE_LAYER_SELECT(state, layer) {
		return layer;
	},
	CUSTOM_MAP_STYLE_LAYER_SELECT(state, layer) {
		return layer;
	},
	MAP_STYLE_NAME_UPDATE(state, name) {
		state.mapStyleName = name;
	},
	HIDE_MAP_LABELS(state) {
		state.hideMapLabels = !state.hideMapLabels;
	},
	SET_MAP_PROJECTION(state, projectionType) {
		state.projection = projectionType;
	},
};

const actions = {
	/*
		This is the initial mounting of the map and objects call, which then puts the
		objects in the wxLayers collection which will be used to add and remove the map layers using vuex
	*/
	mountStyleClass: ({ commit }, layer) => commit('MAP_STYLE_MOUNT', layer),
	/*
		This is the what is called when a map layer from the menu is selected.
	*/
	callMapStyleObject: ({ commit }, layer) =>
		commit('MAP_STYLE_LAYER_SELECT', layer),
	/*
		Setting a custom map layer, made for design team
	*/
	setCustomMapStyle: ({ commit }, layer) =>
		commit('CUSTOM_MAP_STYLE_LAYER_SELECT', layer),
	/*
		This is the what is called when a map layer from the menu is selected.
	*/
	hideMapLabels: ({ commit }) => commit('HIDE_MAP_LABELS'),
	/*
		This is the what is called when a change to the map projection occurs.
	*/
	setMapProjection: ({ commit }, projectionType) =>
		commit('SET_MAP_PROJECTION', projectionType),
};

const getters = {
	/*
		Used by the BaseMap to monitor when the map base layer / projection should be changed
	*/
	getMapStyle(state) {
		return state.mapStyleObj;
	},
	getMapStyleName: (state) => state.mapStyleName,
	getHideMapLabelsState: (state) => state.hideMapLabels,
	getMapProjection: (state) => state.projection,
};

export default {
	state: mapState,
	mutations,
	actions,
	getters,
};
