export async function notifyUserOfMockData() {
	const userNotificationBackWall = document.createElement('div');
	userNotificationBackWall.className = 'mock-data-notification-banner';

	const notificationTxt = userNotificationBackWall.appendChild(
		document.createElement('p')
	);
	notificationTxt.className = 'notification-txt';
	notificationTxt.innerHTML = `There are no Tropical Storms. You are viewing <b>MOCK DATA</b>.`;

	document.body.appendChild(userNotificationBackWall);
}
