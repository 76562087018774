/* eslint-disable */
import mapboxgl from 'mapbox-gl';

import * as util from '../../../../utils/web-gl-utils';

import vertexSource from './vertex.vert';
import fragSource from './fragment.frag';

import smirPalette from './palette_colors.json';

export default class LocalRadarMapLayer {
	constructor(id, siteInfo, singleSiteUrls) {
		this.type = 'custom';
		this.id = id;
		this.siteInfo = siteInfo;
		this.singleSiteUrls = singleSiteUrls;
		this.ready = false;

		this.rampColors = {};
		smirPalette.mosaic.precip.values.forEach((v) => {
			const dbz = Number(v.level);
			this.rampColors[Number((dbz / 255.0).toFixed(3))] = v.color;
		});

		this.transition = 0;
	}

	onAdd(map, gl) {
		this.gl = gl;
		this.map = map;

		this.program = util.createProgram(gl, vertexSource, fragSource);

		this.quadBuffer = util.createBuffer(
			gl,
			new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1])
		);
		util.bindAttribute(gl, this.quadBuffer, this.program.a_tex_coord, 2);

		let completedTextures = 0;
		const callback = () => {
			completedTextures++;
			if (completedTextures === this.singleSiteTextures.length) {
				this.ready = true;
				this.tryRepaint();
			}
		};
		this.singleSiteTextures = [];
		this.singleSiteUrls.forEach((url) => {
			this.singleSiteTextures.push(
				util.loadTexture(gl, url, callback, gl.NEAREST)
			);
		});
		// Filtering Modes: https://webglfundamentals.org/webgl/lessons/webgl-3d-textures.html
		// NEAREST for nearest neighor & LINEAR for blended

		this.colorRampTexture = util.createTexture(
			gl,
			gl.LINEAR,
			util.getColorRamp(this.rampColors),
			Object.keys(this.rampColors).length,
			1
		);
	}

	mercY(y) {
		const s = Math.sin(Math.PI * (y - 0.5));
		const y2 =
			1.0 - (Math.log((1.0 + s) / (1.0 - s)) / (2 * Math.PI) + 1.0) / 2.0;
		return y2 < 0 ? 0 : y2 > 1 ? 1 : y2;
	}

	render(gl, matrix) {
		if (!this.ready) {
			return;
		}

		const bounds = this.map.getBounds();
		const northWest = mapboxgl.MercatorCoordinate.fromLngLat(
			bounds.getNorthWest()
		);
		const southEast = mapboxgl.MercatorCoordinate.fromLngLat(
			bounds.getSouthEast()
		);

		this.northWest = [northWest.x, northWest.y];
		this.southEast = [southEast.x, southEast.y];

		// Allow the system to linger on the last frame for a bit
		this.transition =
			(this.transition + 0.004) % this.singleSiteTextures.length;
		let index = Math.floor(this.transition);

		util.bindTexture(gl, this.singleSiteTextures[index], 0);
		util.bindTexture(
			gl,
			this.singleSiteTextures[
				Math.min(this.singleSiteTextures.length - 1, index + 1)
			],
			1
		);

		util.bindTexture(gl, this.colorRampTexture, 2);

		const program = this.program;
		gl.useProgram(program.program);

		gl.uniformMatrix4fv(program.u_matrix, false, matrix);
		gl.uniform1i(program.u_site_texture, 0);
		gl.uniform1i(program.u_site_texture_2, 1);
		gl.uniform1i(program.u_color_ramp, 2);

		gl.uniform1f(program.u_transition, this.transition % 1.0);

		gl.uniform2f(program.u_north_west, this.northWest[0], this.northWest[1]);
		gl.uniform2f(program.u_south_east, this.southEast[0], this.southEast[1]);

		gl.uniform2f(
			program.u_site_center,
			(this.siteInfo.lon + 180.0) / 360.0,
			this.mercY((this.siteInfo.lat + 90.0) / 180.0)
		);

		util.bindAttribute(gl, this.quadBuffer, program.program.a_tex_coord, 2);

		gl.enable(gl.BLEND);
		gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
		gl.drawArrays(gl.TRIANGLE_STRIP, 0, 6);
	}

	tryRepaint() {
		if (this.map && this.ready) {
			this.map.triggerRepaint();
		}
	}

	prerender() {
		this.tryRepaint();
	}
}
