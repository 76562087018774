import * as util from '../../../../utils/web-gl-utils';

export default class WindLayer {
	constructor(id, windData) {
		this.type = 'custom';
		this.id = id;
		// Make sure the width and height match up with the server
		this.width = 720;
		this.height = 720;
		this.ready = false;
		this.windData = windData;

		this.uDelta = [-50, 50];
		this.vDelta = [-50, 50];

		// UV min-max is out of 250 so we calculate these
		//   colors based off of a max of 250
		this.rampColors = {
			0.0: '#ede5eb', // 0-7 mph
			0.028: '#d9c4e4', // 13 mph
			0.052: '#bf98db', // 20 mph
			0.08: '#a56dd2', // 30 mph
			0.12: '#8b41c9', // 40 mph
			0.16: '#7437a8', // 50 mph
			0.2: '#55287b', // 60 mph
			0.24: '#2e1643', // 70 mph
			0.28: '#000000', // >75 mph
			0.5: '#ba0908', // 125 mph - threshold to allow blending
			1.0: '#ba0908', // 250 mph
		};

		this.frameDuration = 1000;
	}

	onAdd(map, gl) {
		this.gl = gl;
		this.map = map;

		this.windTextures = {};
		this.loadWindTextures(gl);

		this.quadBuffer = util.createBuffer(
			gl,
			new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1])
		);

		this.colorRampTexture = util.createTexture(
			gl,
			gl.LINEAR,
			util.getColorRamp(this.rampColors),
			Object.keys(this.rampColors).length,
			1
		);
	}

	mercY(y) {
		const s = Math.sin(Math.PI * (y - 0.5));
		const y2 =
			1.0 - (Math.log((1.0 + s) / (1.0 - s)) / (2 * Math.PI) + 1.0) / 2.0;
		return y2 < 0 ? 0 : y2 > 1 ? 1 : y2;
	}

	setView(bbox) {
		this.bbox = bbox;

		const minX = bbox[0];
		const minY = this.mercY(bbox[3]);
		const maxX = bbox[2];
		const maxY = this.mercY(bbox[1]);

		const kx = 2 / (maxX - minX);
		const ky = 2 / (maxY - minY);

		this.matrix = new Float32Array([
			kx,
			0,
			0,
			0,
			0,
			ky,
			0,
			0,
			0,
			0,
			1,
			0,
			-1 - minX * kx,
			-1 - minY * ky,
			0,
			1,
		]);
	}

	loadWindTextures(gl) {
		let completedFrames = 0;
		const maxFrames = this.windData.length;
		const callback = () => {
			completedFrames++;
			if (completedFrames === maxFrames) {
				this.windTexture = this.windTextures[this.windData[0].timestamp];
				this.previousWindTexture = this.windTexture;
				this.ready = true;
				this.tryRepaint();
			}
		};
		for (let i = 0; i < maxFrames; i++) {
			this.windTextures[this.windData[i].timestamp] = util.loadTexture(
				gl,
				this.windData[i].url,
				callback
			);
		}
	}

	changeFrame(frame) {
		this.previousWindTexture = this.windTexture || this.windTextures[frame];
		this.windTexture = this.windTextures[frame];
		this.animationEnd = new Date(Date.now() + this.frameDuration);
	}

	tryRepaint() {
		if (this.map && this.ready) {
			this.map.triggerRepaint();
		}
	}

	prerender() {
		this.tryRepaint();
	}
}
