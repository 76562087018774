/*=====================================

  Helper function for DISPLAYING ICONS

=====================================*/

export const getImagePath = (s, viewType) => {
	let coords = s.position;
	let status = s.status;
	let hemisphere =
		(coords.Latitude || coords.latitude) >= 0 ? 'north' : 'south'; // north or south
	let positionType = s.type === 'current' ? 'forecast' : s.type; // forecast (red icons) or historical (grey icons)
	let iconStatus = getStormIconStatus(
		status.replace(/[\s-]+/g, '')
	).toLowerCase(); // name of the icon

	return `${viewType}/${hemisphere}/${positionType}/${iconStatus}`;
};

const getStormIconStatus = (status) => {
	let iconStatus = status;

	// some positions reuse the same icon.
	switch (status) {
		case 'PotentialTropicalCyclone':
		case 'ExtratropicalStorm':
			iconStatus = 'ExtratropicalStorm';
			break;
		case 'PostTropicalCyclone':
		case 'Subtropical':
		case 'SubtropicalDepression':
		case 'TropicalRainstorm':
			iconStatus = 'TropicalRainStorm';
			break;
		case 'TropicalCycloneCategory1':
		case 'TropicalCycloneCategory2':
		case 'TropicalCycloneCategory3':
		case 'TropicalCycloneCategory4':
		case 'TropicalCycloneCategory5':
			iconStatus = iconStatus.replace('TropicalCyclone', '');
			break;
		case 'HurricaneCategory1':
		case 'HurricaneCategory2':
		case 'HurricaneCategory3':
		case 'HurricaneCategory4':
		case 'HurricaneCategory5':
			iconStatus = iconStatus.replace('Hurricane', '');
			break;
		case 'VerySevereCyclonicStorm':
		case 'VeryIntenseTropicalCyclone':
		case 'SuperCyclonicStorm':
		case 'ExtremelySevereCyclonicStorm':
		case 'IntenseTropicalCyclone':
		case 'Typhoon':
		case 'VeryStrongTyphoon':
		case 'ViolentTyphoon':
		case 'TropicalCyclone':
			iconStatus = 'Category0';
			break;
		case 'SevereCyclonicStorm':
		case 'ModerateTropicalStorm':
		case 'SevereTropicalStorm':
		case 'CyclonicStorm':
		case 'TropicalStorm':
			iconStatus = 'Subtropical';
			break;
		case 'DeepDepression':
		case 'Depression':
		case 'TropicalDepression':
		case 'TropicalDisturbance':
			iconStatus = 'TropicalDepression';
			break;
	}

	return iconStatus;
};
