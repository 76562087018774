import {
	insertNewElementAfter,
	createSubMenuOption,
} from './flyout-menu-utils';
import { windFlowLayerfeatureSelection } from '../map-layers/wx-layers/wind-flow/wind-flow';

export const createWindFlowSubMenu = (mapObj) => {
	let disableWindFlowLayerEvent = () => disablewindFlow(mapObj);
	let disableWindSpeedContourEvent = () => disableWindSpeedContour(mapObj);
	let disableWindSpeedParticleEvent = () => disableWindSpeedParticle(mapObj);

	let windSpeedOptDiv = createSubMenuOption(
		'wind-flow-contours-menu-option highLight',
		'Wind Speed Contours'
	);
	let windParticlesMenuOptDiv = createSubMenuOption(
		'wind-flow-particles-menu-option highLight',
		'Wind Speed Particles'
	);
	let windFlowMenuOption = document.querySelector('.wind-flow-menu-option');
	windFlowMenuOption.onclick = disableWindFlowLayerEvent;
	windSpeedOptDiv.onclick = disableWindSpeedContourEvent;
	windParticlesMenuOptDiv.onclick = disableWindSpeedParticleEvent;

	windFlowMenuOption.innerHTML = '| Wind Flow';
	insertNewElementAfter(windFlowMenuOption, windSpeedOptDiv);
	insertNewElementAfter(windFlowMenuOption, windParticlesMenuOptDiv);
};

export const disablewindFlow = (map) => {
	const layers = map.style._layers;
	for (var key in layers) {
		if (layers[key].id === 'windspeed' || layers[key].id === 'windparticles') {
			map.removeLayer(layers[key].id);
		}
	}
	// All the submenu options
	const windFlowSubMenu = [
		...document.querySelectorAll(
			`.wind-flow-contours-menu-option,
			.wind-flow-particles-menu-option`
		),
	];
	// Removing the submenu options
	windFlowSubMenu.forEach((m) => {
		m.remove();
	});
	// Reset main menu option
	let windFlowMenuOption = document.querySelector('.wind-flow-menu-option');
	windFlowMenuOption.innerHTML = `Wind Flow`;
};

let windSpeedContourOnOff = true;
const disableWindSpeedContour = (map) => {
	let menuOption = document.querySelector('.wind-flow-contours-menu-option');
	const layers = map.style._layers;
	for (var key in layers) {
		if (layers[key].id === 'windspeed') {
			if (windSpeedContourOnOff) {
				windFlowLayerfeatureSelection(false);
				map.setLayoutProperty(layers[key].id, 'visibility', 'none');
				menuOption.classList.remove('highLight');
			} else {
				windFlowLayerfeatureSelection(true);
				map.setLayoutProperty(layers[key].id, 'visibility', 'visible');
				menuOption.classList.add('highLight');
			}
			windSpeedContourOnOff = !windSpeedContourOnOff;
		}
	}
};

let windSpeedParticleOnOff = true;
const disableWindSpeedParticle = (map) => {
	let menuOption = document.querySelector('.wind-flow-particles-menu-option');
	const layers = map.style._layers;
	for (var key in layers) {
		if (layers[key].id === 'windparticles') {
			if (windSpeedParticleOnOff) {
				map.setLayoutProperty(layers[key].id, 'visibility', 'none');
				menuOption.classList.remove('highLight');
			} else {
				map.setLayoutProperty(layers[key].id, 'visibility', 'visible');
				menuOption.classList.add('highLight');
			}
			windSpeedParticleOnOff = !windSpeedParticleOnOff;
		}
	}
};
