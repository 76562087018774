<template>
  <div v-if="showZoomModal" class="zoomLevelModal">
    <p class="zoomLevelModal-text">
        <span class="zoomLevelModal-text_zoomLevel">:</span> {{ zoomLevel }}
    </p>
  </div>
</template>

<script>
export default {
    data() {
        return {
            zoomLevel: 0,
            showZoomModal: false
        }
    },
    computed: {
        zoomLevelChange() {
            return this.$store.getters.getZoomLevelModalVisibility;
        }
    },
    watch: {
        zoomLevelChange: {
            handler() {
                this.showZoomModal = this.zoomLevelChange;
                if (this.showZoomModal) {
                    this.zoomLevel = window.mapObj.getZoom().toFixed(1);
                    this.zoomLevelChangeOn();
                } else {
                    this.zoomLevelChangeOff();
                }
            }
        }
    },
    methods: {
        zoomLevelChangeOn() {
            window.mapObj.on('zoomend', () => {
                this.zoomLevel = window.mapObj.getZoom().toFixed(1);
            });
        },
        zoomLevelChangeOff() {
            window.mapObj.off('zoomend');
        }
    }

}
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

.zoomLevelModal {
    font-family: 'Solis';
	position: absolute;
	display: flex;
    justify-content: center;
    align-items: center;
	top: 10rem;
	right: 1rem;
	height: 1.8rem;
	border-radius: 4px;
	background: rgba(255, 255, 255, 0.95);
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
	min-width: 3rem;
	max-width: 4rem;

    @include tablet {
        top: 1rem;
        right: 19rem;
        min-width: 6rem;
	    max-width: 8rem;
    }

  &-text {
    font-size: 0.8rem;

    &_zoomLevel {
        &:before {
            content: 'ZL';
            
            @include tablet {
              content: 'Zoom Level';
          }
        }
    }
  }
}
</style>