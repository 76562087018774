import BaseLayer from './base-layer';
import { accuWeatherLayerApiKey } from '../../../utils/api-keys';
import { getFirstSymbolLayer } from '../../../utils/helpers';

export default class RasterLayer extends BaseLayer {
	constructor() {
		super();
		this.type = 'Raster';
		this.changeOpacity = true;
		this.apiKey = accuWeatherLayerApiKey;
	}

	// This is manually making the API call to our end point instead of using a delgate paired to the SDK
	async getApiData(data) {
		try {
			const response = await fetch(
				`https://${this.store.getters.getAPIEnvironmentURL}/maps/v1/${data.url}`
			);
			return await response.json();
		} catch (err) {
			console.error(`${this.layerId} API Call Failed`, err);
		}
	}

	// Removes the selected products frames from the selected map if they exist
	async removeFromMap(map) {
		map.getStyle().layers.forEach((layer) => {
			if (layer.id.includes(`${this.layerId}`)) {
				map.removeLayer(layer.id);
				map.removeSource(layer.source);
			}
		});
		super.removeLayer();
	}

	// Creates objects of data that came from the API call in a specific format to be used with the map service
	mapFrameAPI(layerId, frame, url, urlEnd) {
		let imageType = 'png';
		if (layerId.includes('realvue')) {
			imageType = 'jpeg';
		}
		const sourceID = `${layerId}-source-${frame}`;
		return {
			timestamp: frame,
			source: {
				...SOURCE_DEFAULT,
				id: sourceID,
				tiles: [
					`${url}zxy/${frame}/{z}/{x}/{y}.${imageType}?apikey=${this.apiKey}${urlEnd}`,
				],
			},
			layer: {
				...LAYER_DEFAULT,
				id: `${layerId}-${frame}`,
				source: sourceID,
			},
		};
	}

	// Sorts the frames to mitigate the possibility of unordered data going to the map service
	sortFrames(frames) {
		const sortedFrames = frames.sort((a, b) => (a > b ? 1 : +1));
		this.frameTimes = [];
		this.frameTimes = sortedFrames;
		return sortedFrames;
	}

	// Method to try to add the products frames to the map
	tryAddFrames(map, frames) {
		super.sendToStore(this.frameTimes);
		frames.forEach((frame, index) => {
			try {
				map.addSource(frame.source.id, frame.source);
				map.addLayer(frame.layer, getFirstSymbolLayer(map));
				if (this.active && index === 0) {
					map.setPaintProperty(frame.layer.id, `raster-opacity`, this.opacity);
				}
				if (
					!this.active &&
					frame.timestamp ===
						this.store.getters.getSynchedData[
							this.store.getters.getCurrentFrameIndex
						]
				) {
					map.setPaintProperty(frame.layer.id, `raster-opacity`, this.opacity);
				}
			} catch (error) {
				console.log(error);
			}
		});
	}

	// Animation Control
	previousLayer(map, time) {
		if (
			map.getStyle().layers.some((l) => l.id.includes(this.layerId)) &&
			this.frames.find((t) => t.timestamp.includes(time))
		) {
			map.setPaintProperty(`${this.layerId}-${time}`, `raster-opacity`, 0);
		}
	}

	currentLayer(map, time) {
		if (
			map.getStyle().layers.some((l) => l.id.includes(this.layerId)) &&
			this.frames.find((t) => t.timestamp.includes(time))
		) {
			map.setPaintProperty(
				`${this.layerId}-${time}`,
				`raster-opacity`,
				this.store.getters.getOpacity
			);
		}
	}
}

const SOURCE_DEFAULT = {
	type: 'raster',
	tileSize: 256,
};

const LAYER_DEFAULT = {
	type: 'raster',
	minzoom: 0,
	maxzoom: 24,
	paint: {
		'raster-opacity': 0,
		'raster-opacity-transition': {
			duration: 0,
		},
	},
};
