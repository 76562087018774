import mapboxgl from 'mapbox-gl';

export const createTemperaturePopUp = (map, location, value) => {
	// Look to see if there is a pin already
	const oldTmpPin = document.querySelector('.pin');
	// If there is a pin already showing, then remove it
	if (oldTmpPin !== null) {
		oldTmpPin.remove();
	}

	const tmpPin = document.createElement('div');
	tmpPin.className = 'pin';
	const tmpPinImage = tmpPin.appendChild(document.createElement('img'));
	tmpPinImage.className = 'pin-image';
	const tmpPinText = tmpPin.appendChild(document.createElement('div'));
	tmpPinText.className = 'pin-text';

	tmpPinText.innerHTML = `<p class='tmp-value'>${value}</p>
	<p class='tmp-text'><b>&#176;F</b></p>`;
	tmpPinImage.src = require(`../../../../assets/images/aqi/pin_click.png`);

	tmpPin.innerHTML = `
	${tmpPinImage.outerHTML}
	${tmpPinText.outerHTML}
	`;

	new mapboxgl.Marker(tmpPin)
		.setLngLat([location.lng, location.lat])
		.addTo(map);
};
