import store from '../store/index';
export async function layerOverlayService(activeLayer) {
	/*
	 * WE WILL NEED TO ONLY ALLOW FOR RADAR AND LIGHTNING FOR NOW
	 *
	 * - LOGIC IS NEEDED FOR A LAYER OVERLAY HIERARCHY AS WELL (ex. LIGHTNING ALWAYS OVERLAYS RADAR)
	 */
	if (!activeLayer.length) {
		return;
	} else {
		let overlayLayers = [];
		if (
			activeLayer[0].name === 'Global Radar' ||
			activeLayer[0].name === 'Future Radar'
		) {
			store.getters.getNonActiveLayers.forEach((layer) => {
				if (layer.name === 'Lightning' || layer.name === 'Future Radar') {
					overlayLayers.push(layer);
				}
			});
		} else if (activeLayer[0].name === 'Lightning') {
			overlayLayers = [
				store.getters.getNonActiveLayers.find((l) => l.name === 'Global Radar'),
			];
		}
		return overlayLayers;
	}
}
