import store from "../store/index";
import { changeFrame } from "../components/map-layers/wx-layers/wind-flow/wind-flow";

let interval;

export function startAnimatingMapLayers() {
  interval = setInterval(tryChangeFrame, 250);
}
export function stopAnimatingMapLayers() {
  clearInterval(interval);
}

export function tryChangeFrame(frameIdx) {
  const map = window.mapObj;
  const baseLayer = store.getters.getActiveLayers;
  const overlayLayer = store.getters.getOverlayLayers;
  const synchedTimes = store.getters.getSynchedData;
  let currentIdx = store.getters.getCurrentFrameIndex;
  const animatingLayers = baseLayer.concat(overlayLayer);

  animatingLayers.forEach((layer) => {
    if (layer.type === "Raster") {
      layer?.previousLayer(map, synchedTimes[currentIdx]);
    } else if (layer.type === "Vector") {
      if (currentIdx !== synchedTimes.length) {
        if (currentIdx === synchedTimes.length) {
          layer?.previousLayer(map, synchedTimes[currentIdx], true);
        } else {
          layer?.previousLayer(map, synchedTimes[currentIdx], false);
        }
      }
    } else {
      // Custom Wind Flow Layer
      changeFrame(synchedTimes[currentIdx]);
    }
  });

  // Iterating the frame index
  frameIdx ? (currentIdx = frameIdx) : currentIdx++;
  store.dispatch("setFrameIndex", currentIdx);
  // If at the last frame then reset to 0 to start frame loop over
  if (currentIdx === synchedTimes.length) {
    store.dispatch("clearFrameIndex");
    currentIdx = 0;
  }

  animatingLayers.forEach((layer) => {
    // Show next frame
    if (layer.type === "Raster" || layer.type === "Vector") {
      layer?.currentLayer(map, synchedTimes[frameIdx ? frameIdx : currentIdx]);
    }
  });
}
