// CREATE Tropical Storms VIEW MOCK DATA
export const buildActiveStormsMockData = () => {
	let dt = getIteratedDateH(0);
	let yr = new Date(dt).getFullYear();
	// Active Storm
	let activeStormObj = {
		year: yr,
		basinId: 'AL',
		depressionNumber: 2,
		name: 'Faux',
		isMock: true,
		isActive: true,
		isRetired: false,
		isSubtropical: false,
		accuId: 2,
		atcfId: 2,
		govId: 2,
		eventKey: `AL02${yr}`,
		govBaseLink: '',
		accuBaseLink: '',
		atcfBaseLink: '',
	};
	// Individual Active Storm Response Data
	let individualStormObj = createIndividualStormObj();
	return {
		info: activeStormObj,
		current: individualStormObj,
	};
};

const createIndividualStormObj = () => {
	let dt = getIteratedDateH(0);
	dt = new Date(dt);
	// Individual Active Storm Response Data
	return {
		validDateTime: dt.toISOString(),
		validEpochDateTime: Date.now(),
		position: {
			latitude: 28.53,
			longitude: -81.37,
		},
		maxWindGust: {
			metric: {
				Value: 160,
				Unit: 'km/h',
				UnitType: 7,
			},
			imperial: {
				Value: 100,
				Unit: 'mi/h',
				UnitType: 9,
			},
		},
		sustainedWind: {
			metric: {
				Value: 128,
				Unit: 'km/h',
				UnitType: 7,
			},
			imperial: {
				Value: 80,
				Unit: 'mi/h',
				UnitType: 9,
			},
		},
		minimumPressure: {
			metric: {
				Value: 980,
				Unit: 'mb',
				UnitType: 14,
			},
			imperial: {
				Value: 28.93,
				Unit: 'inHg',
				UnitType: 12,
			},
		},
		movement: {
			direction: {
				degrees: 25,
				localized: null,
				english: 'NNE',
			},
			speed: {
				metric: {
					Value: 34,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 15,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
		},
		status: 'Hurricane Category 1',
		localizedStatus: 'Hurricane Category 1',
		isSubtropical: false,
		landmarkReferences: [
			{
				landmark: 'JACKSONVILLE',
				direction: {
					degrees: 352,
					english: 'N',
				},
				range: {
					Metric: {
						Value: 200,
						Unit: 'km',
						UnitType: 6,
					},
					Imperial: {
						Value: 125,
						Unit: 'mi',
						UnitType: 2,
					},
				},
			},
		],
	};
};

// CREATE FEP VIEW MOCK DATA
export const buildFEPMockData = () => {
	// Historical Track For FEP
	let historicalTrack = createHistoricalTrack();
	// Forecast Track For FEP
	let forecastTrack = createForecastTrack();
	// Creating actf points
	let forecastPoints = [];
	// forecastPoints.push(historicalTrack[0].position);
	forecastTrack.forEach((pos) => {
		forecastPoints.push(pos.position);
	});
	let atcfTracks = createATCFTracks(forecastPoints);
	let impacts = createImpactPolygons(historicalTrack);
	return [historicalTrack, forecastTrack, atcfTracks, impacts];
};
const createHistoricalTrack = () => {
	let historicalTrack = [];
	for (let i = 0; i <= 6; i++) {
		let dt = new Date(getIteratedDateH(i - 1));
		let trackObj = {
			validDateTime: dt.toISOString(),
			validEpochDateTime: convertToEpoch(dt),
			position: {
				latitude: 28.53 - (i % 2 ? i * 1.9 : i * 1.5),
				longitude: -81.37 + (i % 2 ? i * 2.6 : i * 2.4),
			},
			maxWindGust: {
				metric: {
					Value: 92.6,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 58,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			sustainedWind: {
				metric: {
					Value: 74.1,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 46,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			minimumPressure: {
				metric: {
					Value: 1000,
					Unit: 'mb',
					UnitType: 14,
				},
				imperial: {
					Value: 29.53,
					Unit: 'inHg',
					UnitType: 12,
				},
			},
			movement: {
				direction: {
					degrees: 340,
					localized: null,
					english: 'NNE',
				},
				speed: {
					metric: {
						Value: 70.4,
						Unit: 'km/h',
						UnitType: 7,
					},
					imperial: {
						Value: 44,
						Unit: 'mi/h',
						UnitType: 9,
					},
				},
			},
			status: i === 0 ? 'Hurricane Category 1' : 'Potential Tropical Cyclone',
			localizedStatus:
				i === 0 ? 'Hurricane Category 1' : 'Potential Tropical Cyclone',
			isSubtropical: false,
		};
		historicalTrack.push(trackObj);
	}
	return historicalTrack;
};
const createForecastTrack = () => {
	const lat = 28.53;
	const lng = -81.37;
	let dateGroup1 = [];
	let dateGroup2 = [];
	for (let i = 0; i <= 7; i++) {
		dateGroup1.push(new Date(getIteratedDateF(i + 2)));
		dateGroup2.push(new Date(getIteratedDateF(i + 3)));
	}
	return [
		// Forecast day 1
		{
			validDateTime: dateGroup1[0].toISOString(),
			validEpochDateTime: convertToEpoch(dateGroup1[0]),
			position: {
				latitude: lat + 1,
				longitude: lng + 1,
			},
			maxWindGust: {
				metric: {
					Value: 92.6,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 58,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			sustainedWind: {
				metric: {
					Value: 74.1,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 46,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			status: 'Hurricane Category 2',
			localizedStatus: 'Hurricane Category 2',
			window: {
				left: { latitude: lat + 1, longitude: lng - 1 },
				right: { latitude: lat + 1, longitude: lng + 1 },
				beginDateTime: dateGroup1[0].toISOString(),
				epochBeginDateTime: convertToEpoch(dateGroup1[0]),
				endDateTime: dateGroup2[0].toISOString(),
				epochEndDateTime: convertToEpoch(dateGroup2[0]),
				beginStatus: null,
				endStatus: null,
				geometry: {
					type: 'Polygon',
					coordinates: [
						[
							[lng, lat], // Bottom Right
							[lng + 1.5, lat + 0.5], // Top Right
							[lng + 0.5, lat + 1.5], // Top Left
							[lng, lat], // Bottom Left
							[lng, lat], // Bottom Right
						],
					],
				},
			},
		},
		// Forecast day 2
		{
			validDateTime: dateGroup1[1].toISOString(),
			validEpochDateTime: convertToEpoch(dateGroup1[1]),
			position: {
				latitude: lat + 2,
				longitude: lng + 0.8,
			},
			maxWindGust: {
				metric: {
					Value: 92.6,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 58,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			sustainedWind: {
				metric: {
					Value: 74.1,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 46,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			status: 'Tropical Cyclone Category 4',
			localizedStatus: 'Tropical Cyclone Category 4',
			window: {
				left: { latitude: lat + 2, longitude: lng - 1 },
				right: { latitude: lat + 2, longitude: lng + 1 },
				beginDateTime: dateGroup1[1].toISOString(),
				epochBeginDateTime: convertToEpoch(dateGroup1[1]),
				endDateTime: dateGroup2[1].toISOString(),
				epochEndDateTime: convertToEpoch(dateGroup2[1]),
				beginStatus: null,
				endStatus: null,
				geometry: {
					type: 'Polygon',
					coordinates: [
						[
							[lng + 1.5, lat + 0.5], // Bottom Right
							[lng + 1.5, lat + 2], // Top Right
							[lng + 0.3, lat + 2], // Top Left
							[lng + 0.5, lat + 1.5], // Bottom Left
							[lng + 1.5, lat + 0.5], // Bottom Right
						],
					],
				},
			},
		},
		// Forecast day 3
		{
			validDateTime: dateGroup1[2].toISOString(),
			validEpochDateTime: convertToEpoch(dateGroup1[2]),
			position: {
				latitude: lat + 3,
				longitude: lng + 0.2,
			},
			maxWindGust: {
				metric: {
					Value: 92.6,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 58,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			sustainedWind: {
				metric: {
					Value: 74.1,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 46,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			status: 'Tropical Cyclone Category 3',
			localizedStatus: 'Tropical Cyclone Category 3',
			window: {
				left: { latitude: lat + 3, longitude: lng - 0.5 },
				right: { latitude: lat + 3, longitude: lng + 0.5 },
				beginDateTime: dateGroup1[2].toISOString(),
				epochBeginDateTime: convertToEpoch(dateGroup1[2]),
				endDateTime: dateGroup2[2].toISOString(),
				epochEndDateTime: convertToEpoch(dateGroup2[2]),
				beginStatus: null,
				endStatus: null,
				geometry: {
					type: 'Polygon',
					coordinates: [
						[
							[lng + 1.5, lat + 2], // Bottom Right
							[lng + 1, lat + 3], // Top Right
							[lng - 1, lat + 3], // Top Left
							[lng + 0.3, lat + 2], // Bottom Left
							[lng + 1.5, lat + 2], // Bottom Right
						],
					],
				},
			},
		},
		// Forecast day 3.5
		{
			validDateTime: dateGroup1[2].toISOString(),
			validEpochDateTime: convertToEpoch(dateGroup1[2]),
			position: {
				latitude: lat + 4,
				longitude: lng - 0.2,
			},
			maxWindGust: {
				metric: {
					Value: 92.6,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 58,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			sustainedWind: {
				metric: {
					Value: 74.1,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 46,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			status: 'Tropical Cyclone Category 3',
			localizedStatus: 'Tropical Cyclone Category 3',
			window: {
				left: { latitude: lat + 4, longitude: lng - 2 },
				right: { latitude: lat + 4, longitude: lng },
				beginDateTime: dateGroup1[2].toISOString(),
				epochBeginDateTime: convertToEpoch(dateGroup1[2]),
				endDateTime: dateGroup2[2].toISOString(),
				epochEndDateTime: convertToEpoch(dateGroup2[2]),
				beginStatus: null,
				endStatus: null,
				geometry: {
					type: 'Polygon',
					coordinates: [
						[
							[lng + 1, lat + 3], // Bottom Right
							[lng + 1.2, lat + 4], // Top Right
							[lng - 1.5, lat + 4], // Top Left
							[lng - 1, lat + 3], // Bottom Left
							[lng + 1, lat + 3], // Bottom Right
						],
					],
				},
			},
		},
		// Forecast day 4
		{
			validDateTime: dateGroup1[3].toISOString(),
			validEpochDateTime: convertToEpoch(dateGroup1[3]),
			position: {
				latitude: lat + 5,
				longitude: lng + 0.2,
			},
			maxWindGust: {
				metric: {
					Value: 92.6,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 58,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			sustainedWind: {
				metric: {
					Value: 74.1,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 46,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			status: 'Tropical Cyclone Category 2',
			localizedStatus: 'Tropical Cyclone Category 2',
			window: {
				left: { latitude: lat + 5, longitude: lng - 1 },
				right: { latitude: lat + 5, longitude: lng + 1.5 },
				beginDateTime: dateGroup1[3].toISOString(),
				epochBeginDateTime: convertToEpoch(dateGroup1[3]),
				endDateTime: dateGroup2[3].toISOString(),
				epochEndDateTime: convertToEpoch(dateGroup2[3]),
				beginStatus: null,
				endStatus: null,
				geometry: {
					type: 'Polygon',
					coordinates: [
						[
							[lng + 1.2, lat + 4], // Bottom Right
							[lng + 1.5, lat + 5], // Top Right
							[lng - 1, lat + 5], // Top Left
							[lng - 1.5, lat + 4], // Bottom Left
							[lng + 1.2, lat + 4], // Bottom Right
						],
					],
				},
			},
		},
		// Forecast day 5
		{
			validDateTime: dateGroup1[4].toISOString(),
			validEpochDateTime: convertToEpoch(dateGroup1[4]),
			position: {
				latitude: lat + 7.5,
				longitude: lng + 2.5,
			},
			maxWindGust: {
				metric: {
					Value: 92.6,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 58,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			sustainedWind: {
				metric: {
					Value: 74.1,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 46,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			status: 'Tropical Cyclone Category 1',
			localizedStatus: 'Tropical Cyclone Category 1',
			window: {
				left: { latitude: lat + 7.5, longitude: lng - 1 },
				right: { latitude: lat + 7.5, longitude: lng + 3 },
				beginDateTime: dateGroup1[4].toISOString(),
				epochBeginDateTime: convertToEpoch(dateGroup1[4]),
				endDateTime: dateGroup2[4].toISOString(),
				epochEndDateTime: convertToEpoch(dateGroup2[4]),
				beginStatus: null,
				endStatus: null,
				geometry: {
					type: 'Polygon',
					coordinates: [
						[
							[lng + 1.5, lat + 5], // Bottom Right
							[lng + 4.8, lat + 7.5], // Top Right
							[lng + 0.5, lat + 7.5], // Top Left
							[lng - 1, lat + 5], // Bottom Left
							[lng + 1.5, lat + 5], // Bottom Right
						],
					],
				},
			},
		},
		// Forecast day 6
		{
			validDateTime: dateGroup1[5].toISOString(),
			validEpochDateTime: convertToEpoch(dateGroup1[5]),
			position: {
				latitude: lat + 9,
				longitude: lng + 4,
			},
			maxWindGust: {
				metric: {
					Value: 92.6,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 58,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			sustainedWind: {
				metric: {
					Value: 74.1,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 46,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			status: 'Tropical Cyclone Category 1',
			localizedStatus: 'Tropical Cyclone Category 1',
			window: {
				left: { latitude: lat + 9, longitude: lng + 1.5 },
				right: { latitude: lat + 9, longitude: lng + 6.5 },
				beginDateTime: dateGroup1[5].toISOString(),
				epochBeginDateTime: convertToEpoch(dateGroup1[5]),
				endDateTime: dateGroup2[5].toISOString(),
				epochEndDateTime: convertToEpoch(dateGroup2[5]),
				beginStatus: null,
				endStatus: null,
				geometry: {
					type: 'Polygon',
					coordinates: [
						[
							[lng + 4.8, lat + 7.5], // Bottom Right
							[lng + 6.5, lat + 9], // Top Right
							[lng + 1.5, lat + 9], // Top Left
							[lng + 0.5, lat + 7.5], // Bottom Left
							[lng + 4.8, lat + 7.5], // Bottom Right
						],
					],
				},
			},
		},
		// Forecast day 7
		{
			validDateTime: dateGroup1[6].toISOString(),
			validEpochDateTime: convertToEpoch(dateGroup1[6]),
			position: {
				latitude: lat + 12,
				longitude: lng + 6,
			},
			maxWindGust: {
				metric: {
					Value: 92.6,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 58,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			sustainedWind: {
				metric: {
					Value: 74.1,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 46,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			status: 'Post Tropical Cyclone',
			localizedStatus: 'Post Tropical Cyclone',
			window: {
				left: { latitude: lat + 12, longitude: lng + 3 },
				right: { latitude: lat + 12, longitude: lng + 9 },
				beginDateTime: dateGroup1[6].toISOString(),
				epochBeginDateTime: convertToEpoch(dateGroup1[6]),
				endDateTime: dateGroup2[6].toISOString(),
				epochEndDateTime: convertToEpoch(dateGroup2[6]),
				beginStatus: null,
				endStatus: null,
				geometry: {
					type: 'Polygon',
					coordinates: [
						[
							[lng + 6.5, lat + 9], // Bottom Right
							[lng + 9, lat + 12], // Top Right
							[lng + 3, lat + 12], // Top Left
							[lng + 1.5, lat + 9], // Bottom Left
							[lng + 6.5, lat + 9], // Bottom Right
						],
					],
				},
			},
		},
		// Forecast day 8
		{
			validDateTime: dateGroup1[7].toISOString(),
			validEpochDateTime: convertToEpoch(dateGroup1[7]),
			position: {
				latitude: lat + 14,
				longitude: lng + 2,
			},
			maxWindGust: {
				metric: {
					Value: 92.6,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 58,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			sustainedWind: {
				metric: {
					Value: 74.1,
					Unit: 'km/h',
					UnitType: 7,
				},
				imperial: {
					Value: 46,
					Unit: 'mi/h',
					UnitType: 9,
				},
			},
			status: 'Tropical Rainstorm',
			localizedStatus: 'Tropical Rainstorm',
			window: {
				left: { latitude: lat + 14, longitude: lng - 2 },
				right: { latitude: lat + 14, longitude: lng + 5 },
				beginDateTime: dateGroup1[7].toISOString(),
				epochBeginDateTime: convertToEpoch(dateGroup1[7]),
				endDateTime: dateGroup2[7].toISOString(),
				epochEndDateTime: convertToEpoch(dateGroup2[7]),
				beginStatus: null,
				endStatus: null,
				geometry: {
					type: 'Polygon',
					coordinates: [
						[
							[lng + 9, lat + 12], // Bottom Right
							[lng + 5, lat + 14], // Top Right
							[lng - 2, lat + 14], // Top Left
							[lng + 3, lat + 12], // Bottom Left
							[lng + 9, lat + 12], // Bottom Right
						],
					],
				},
			},
		},
	];
};
const createATCFTracks = (forecastPoints) => {
	let allATCFTracks = [];

	// creating models to be used
	const models = [
		{ name: 'GFS' },
		{ name: 'ECMWF' },
		{ name: 'HWRF' },
		{ name: 'CMC' },
		{ name: 'UKMET' },
	];

	models.forEach((m, index) => {
		let Forecasts = [];

		forecastPoints.forEach((p, i) => {
			let lng = i === 0 ? -81.37 : p.longitude;
			let lat = i === 0 ? 28.53 : p.latitude;
			if (i !== 0) {
				lng = index > 2 ? p.longitude + index / 2 : p.longitude - index / 2 - 1;
			}

			let Position = {
				Position: {
					Latitude: lat,
					Longitude: lng,
				},
			};
			Forecasts.push(Position);
		});
		let trackObj = {
			Name: m.name,
			color: m.color,
			Forecasts: Forecasts,
		};
		allATCFTracks.push(trackObj);
	});
	return allATCFTracks;
};
const createImpactPolygons = (historicalTrack) => {
	const lat = historicalTrack[0].position.latitude;
	const lng = historicalTrack[0].position.longitude;
	const startDt = new Date();
	const endDt = getIteratedDateF(2);
	const coords1 = [
		[lng + 0.6, lat - 0.5], // Bottom Right
		[lng + 0.8, lat], // Right 1
		[lng + 0.5, lat + 0.4], // Right 2
		[lng + 0.4, lat + 0.6], // Right 3
		[lng + 0.1, lat + 1.2], // Right 4
		[lng - 0.1, lat + 2.2], // Right 5

		[lng, lat + 2.8], // Top Right
		[lng - 0.5, lat + 2.5], // Middle Right
		[lng - 0.8, lat + 2.2], // Middle Left
		[lng - 0.9, lat + 2], // Top Left

		[lng - 1, lat + 0.9], // Left 1
		[lng - 1, lat + 0.7], // Left 2
		[lng - 1, lat + 0.6], // Left 3
		[lng - 0.95, lat + 0.5], // Left 4
		[lng - 0.9, lat + 0.25], // Left 5
		[lng - 0.5, lat - 0.2], // Bottom Left

		[lng + 0.6, lat - 0.5], // Bottom Right
	];
	const coords2 = [
		[lng + 0.6, lat - 0.2], // Bottom Right
		[lng + 0.5, lat], // Right 1
		[lng + 0.2, lat + 0.4], // Right 2
		[lng + 0.2, lat + 0.6], // Right 3
		[lng - 0.1, lat + 1.2], // Right 4
		[lng - 0.3, lat + 2], // Right 5

		[lng, lat + 2.2], // Top Right
		[lng - 0.3, lat + 2], // Middle Right
		[lng - 0.6, lat + 1.8], // Middle Left
		[lng - 0.7, lat + 1.6], // Top Left

		[lng - 0.7, lat + 0.9], // Left 1
		[lng - 0.7, lat + 0.7], // Left 2
		[lng - 0.6, lat + 0.6], // Left 3
		[lng - 0.55, lat + 0.5], // Left 4
		[lng - 0.4, lat + 0.3], // Left 5
		[lng - 0.2, lat - 0.15], // Bottom Left

		[lng + 0.6, lat - 0.2], // Bottom Right
	];

	return [
		//RISK_TO_LIFE_PROPERTY
		{
			categoryType: 'RISK_TO_LIFE_PROPERTY',
			categoryName: 'Moderate',
			startDateTime: new Date(startDt), // '2022-06-20T17:00:00+00:00'
			epochStartDateTime: convertToEpoch(startDt), //1655744400
			endDateTime: new Date(endDt),
			epochEndDateTime: convertToEpoch(endDt),
			impactPolygons: [
				{
					beginValue: null,
					endValue: null,
					units: '',
					geometry: {
						type: 'Polygon',
						coordinates: [coords1],
					},
				},
			],
		},
		{
			categoryType: 'RISK_TO_LIFE_PROPERTY',
			categoryName: 'Extreme',
			startDateTime: new Date(startDt), // '2022-06-20T17:00:00+00:00'
			epochStartDateTime: convertToEpoch(startDt), //1655744400
			endDateTime: new Date(endDt),
			epochEndDateTime: convertToEpoch(endDt),
			impactPolygons: [
				{
					beginValue: null,
					endValue: null,
					units: '',
					geometry: {
						type: 'Polygon',
						coordinates: [coords2],
					},
				},
			],
		},
		//RAINFALL
		{
			categoryType: 'RAINFALL',
			categoryName: '2', // in api it is 1-2
			startDateTime: new Date(startDt), // '2022-06-20T17:00:00+00:00'
			epochStartDateTime: convertToEpoch(startDt), //1655744400
			endDateTime: new Date(endDt),
			epochEndDateTime: convertToEpoch(endDt),
			impactPolygons: [
				{
					beginValue: 1.0,
					endValue: 2.0,
					units: 'in',
					geometry: {
						type: 'Polygon',
						coordinates: [coords1],
					},
				},
			],
		},
		{
			categoryType: 'RAINFALL',
			categoryName: '9', // in api it is >8
			startDateTime: new Date(startDt), // '2022-06-20T17:00:00+00:00'
			epochStartDateTime: convertToEpoch(startDt), //1655744400
			endDateTime: new Date(endDt),
			epochEndDateTime: convertToEpoch(endDt),
			impactPolygons: [
				{
					beginValue: 8,
					endValue: 20,
					units: 'in',
					geometry: {
						type: 'Polygon',
						coordinates: [coords2],
					},
				},
			],
		},
		//STORM_SURGE
		{
			categoryType: 'STORM_SURGE',
			categoryName: '4', // in api it is 3-6
			startDateTime: new Date(startDt), // '2022-06-20T17:00:00+00:00'
			epochStartDateTime: convertToEpoch(startDt), //1655744400
			endDateTime: new Date(endDt),
			epochEndDateTime: convertToEpoch(endDt),
			impactPolygons: [
				{
					beginValue: 3.0,
					endValue: 6.0,
					units: 'ft',
					geometry: {
						type: 'Polygon',
						coordinates: [coords1],
					},
				},
			],
		},
		{
			categoryType: 'STORM_SURGE',
			categoryName: '16', // in api it is >15
			startDateTime: new Date(startDt), // '2022-06-20T17:00:00+00:00'
			epochStartDateTime: convertToEpoch(startDt), //1655744400
			endDateTime: new Date(endDt),
			epochEndDateTime: convertToEpoch(endDt),
			impactPolygons: [
				{
					beginValue: 15,
					endValue: 50,
					units: 'ft',
					geometry: {
						type: 'Polygon',
						coordinates: [coords2],
					},
				},
			],
		},
		//MAXIMUM_SUSTAINED_WINDS
		{
			categoryType: 'MAXIMUM_SUSTAINED_WINDS',
			categoryName: '42', // in api it is 39-56
			startDateTime: new Date(startDt), // '2022-06-20T17:00:00+00:00'
			epochStartDateTime: convertToEpoch(startDt), //1655744400
			endDateTime: new Date(endDt),
			epochEndDateTime: convertToEpoch(endDt),
			impactPolygons: [
				{
					beginValue: 39.0,
					endValue: 56.0,
					units: 'mph',
					geometry: {
						type: 'Polygon',
						coordinates: [coords1],
					},
				},
			],
		},
		{
			categoryType: 'MAXIMUM_SUSTAINED_WINDS',
			categoryName: '120', // in api it is 111-130
			startDateTime: new Date(startDt), // '2022-06-20T17:00:00+00:00'
			epochStartDateTime: convertToEpoch(startDt), //1655744400
			endDateTime: new Date(endDt),
			epochEndDateTime: convertToEpoch(endDt),
			impactPolygons: [
				{
					beginValue: 111.0,
					endValue: 130.0,
					units: 'mph',
					geometry: {
						type: 'Polygon',
						coordinates: [coords2],
					},
				},
			],
		},
		//MAXIMUM_WIND_GUSTS
		{
			categoryType: 'MAXIMUM_WIND_GUSTS',
			categoryName: '75', // in api it is 74-96
			startDateTime: new Date(startDt), // '2022-06-20T17:00:00+00:00'
			epochStartDateTime: convertToEpoch(startDt), //1655744400
			endDateTime: new Date(endDt),
			epochEndDateTime: convertToEpoch(endDt),
			impactPolygons: [
				{
					beginValue: 74,
					endValue: 96,
					units: 'mph',
					geometry: {
						type: 'Polygon',
						coordinates: [coords1],
					},
				},
			],
		},
		{
			categoryType: 'MAXIMUM_WIND_GUSTS',
			categoryName: '165', // in api it is >157
			startDateTime: new Date(startDt), // '2022-06-20T17:00:00+00:00'
			epochStartDateTime: convertToEpoch(startDt), //1655744400
			endDateTime: new Date(endDt),
			epochEndDateTime: convertToEpoch(endDt),
			impactPolygons: [
				{
					beginValue: 157,
					endValue: 200,
					units: 'mph',
					geometry: {
						type: 'Polygon',
						coordinates: [coords2],
					},
				},
			],
		},
	];
};

// HELPER FUNCTIONS
const getIteratedDateH = (d) => {
	let dt = new Date();
	return dt.setDate(dt.getDate() - d);
};
const getIteratedDateF = (d) => {
	let dt = new Date();
	return dt.setDate(dt.getDate() + d);
};
const convertToEpoch = (date) => {
	return Date.parse(date);
};
