import RasterLayer from '../parent-layers/raster-layer';

export default class RadarLayer extends RasterLayer {
	constructor(apiKey) {
		super(apiKey);
	}

	async addToMap(data) {
		let radarMeta = await super.getApiData({
			name: this.layerId,
			url: data.url,
		});
		if (this.layerId === 'past-radar') {
			this.nowIndex = radarMeta?.frames.length - 1;
		}
		this.frames =
			super
				.sortFrames(radarMeta?.frames)
				.map((l) =>
					super.mapFrameAPI(
						this.layerId,
						l,
						`${radarMeta?.url}`,
						`${data.urlEnd || ''}`
					)
				) || [];
		super.tryAddFrames(data.map, this.frames);
	}

	async removeFromMap(map) {
		await super.removeFromMap(map);
	}
}
