import {adjustPolygonLongitudes} from '../utils/tropical-map-bounds';

export const drawRadialData = (map, store, forecastPoints) => {
	// Short circuit logic
	if (!forecastPoints || forecastPoints.length === 0) {
		return;
	}

	const indexedColorDictionary = {
		0: '#618f4c',
		1: '#30780F',
		2: '#133B01',
	};

	// Getting only the wind radials
	let windRadials = forecastPoints.map((pos) => pos?.windRadiiSummary);
	if (windRadials) {
		windRadials.forEach((radialArrays, radialIndex) => {
			if (radialArrays) {
				radialArrays.forEach((summary, summaryIndex) => {
					// Missing data logic
					const radiiSummary = summary?.radiiGeometry;
					if (radiiSummary?.coordinates?.length) {
						// Checking for points that cross the 180th meridian
						const radiiGeo = adjustPolygonLongitudes(radiiSummary);

						map.addLayer({
							id: `trackRadials-${radialIndex}-radial-${summaryIndex}`,
							type: 'fill',
							minzoom: 3,
							source: {
								type: 'geojson',
								data: radiiGeo,
							},
							paint: {
								'fill-color': indexedColorDictionary[summaryIndex],
								'fill-opacity': 0.5,
								'fill-outline-color': '#fff',
							},
						});
					}
				});
			}
		});
	}

	// This will set the wind radials along the forecast track as visible or not with the icons as well
	map.on('zoom', () => {
		if (store.getters.getEyePathLayers.includes('windRadials')) {
			map.getStyle().layers.forEach((layer) => {
				if (layer.id.includes('trackRadials-')) {
					map.setLayoutProperty(layer.id, 'visibility', 'visible');
				}
			});
		} else {
			map.getStyle().layers.forEach((layer) => {
				if (layer.id.includes('trackRadials-')) {
					map.setLayoutProperty(layer.id, 'visibility', 'none');
				}
			});
		}
	});
};
