import { hideMapLabels } from "../../components/flyout-menu/flyout-menu-utils";
export default (store) => {
  store.subscribe((mutation, state) => {
    if (mutation.type === "HIDE_MAP_LABELS") {
      // Finds the obj in the wxLayers Collection
      let map = window.mapObj;
      hideMapLabels(map, state.mapStore.hideMapLabels);
    }
    if (mutation.type === "SET_API_ENVIRONMENT") {
      let map = window.mapObj;
      // Change the API Environment Domain
      state.utilityStore.devEnvironment
        ? (state.utilityStore.apiEnvironmentURL =
            "development.weatherviz.accuweather.com")
        : (state.utilityStore.apiEnvironmentURL = "api.accuweather.com");

      // Check if there is an active layer on map
      if (state.mapLayers.activeLayers.length) {
        // if so, Remove current Active Layer on Map
        state.mapLayers.activeLayers[0].removeFromMap(map);
        store.commit("CLEAR_FRAME_INDEX");
        state.mapLayers.activeLayers[0].addToMap(map);
      }
    }
    if (mutation.type === "SET_UI_VISIBILITY_STATE") {
	/*
		Toggle the UI Visibility of the following elements class:
			- control-center-container
			- location-search-bar
			- mapboxgl-ctrl-top-right
	*/
      // Get all of the elements
      let elementsToToggle = document.querySelectorAll(
        ".control-center-container, .location-search-bar, .mapboxgl-ctrl-top-right"
      );
      // Toggle the class
      elementsToToggle.forEach((element) => {
        element.classList.toggle("hide-ui");
      });
    }
    if (mutation.type === "SET_UI_VISIBILITY_STATE_LOGO") {
      // Toggle the UI Visibility of the following elements class:
      // - accuLogoBacking
      // - accuLogo
      let elementsToToggle = document.querySelectorAll(
        ".accuLogoBacking, .accuLogo"
      );
      // Toggle the class
      elementsToToggle.forEach((element) => {
        element.classList.toggle("hide-ui");
      });
    }
  });
};
