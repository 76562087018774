import SatelliteLayer from '../satellite-layer';

export default class InfraredSatelliteLayer extends SatelliteLayer {
	constructor(frames, apiKey) {
		super(apiKey);
		this.layerId = 'infrared';
		this.frames = frames || [];

		this.name = 'Infrared';
	}
	async addToMap(map) {
		await super.addToMap({
			map: map,
			url: `satellite/globalIR/preferred_product_frames?apikey=${this.apiKey}&attribute=False`,
		});
	}

	async removeFromMap(map) {
		await super.removeFromMap(map);
	}
}