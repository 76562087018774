<template>
	<div
		v-if="overlayOpacityBar"
		class="opacity-overlay-bar"
	>
		<div
			v-if="opacityLayer"
			ref="opacitySlider"
			class="opacity-slider"
		>
			<div>
				<label
					class="_title"
					for="layerOpacity"
				> Opacity </label>
				<select
					ref="opacityLayerSelected"
					class="opacity-slider-layer-selected"
					name="layerOpacity"
					@change="opacitySelect($event)"
				>
					<option
						v-for="(layer, index) in opacityLayersCollection"
						:key="layer.name"
						:value="index"
						class="opacity-slider-layer-option"
					>
						{{ layer.name }}
					</option>
				</select>
			</div>

			<div class="slidecontainer">
				<input
					v-model="rangeValue"
					type="range"
					min="0"
					max="100"
					class="opacity-range"
					@input="changeOpacity"
					@value="rangeValue"
				/>
				<span
					ref="slideValue"
					class="_value"
				>
					{{ rangeValue }}
				</span>
			</div>
		</div>
		<div
			v-if="overlayLayers"
			ref="overlayLayers"
			class="overlay-layers"
		>
			<div>
				<label
					class="_title"
					for="layerOverlay"
				> Overlay </label>
				<select
					class="overlay-layer-selected"
					name="layerOverlay"
					@change="overlaySelect($event)"
				>
					<option
						v-for="(layer, index) in overlayLayersCollection"
						:key="layer.name"
						:value="index"
						class="overlay-layer-option"
					>
						{{ layer.name }}
					</option>
				</select>
			</div>
		</div>
	</div>
</template>

<script>
import { debounce } from 'debounce';
import { layerOverlayService } from '../../services/layer-overlay-service';

export default {
	name: 'OpacitySlider',
	props: {
		playBarTimes: Array,
		devAPIEnvironment: Boolean,
		activeLayers: Array,
		mapStyle: String,
		animationIndex: Number,
	},
	data() {
		return {
			opacityLayer: false,
			overlayLayers: false,
			title: 'Opacity',
			rangeValue: 100,
			opacityLayersCollection: [],
			overlayLayersCollection: [],
			selectedOpacityLayer: '',
		};
	},
	computed: {
		overlayOpacityBar() {
			return this.opacityLayer || this.overlayLayers ? true : false;
		},
		map() {
			return window.mapObj;
		},
	},
	watch: {
		async mapStyle() {
			this.opacityLayersCollection = [];
			this.overlayLayersCollection = [''];
			this.selectedOpacityLayer = '';

			this.hasOpacityLayer();
			this.hasOpacityFeature();

			const overlayLayersTempCollection =
				(await layerOverlayService(this.activeLayers)) || [];
			this.hasOverlayLayer(overlayLayersTempCollection);
		},
		async activeLayers() {
			this.opacityLayersCollection = [];
			this.overlayLayersCollection = [''];
			this.selectedOpacityLayer = '';

			this.hasOpacityLayer();
			this.hasOpacityFeature();

			const overlayLayersTempCollection = await layerOverlayService(
				this.activeLayers
			);
			this.hasOverlayLayer(overlayLayersTempCollection);
		},
		overlayLayers() {
			if (!this.overlayLayers) {
				this.$store.dispatch('clearOverlayLayer');
			}
		},
		devAPIEnvironment() {
			const select = document.querySelector('.overlay-layer-selected');
			if (select) {
				select.value = '0';
			}
		},
	},
	methods: {
		hasOverlayLayer(arr) {
			if (!arr?.length) {
				this.overlayLayers = false;
			} else {
				this.overlayLayers = true;
				arr.forEach((layer) => {
					this.overlayLayersCollection.push(layer);
				});
			}
		},
		hasOpacityLayer() {
			this.activeLayers.forEach((layer) => {
				if (layer?.changeOpacity) {
					this.opacityLayer = true;
					this.opacityLayersCollection.push(layer);
				}
			});
		},
		hasOpacityFeature() {
			if (!this.opacityLayersCollection.length) {
				this.opacityLayer = false;
			} else {
				this.selectedOpacityLayer = this.opacityLayersCollection[0];
				this.rangeValue = this.selectedOpacityLayer.opacity * 100;
				this.$store.dispatch(
					'setMapLayerOpacity',
					this.selectedOpacityLayer.opacity
				);
			}
		},
		opacitySelect() {},
		overlaySelect(event) {
			if (this.overlayLayersCollection[event.target.value].name) {
				this.$store.dispatch(
					'setOverlayLayer',
					this.overlayLayersCollection[event.target.value].name
				);
				this.opacityLayersCollection = [];
				this.hasOpacityLayer();
				this.hasOpacityFeature();
				this.selectedOpacityLayer = this.opacityLayersCollection[0];
				this.addToOpacityOptions(event.target.value);
			} else {
				this.$store.dispatch('clearOverlayLayer');
				this.opacityLayersCollection = [];
				this.hasOpacityLayer();
				this.hasOpacityFeature();
				this.selectedOpacityLayer = this.opacityLayersCollection[0];
				this.rangeValue = this.selectedOpacityLayer?.opacity * 100 || null;
			}
		},
		addToOpacityOptions(layer) {
			if (this.overlayLayersCollection[layer]?.changeOpacity) {
				this.opacityLayersCollection.push(this.overlayLayersCollection[layer]);
			}
		},
		changeOpacity: debounce(function () {
			let layerName;
			this.$store.dispatch('setMapLayerOpacity', this.rangeValue / 100);
			let selectedLayer =
				this.opacityLayersCollection[this.$refs.opacityLayerSelected.value];

			this.map.getStyle().layers.forEach((layer) => {
				// Check if it is an animating layer
				if (selectedLayer?.animates) {
					layerName = `${selectedLayer?.layerId}-${
						this.playBarTimes[this.animationIndex]
					}`;
				}
				if (layer.id.toLowerCase().includes(`${selectedLayer?.layerId}`)) {
					if (layer.type === 'raster') {
						this.map.setPaintProperty(
							!selectedLayer?.animates ? layer.id : layerName,
							'raster-opacity',
							this.rangeValue / 100
						);
					}
					if (layer.type === 'fill') {
						this.map.setPaintProperty(
							!selectedLayer?.animates ? layer.id : `${layerName}-contour`,
							'fill-opacity',
							this.rangeValue / 100
						);
					}
				}
			});
		}, 50),
	},
};
</script>

<style lang="scss">
// Style imports
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/colors.scss';
// Primary style for the whole overlay bar
.opacity-overlay-bar {
	z-index: 2;
	width: 94%;
	left: 3%;
	position: relative;
	align-items: center;
	display: flex;
	font-size: 0.7rem;
	color: #000;
	border-radius: 3px 3px 0px 0px;
	padding: 0.3rem;
}

// For the slider bar
.opacity-slider {
	display: flex;
	align-items: center;
	left: 1rem;
	min-width: 50%;
	max-width: 100%;
	margin-right: 1rem;

	&.non-animating {
		left: 10%;
	}

	._title {
		font-weight: bold;
		text-align: center;
	}

	.opacity-slider-layer {
		&-selected {
			font-size: 0.6rem;
			margin-right: 0.4rem;
		}
		&-option {
			font-size: 0.6rem;
		}
	}

	.slidecontainer {
		display: flex;
		flex-direction: row;
		width: 50%;

		.opacity-range {
			appearance: none;
			margin-right: 0.5rem;
			width: 80%;
			height: 0.6rem;
			border-radius: 5px;
			background: #d3d3d3;
			outline: none;
			opacity: 0.7;
			transition: 0.2s;
			transition: opacity 0.2s;

			:hover {
				opacity: 1;
			}

			::-webkit-slider-thumb {
				-webkit-appearance: none;
				appearance: none;
				width: 25px;
				height: 25px;
				border-radius: 50%;
				background: $blood-orange;
				cursor: pointer;
			}

			::-moz-range-thumb {
				width: 25px;
				height: 25px;
				border-radius: 50%;
				background: $blood-orange;
				cursor: pointer;
			}
		}
	}
}

// For the overlay layers drop down
.overlay-layers {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	&.non-animating {
		left: 10%;
	}

	._title {
		font-weight: bold;
		text-align: center;
	}

	.overlay-layer {
		&-selected {
			font-size: 0.6rem;
			margin-right: 0.4rem;
		}
		&-option {
			font-size: 0.6rem;
		}
	}
}
</style>
