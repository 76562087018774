<template>
  <div v-if="menuVisible" class="flyout-menu">
    <div v-show="openedMenu" ref="flyOutNav" class="flyout-menu__flyout">
      <!-- This is the Share button to get a share link for a custom map view that can be sent out
			to others and allows them to load into the env with the exact map layer, style, center coords
			and zoom level you are at. -->
      <div class="flyout-menu__share" @click="shareMapButton">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="21"
          viewBox="0 0 17 21"
        >
          <path
            fill="#fff"
            fill-rule="evenodd"
            d="M17 19.8V21H0v-9h2.267v6.6h12.466V12H17v7.8zM9.61 4.36V16H7.37V4.38L3.583 8.248 2 6.632l5.371-5.485v-.004L8.491 0l.567.58.008-.009L15 6.632 13.417 8.25 9.61 4.359v.001z"
          />
        </svg>
      </div>
      <!-- Menu items container -->
      <div class="flyout-menu__content">
        <div v-for="layer in layers" :key="layer.id">
          <p
            class="flyout-menu__content-titles"
            :class="layer.menuOption.toLowerCase().replace(' ', '-')"
          >
            | {{ layer.menuOption }}
          </p>
          <div class="flyout-menu__content-items">
            <div v-for="option in layer.options" :key="option.id">
              <p
                v-if="!option.smallMenuOption"
                :ref="option"
                :class="[
                  { highLight: option == highLight },
                  option.replace(/ /g, '-').toLowerCase() + '-menu-option',
                ]"
                @click="callToLayerStore(option)"
              >
                {{ option }}
              </p>
              <p
                v-if="option.smallMenuOption"
                @click="
                  callToShowSubMenu(createSubMenuId(option.smallMenuOption))
                "
              >
                | {{ option.smallMenuOption }}
              </p>
              <div :id="createSubMenuId(option.smallMenuOption)">
                <p
                  v-for="suboptionItem in option.smallOptions"
                  :key="suboptionItem.id"
                  class="flyout-menu__content-subItems"
                  :class="[
                    suboptionItem.replace(/ /g, '-').toLowerCase() +
                      '-sub-menu-option',
                    { highLight: suboptionItem == highLight },
                  ]"
                  @click="callToLayerStore(suboptionItem)"
                >
                  {{ suboptionItem }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- App version -->
      <div class="flyout-menu__app-version">
        v{{ $store.getters.getAppVersion }}
      </div>
    </div>
    <!-- The flyout menu hamburger button -->
    <div class="flyout-menu__btn" @click="toggleNav">
      <span>&#9776;</span>
    </div>
  </div>
</template>

<script>
import { layersArray } from "./menu-options";
import { disableMenuItem, enableMenuItem } from "../utils/helpers";
import { notifyOfEnvironmentChange } from "./flyout-menu-utils";

export default {
  data() {
    return {
      menuVisible: true,
      layers: layersArray,
      openedMenu: false,
      highLight: null,
    };
  },
  computed: {
    activeMapStyle() {
      return this.$store.getters.getMapStyleName;
    },
    currentMapProjection() {
      return this.$store.getters.getMapProjection;
    },
    hideLabels() {
      return this.$store.getters.getHideMapLabelsState;
    },
    activeLayer() {
      return this.$store.getters.getActiveLayers;
    },
    devAPIEnvironment() {
      return this.$store.getters.getDevEnvironment;
    },
    uiVisibilityState() {
      return this.$store.getters.getUIVisibilityState;
    },
  },
  watch: {
    activeLayer() {
      if (this.activeLayer?.length === 0) {
        this.highLight = null;
      } else {
        this.highLight = this.activeLayer[0].name;
      }
      if (
        this.currentMapProjection === "globe" &&
        this.activeLayer?.length &&
        this.activeLayer[0].name === "Wind Flow"
      ) {
        this.removeWindFlow(true);
      }
    },
    activeMapStyle: {
      handler(newVal, oldVal) {
        if (newVal && oldVal) {
          this.removeMapStyleHighlight(oldVal);
        }
        this.addMapStyleHighlight(newVal);
      },
    },
    currentMapProjection() {
      if (this.currentMapProjection === "globe") {
        disableMenuItem(this.$refs["Wind Flow"][0]);
        if (
          this.activeLayer.length &&
          this.activeLayer[0].name === "Wind Flow"
        ) {
          this.removeWindFlow(true);
        }
      } else {
        enableMenuItem(this.$refs["Wind Flow"][0]);
        if (
          this.activeLayer.length &&
          this.activeLayer[0].name === "Wind Flow"
        ) {
          this.removeWindFlow(false);
        }
      }
    },
    devAPIEnvironment() {
      notifyOfEnvironmentChange(this.devAPIEnvironment, this.$toast);
    },
    uiVisibilityState() {
      const elUIToggle = this.$refs["Toggle UI"];
      if (this.uiVisibilityState) {
        elUIToggle[0].classList.add("highLight");
      } else {
        elUIToggle[0].classList.remove("highLight");
      }
    },
  },
  created() {
    this.menuDisplay = this.$store.watch(
      (state, getters) => getters.getFlyOutMenuHidden,
      (hidden) => {
        if (hidden) {
          this.menuVisible = false;
        } else if (!hidden) {
          this.menuVisible = true;
        }
      }
    );
  },
  beforeDestroy() {
    this.menuDisplay();
  },
  mounted() {
    if (this.menuVisible) {
      this.addMapStyleHighlight(this.activeMapStyle);
    }
  },
  methods: {
    toggleNav() {
      this.openedMenu = !this.openedMenu;
    },
    shareMapButton() {
      this.$store.dispatch("setShareModalVisibility");
    },
    callToShowSubMenu(option) {
      if (document.getElementById(option).style.display === "contents") {
        document.getElementById(option).style.display = "none";
      } else {
        document.getElementById(option).style.display = "contents";
      }
    },
    createSubMenuId(option) {
      if (option) {
        return option.replace(/ /g, "-").toLowerCase();
      }
    },
    callToLayerStore(layer) {
      switch (layer) {
        case "Global Radar":
        case "Single-Site Radar":
        case "Temperature":
        case "Lightning":
        case "Surface Smoke":
        case "Severe Weather":
        case "Wind Flow":
        case "Tropical Storms":
        case "RealVue":
        case "Enhanced RealVue":
        case "Water Vapor":
        case "Visible":
        case "Infrared":
        case "Air Quality":
        case "Past Snowfall":
        case "Ice Forecast":
        case "Snow Forecast":
          this.$store.dispatch("callWXLayerObject", layer);
          break;
        case "Light Basemap":
        case "Dark Basemap":
        case "Terrain Basemap":
          this.$store.dispatch("callMapStyleObject", layer);
          break;
        case "Remove Labels":
          this.$store.dispatch("hideMapLabels", this.hideLabels);
          this.removeLabelTextLogic();
          break;
        case "Custom Map":
          this.$store.dispatch("setCustomMapModalVisibility");
          break;
        case "Toggle UI":
          this.$store.dispatch("toggleUIVisibilityState");
          break;
        case "Change Environment":
          this.$store.dispatch("setAPIEnvironment");
          break;
        case "View Zoom Level":
          this.$store.dispatch("setZoomLevelModalVisibility");
          const element = this.$refs["View Zoom Level"];
          element[0].classList.toggle("highLight");
          break;
      }
    },
    removeLabelTextLogic() {
      const removeLabel = document.querySelector(".remove-labels-menu-option");
      if (this.hideLabels) {
        removeLabel.innerHTML = "Restore Labels";
        removeLabel.classList.add("highLight");
      } else {
        removeLabel.innerHTML = "Remove Labels";
        removeLabel.classList.remove("highLight");
      }
    },
    addMapStyleHighlight(styleName) {
      if (styleName) {
        const element = this.$refs[styleName];
        element[0].classList.add("highLight");
      }
    },
    removeMapStyleHighlight(styleName) {
      if (styleName) {
        const element = this.$refs[styleName];
        element[0].classList.remove("highLight");
      }
    },
    removeWindFlow(bool) {
      let windflowParticles = document.querySelector(
        ".wind-flow-particles-menu-option"
      );
      let windflowContours = document.querySelector(
        ".wind-flow-contours-menu-option"
      );
      if (bool) {
        this.$toast.warning(
          "Wind Flow is not currently available in this view.",
          {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        windflowParticles.style.display = "none";
        windflowContours.style.display = "none";
      } else {
        windflowParticles.style.display = "";
        windflowContours.style.display = "";
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/colors.scss";

.flyout-menu {
  position: relative;
  color: #fff;
  z-index: 8;

  &__app-version {
    margin: auto auto 0;
  }

  &__btn {
    position: absolute;
    top: 12px;
    left: 12px;
    cursor: pointer;
    color: $blood-orange;
    background: rgba(1, 1, 1, 0.5);
    border-radius: 16px;
    height: 48px;
    width: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 24px;

    transition: background 200ms;

    &:hover {
      background-color: white;
    }
  }

  &__share {
    cursor: pointer;
    background: rgba(1, 1, 1, 0.5);
    border-radius: 16px;
    height: 48px;
    width: 48px;
    text-align: center;
    line-height: 48px;
    display: flex;

    margin: 0px 0 24px auto;

    transition: background 200ms;

    svg {
      margin: auto;
    }

    path {
      fill: $blood-orange;
    }

    &:hover {
      background-color: white;
    }
  }

  &__flyout {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 16px 12px 12px 12px;
    left: 0;
    top: 0;
    bottom: 0;
  }

  &__content {
    margin: 8px 0;
    overflow-x: hidden;

    div:not(:last-child) {
      margin-bottom: 10px;
    }

    &-titles {
      margin: 0;
      font-size: 1.2rem;
    }

    &-items {
      padding: 4px;
      margin-left: 8px;
      font-size: 1rem;

      p {
        cursor: pointer;
        &:hover {
          color: $blood-orange;
        }
      }
    }

    &-subItems {
      margin-left: 16px;
      font-size: 14px;

      p {
        &:hover {
          cursor: pointer;
          color: $blood-orange;
        }
      }
    }
  }

  .highLight {
    color: $blood-orange;
  }

  // These sections are designated like this as they have sub-items
  #satellite,
  #radar,
  #winter {
    display: none;
  }
}

// This is meant as a temporary fix to show that the Single-Site Radar product is in "Beta" mode (not a finalized product)
.single-site-radar-sub-menu-option {
  display: flex;
  align-items: center;

  &::before {
    content: "[Beta]";
    color: #ff6dff;
    font-size: 14px;
    padding-right: 4px;
  }
}
</style>
