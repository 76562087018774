<template>
	<div
		v-if="customShareModal"
		class="share-modal"
	>
		<p class="share-modal_title">
			Share Link
		</p>
		<label class="share-modal_label">
			<input
				v-model="hideMenuWhenSharedChecked"
				value="true"
				type="checkbox"
			/>
			Hide Flyout Menu
		</label>
		<div class="share-modal_buttons">
			<button
				class="share-modal_buttons-close"
				@click="closeMapShareModal"
			>
				Close
			</button>
			<button
				class="share-modal_buttons-copy"
				@click="copyButton"
			>
				Copy
			</button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			customShareModal: false,
			hideMenuWhenSharedChecked: false,
		};
	},
	computed: {
		shareBoxShow() {
			return this.$store.getters.getShareModalVisibility;
		},
		customMapBoxShow() {
			return this.$store.getters.getCustomMapModalVisibility;
		},
	},
	watch: {
		shareBoxShow() {
			this.customShareModal = this.shareBoxShow;
		},
		customMapBoxShow() {
			if (this.customMapBoxShow) {
				this.$store.dispatch('setShareModalVisibility', false);
			}
		},
	},
	methods: {
		shareMapButton() {
			if (this.customModalOpen) {
				this.customModalOpen = false;
			}
			this.customShareModal = !this.customShareModal;
		},
		closeMapShareModal() {
			this.$store.dispatch('setShareModalVisibility', false);
		},
		copyButton() {
			this.shareServiceCall(this.$toast, this.hideMenuWhenSharedChecked);
		},
		shareServiceCall() {
			const map = window.mapObj;
			const mapZoom = Math.round(map.getZoom());
			const { lng, lat } = map.getCenter();
			let mapStyle = map
				.getStyle()
				.sprite.replace('sprites', 'styles')
				.match(/^(.*[\\/])/)[0]
				.replace(/\/$/, '');
			let mapProduct = this.$store.getters.getActiveLayers;
			if (mapProduct.length !== 0) {
				mapProduct = mapProduct[0].name;
			}

			const urlString = `${
				window.location.origin
			}/?productName=${mapProduct}&mapStyle=${mapStyle}&zoomLevel=${mapZoom}&lat=${lat.toFixed(
				4
			)}&lng=${lng.toFixed(4)}&menuHidden=${this.hideMenuWhenSharedChecked}`;

			navigator.clipboard
				.writeText(urlString)
				.then(() => {
					/* Alert the copied text */
					this.$toast.success('Url has been copied!', {
						position: 'top-center',
						timeout: 2500,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						draggablePercent: 0.6,
						closeButton: 'button',
						icon: true,
						rtl: false,
					});
				})
				.catch(() => {
					this.$toast.error('The share feature failed.', {
						position: 'top-center',
						timeout: 2500,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						draggablePercent: 0.6,
						closeButton: 'button',
						icon: true,
						rtl: false,
					});
				});
		},
	},
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

.share-modal {
	position: absolute;
	display: flex;
	flex-direction: column;
	left: 40%;
	top: 5rem;
	background-color: rgb(74, 74, 74, 0.9);
	color: #fff;
	border: 1px solid #000;
	border-radius: 5px;
	padding: 1rem;

	@include tablet {
		left: 40%;
	}

	@include desktop {
		left: 45%;
	}

	&_title {
		font-size: 1rem;
		margin: -1rem -1rem 1rem -1rem;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.7);
		padding: 1rem;
	}

	&_label {
		text-align: left;
		margin: 0 0 1rem 0;
		font-size: 0.9rem;
	}

	&_buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		button {
			width: 40%;
			border: 1px solid #000;
			border-radius: 5px;

			@include tablet {
				width: 45%;
			}
		}

		&-copy {
			background-color: rgb(74, 74, 74, 0.9);
			color: #24a0ed;
		}

		&-close {
			color: #680808;
			margin-right: 0.2rem;
		}
	}
}
</style>
