import {adjustLongitudes} from '../utils/tropical-map-bounds';

// HISTORICAL STORM TRACK LINE
export const addStormHistoricalTrackLineToMap = (map, s) => {
	s = adjustLongitudes(s);
	map.addSource('stormTrackLineHistorical', {
		type: 'geojson',
		data: {
			type: 'Feature',
			properties: {},
			geometry: {
				type: 'LineString',
				coordinates: s,
			},
		},
	});
	map.addLayer({
		id: 'tropical-category-layer-stormTrackLineHistorical',
		type: 'line',
		source: 'stormTrackLineHistorical',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': '#666c72',
			'line-width': 2,
		},
	});
};

// FORECAST STORM TRACK LINE
export const addStormForecastTrackLineToMap = (map, s) => {
	s = adjustLongitudes(s);
	map.addSource('stormTrackLineForecast', {
		type: 'geojson',
		data: {
			type: 'Feature',
			properties: {},
			geometry: {
				type: 'LineString',
				coordinates: s,
			},
		},
	});
	map.addLayer({
		id: 'tropical-category-layer-stormTrackLineForecast',
		type: 'line',
		source: 'stormTrackLineForecast',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': '#cf0a0a',
			'line-width': 2,
		},
	});
};
