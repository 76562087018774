import BaseLayer from './base-layer';

export default class VectorLayerLegacy extends BaseLayer {
	constructor(mapCon, store) {
		super(mapCon, store);
		this.type = 'Vector';
	}

	async vectorCallback(data) {
		try {
			const response = await fetch(
				`https://${this.store.getters.getAPIEnvironmentURL}/maps/v1/${data.url}`
			);
			const responseJSON = await response.json();
			responseJSON.vector_layers.sort((a, b) =>
				a.description > b.description ? 1 : -1
			);
			return responseJSON;
		} catch (err) {
			console.error(`${this.layerId} API Call Failed`, err);
		}
	}

	async sendToStore(times) {
		let vectorDataTimes = [];
		times.forEach((f) => {
			vectorDataTimes.push(f.description);
		});

		if (this.active) {
			await this.store.dispatch('setBaseFrameTimes', {
				name: this.layerId,
				typeOfLayer: this.type,
				timeFrames: vectorDataTimes,
			});
		} else {
			await this.store.dispatch('setTopFrameTimes', {
				name: this.layerId,
				typeOfLayer: this.type,
				timeFrames: vectorDataTimes,
			});
		}
	}
	removeLayer() {
		this.active
			? this.store.dispatch('removeBaseFrames', this.layerId)
			: this.store.dispatch('removeTopFrames', this.layerId);
	}
}
