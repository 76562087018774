export default (store) => {
	store.subscribe((mutation, state) => {
		if (mutation.type === 'SET_MAP_PROJECTION') {
			const map = window.mapObj;
			const projection = state.mapStore.projection;
			localStorage.setItem('mapProjection', projection);
			map.setProjection({
				name: projection,
			});
			if (projection === 'globe') {
				map.setZoom(3);
				map.setFog({
					color: 'rgb(186, 210, 235)', // Lower atmosphere
					'high-color': 'rgb(36, 92, 223)', // Upper atmosphere
					'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
					'space-color': 'rgb(11, 11, 25)', // Background color
					'star-intensity': 0.6, // Background star brightness (default 0.35 at low zoooms )
				});
			} else {
				map.setZoom(4);
			}
		}
		if (mutation.type === 'SET_PROJECTION_ICON') {
			const currentGlobeColor =
				state.mapStore.projection === 'globe' ? '#f05514' : '#fff';
			let globeIcon = document.querySelector('.map-projection-icon');
			let globePath = document.querySelector('#globe-path');
			let globeText = document.querySelector('.map-projection-text');

			globeIcon.style.border = `${currentGlobeColor} solid 1px`;
			globePath.style.fill = currentGlobeColor;
			globeText.style.color = currentGlobeColor;
			globeText.innerHTML = state.mapStore.projection === 'globe' ? '2D' : '3D';
		}
	});
};
