import * as util from '../../../../utils/web-gl-utils';

import mapboxgl from 'mapbox-gl';

import drawVert from './shaders/particles/draw.vert';
import drawFrag from './shaders/particles/draw.frag';

import quadVert from './shaders/particles/quad.vert';

import screenFrag from './shaders/particles/screen.frag';
import updateFrag from './shaders/particles/update.frag';

import WindLayer from './wind-layer';

export default class WindParticleLayer extends WindLayer {
	constructor(id, windData, width) {
		super(id, windData);

		this.fadeOpacity = 0.985;
		this.dropRate = 0.003;
		this.dropRateBump = 0.02;

		this.panning = false;

		this.updateWidth(width);

		this.particleSize = 2.0 * window.devicePixelRatio;
	}

	updateWidth(width) {
		this.numberParticles = width * 3;
		this.speedFactor = 0.2 + (1.0 - width / 1920);
		this.speedFactor = Math.max(this.speedFactor, 0.05);
		if (this.gl) {
			this.initializeParticles(this.gl, this.numberParticles);
		}
	}

	onAdd(map, gl) {
		super.onAdd(map, gl);

		this.gl = gl;

		this.drawProgram = util.createProgram(gl, drawVert, drawFrag);
		this.screenProgram = util.createProgram(gl, quadVert, screenFrag);
		this.updateProgram = util.createProgram(gl, quadVert, updateFrag);

		this.framebuffer = gl.createFramebuffer();

		this.resize(gl);

		this.initializeParticles(gl, this.numberParticles);

		const root = this;
		map.on('movestart', () => {
			root.panning = true;
			root.resize(gl);
		});
		map.on('moveend', () => {
			root.initializeParticles(gl, this.numberParticles);
			root.panning = false;
		});
	}

	initializeParticles(gl, count) {
		// we create a square texture where each pixel will hold a particle position encoded as RGBA
		const particleRes = (this.particleStateResolution = Math.ceil(
			Math.sqrt(count)
		));
		this._numParticles = particleRes * particleRes;

		// Update Zoom Level
		this.zoom = this.map.getZoom();
		if (this.zoom < 3) {
			this.particleSize = 1.5 * window.devicePixelRatio;
		} else {
			this.particleSize = 2.0 * window.devicePixelRatio;
		}
		// Define the lat-lon bounds
		const bounds = this.map.getBounds();
		const northWest = mapboxgl.MercatorCoordinate.fromLngLat(
			bounds.getNorthWest()
		);
		const southEast = mapboxgl.MercatorCoordinate.fromLngLat(
			bounds.getSouthEast()
		);

		this.northWest = [northWest.x, northWest.y];
		this.southEast = [southEast.x, southEast.y];
		this.setView([northWest.x, northWest.y, southEast.x, southEast.y]);

		// Randomize the initial particle view
		const particleState = new Uint8Array(this._numParticles * 4);
		for (let i = 0; i < this._numParticles; i++) {
			// Red channel
			particleState[i * 4] = Math.floor(Math.random() * 256);
			// Green channel
			particleState[i * 4 + 1] = Math.floor(Math.random() * 256);
			// Blue channel
			particleState[i * 4 + 2] = Math.floor(Math.random() * 256);
			// Alpha channel
			particleState[i * 4 + 3] = Math.floor(Math.random() * 256);
		}

		// textures to hold the particle state for the current and the next frame
		this.particleStateTexture0 = util.createTexture(
			gl,
			gl.NEAREST,
			particleState,
			particleRes,
			particleRes
		);
		this.particleStateTexture1 = util.createTexture(
			gl,
			gl.NEAREST,
			particleState,
			particleRes,
			particleRes
		);

		// Initialize a simple particle
		const particleIndices = new Float32Array(this._numParticles);
		for (let i = 0; i < this._numParticles; i++) {
			particleIndices[i] = i;
		}
		this.particleIndexBuffer = util.createBuffer(gl, particleIndices);
	}

	resize(gl) {
		const emptyPixels = new Uint8Array(gl.canvas.width * gl.canvas.height * 4);
		// screen textures to hold the drawn screen for the previous and the current frame
		this.backgroundTexture = util.createTexture(
			gl,
			gl.NEAREST,
			emptyPixels,
			gl.canvas.width,
			gl.canvas.height
		);
		this.screenTexture = util.createTexture(
			gl,
			gl.NEAREST,
			emptyPixels,
			gl.canvas.width,
			gl.canvas.height
		);
	}

	render(gl) {
		if (!this.ready || this.panning) {
			return;
		}

		gl.disable(gl.DEPTH_TEST);
		gl.disable(gl.STENCIL_TEST);

		util.bindTexture(gl, this.windTexture, 0);
		util.bindTexture(gl, this.particleStateTexture0, 1);

		this.drawScreen(gl, this.matrix);
		this.updateParticles(gl);
	}

	drawScreen(gl, matrix) {
		// draw the screen into a temporary framebuffer to retain it as the background on the next frame
		util.bindFramebuffer(gl, this.framebuffer, this.screenTexture);
		gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

		gl.clearColor(0, 0, 0, 0);
		gl.clear(gl.COLOR_BUFFER_BIT);

		this.drawTexture(gl, this.backgroundTexture, this.fadeOpacity);

		gl.enable(gl.BLEND);
		gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
		this.drawParticles(gl, matrix);
		gl.disable(gl.BLEND);

		util.bindFramebuffer(gl, null);

		// enable blending to support drawing on top of an existing background (e.g. a map)
		gl.enable(gl.BLEND);
		gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
		this.drawTexture(gl, this.screenTexture, 1.0);
		gl.disable(gl.BLEND);

		// save the current screen as the background for the next frame
		const temp = this.backgroundTexture;
		this.backgroundTexture = this.screenTexture;
		this.screenTexture = temp;
	}

	drawParticles(gl, matrix) {
		const program = this.drawProgram;
		gl.useProgram(program.program);

		gl.uniformMatrix4fv(program.u_matrix, false, matrix);

		util.bindAttribute(gl, this.particleIndexBuffer, program.a_index, 1);
		util.bindTexture(gl, this.colorRampTexture, 2);

		gl.uniform1i(program.u_wind, 0);
		gl.uniform1i(program.u_particles, 1);
		gl.uniform1i(program.u_color_ramp, 2);

		gl.uniform1f(program.u_particles_res, this.particleStateResolution);
		gl.uniform2f(program.u_wind_min, this.uDelta[0], this.vDelta[0]);
		gl.uniform2f(program.u_wind_max, this.uDelta[1], this.vDelta[1]);
		gl.uniform1i(program.u_background_enabled, this.windEnabled);
		gl.uniform1f(program.u_particles_size, this.particleSize);

		gl.uniform2f(program.u_north_west, this.northWest[0], this.northWest[1]);
		gl.uniform2f(program.u_south_east, this.southEast[0], this.southEast[1]);

		gl.drawArrays(gl.POINTS, 0, this._numParticles);
	}

	drawTexture(gl, texture, opacity) {
		const program = this.screenProgram;
		gl.useProgram(program.program);

		util.bindAttribute(gl, this.quadBuffer, program.a_pos, 2);
		util.bindTexture(gl, texture, 2);
		gl.uniform1i(program.u_screen, 2);
		gl.uniform1f(program.u_opacity, opacity);

		gl.drawArrays(gl.TRIANGLES, 0, 6);
	}

	updateParticles(gl) {
		const blendingEnabled = gl.isEnabled(gl.BLEND);
		gl.disable(gl.BLEND);

		util.bindFramebuffer(gl, this.framebuffer, this.particleStateTexture1);
		gl.viewport(
			0,
			0,
			this.particleStateResolution,
			this.particleStateResolution
		);

		const program = this.updateProgram;
		gl.useProgram(program.program);

		util.bindAttribute(gl, this.quadBuffer, program.a_pos, 2);

		gl.uniform1i(program.u_wind, 0);
		gl.uniform1i(program.u_particles, 1);

		gl.uniform1f(program.u_rand_seed, Math.random());
		gl.uniform2f(program.u_wind_res, this.width, this.height);
		gl.uniform2f(program.u_wind_min, this.uDelta[0], this.vDelta[0]);
		gl.uniform2f(program.u_wind_max, this.uDelta[1], this.vDelta[1]);
		if (this.zoom < 5) {
			gl.uniform1f(program.u_speed_factor, this.speedFactor * this.zoom * 0.2);
		} else {
			gl.uniform1f(program.u_speed_factor, this.speedFactor);
		}
		gl.uniform1f(program.u_drop_rate, this.dropRate);
		gl.uniform1f(program.u_drop_rate_bump, this.dropRateBump);

		gl.uniform2f(program.u_north_west, this.northWest[0], this.northWest[1]);
		gl.uniform2f(program.u_south_east, this.southEast[0], this.southEast[1]);

		gl.drawArrays(gl.TRIANGLES, 0, 6);

		if (blendingEnabled) {
			gl.enable(gl.BLEND);
		}

		// swap the particle state textures so the new one becomes the current one
		const temp = this.particleStateTexture0;
		this.particleStateTexture0 = this.particleStateTexture1;
		this.particleStateTexture1 = temp;
	}
}
