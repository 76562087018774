import { hideMapLabels } from '../../components/flyout-menu/flyout-menu-utils';
import { getLocationInformationAsync } from '../../services/api/accuweather';

export default (store) => {
	store.subscribe((mutation, state) => {
		if (mutation.type === 'MAP_STYLE_MOUNT') {
			store.commit('MAP_STYLE_NAME_UPDATE', mutation.payload.styleName);
		}

		if (mutation.type === 'MAP_STYLE_LAYER_SELECT') {
			state.mapStore.mapStyleObj[0].changeStyle(mutation.payload);

			let map = window.mapObj;
			// Stopping animation
			state.mapLayers.activeLayers.forEach((layer) => {
				if (layer.removeFromMap) {
					layer.removeFromMap(map);
				}
			});
			// Setting new mapStyle
			map.setStyle(state.mapStore.mapStyleObj[0].longStyle);
			map.once('render', () => {
				if (state.mapStore.hideMapLabels) {
					hideMapLabels(map, true);
				}
				store.commit(
					'MAP_STYLE_NAME_UPDATE',
					state.mapStore.mapStyleObj[0].styleName
				);
				if (state.mapLayers.activeLayers.length) {
					map.once('idle', () => {
						state.mapLayers.activeLayers.forEach((layer) => {
							layer.addToMap(map);
							if (state.mapStore.hideMapLabels) {
								hideMapLabels(map, true);
							}

							// needed to add legend back as well for the layer
							if (layer.createLegend) {
								layer.createLegend(
									...document.getElementsByClassName('legend')
								);
							}
						});
					});
				}
			});
		}

		if (mutation.type === 'CUSTOM_MAP_STYLE_LAYER_SELECT') {
			let mapID = mutation.payload.match(/[^\\/]+$/)[0];
			const fetchPromise = fetch(
				`https://api.mapbox.com/styles/v1/accuweather-inc/${mapID}.html?title=view&access_token=pk.eyJ1IjoiYWNjdXdlYXRoZXItaW5jIiwiYSI6ImNqZm9lbXFvYTFnMGgzMm52YnhmYWRkaXAifQ.GcWwddU8arGo1e-PkKu0Mg`
			);
			fetchPromise.then((response) => {
				if (response.ok) {
					let map = window.mapObj;
					state.mapStore.mapStyleName = 'Custom Map';
					map.setStyle(`${mutation.payload}`);
					map.once('idle', () => {
						state.mapLayers.activeLayers.forEach((layer) => {
							layer.addToMap(map);
							// needed to add legend back as well for the layer
							if (layer.createLegend) {
								layer.createLegend(
									...document.getElementsByClassName('legend')
								);
							}
						});
					});
				} else {
					alert(`Map ID did not work \n${mutation.payload}`);
				}
			});
		}

		if (
			mutation.type === 'SAVE_LOCATION' ||
			mutation.type === 'REMOVE_SAVED_LOCATION'
		) {
			const lastSearchedLocation = state.locationStore.savedLocations.slice(-1);
			if (lastSearchedLocation.length > 0) {
				getLocationInformationAsync(lastSearchedLocation[0]).then((loc) => {
					const saveLocationCoords = [
						loc.GeoPosition.Longitude,
						loc.GeoPosition.Latitude,
					];
					localStorage.setItem('lastSearchedLocation', saveLocationCoords);
				});
			}
		}
	});
};
