import {
	insertNewElementAfter,
	createSubMenuOption,
} from './flyout-menu-utils';
import store from '../../store';

export const createActiveStormsSubMenu = (map) => {
	const menuOption = document.querySelector('.tropical-storms-menu-option');
	menuOption.classList.remove('highLight');
	menuOption.style.pointerEvents = 'none';

	let trackWindowsMenuOptDiv = null;
	let trackRadialsMenuOptDiv = null;
	let atcfPlotsMenuOptDiv = null;
	let tropicalImpactsMenuOptDiv = null;
	let returnToActiveStormsEvent = () => returnToActiveStormsView();
	let trackWindowsEvent = () =>
		trackWindowsSelected(map, trackWindowsMenuOptDiv);
	let trackRadialsEvent = () =>
		trackRadialsSelected(map, trackRadialsMenuOptDiv);
	let atcfPlotsEvent = () => atcfPlotsSelected(map, atcfPlotsMenuOptDiv);
	let tropicalImpactsEvent = () =>
		tropicalImpactsSelected(map, tropicalImpactsMenuOptDiv);

	// Back to Active Storms menu option
	let allStormsMenuOptDiv = createSubMenuOption(
		'active-storm-return-menu-option',
		'Back To Active Storms'
	);
	// Event to take the user back to the Tropical Storms view
	allStormsMenuOptDiv.onclick = returnToActiveStormsEvent;

	// if products are available for the selected storm, make the menu options for it
	map.getStyle().layers.forEach((layer) => {
		if (layer.id.includes('trackWindow-')) {
			trackWindowsMenuOptDiv = createSubMenuOption(
				'track-windows-menu-option highLight',
				'Windows of Movement'
			);
			trackWindowsMenuOptDiv.onclick = trackWindowsEvent;
			if (!store.getters.getEyePathLayers.includes('windows')) {
				store.dispatch('setEyePathLayer', 'windows');
			}
		}
		if (layer.id.includes('trackRadials-')) {
			trackRadialsMenuOptDiv = createSubMenuOption(
				'track-radials-menu-option',
				'Wind Radials'
			);
			trackRadialsMenuOptDiv.onclick = trackRadialsEvent;
		}
		if (layer.id.includes('atcfPlots-')) {
			atcfPlotsMenuOptDiv = createSubMenuOption(
				'atcf-plots-menu-option',
				'Spaghetti Plots'
			);
			atcfPlotsMenuOptDiv.onclick = atcfPlotsEvent;
		}
		if (layer.id.includes('tropicalImpacts-')) {
			tropicalImpactsMenuOptDiv = createSubMenuOption(
				'tropical-impacts-menu-option',
				'Tropical Impacts'
			);
			tropicalImpactsMenuOptDiv.onclick = tropicalImpactsEvent;
		}
	});

	// creating the submenu
	menuOption.innerHTML = '| Tropical Storms';
	if (tropicalImpactsMenuOptDiv !== null) {
		insertNewElementAfter(menuOption, tropicalImpactsMenuOptDiv);
	}
	if (atcfPlotsMenuOptDiv !== null) {
		insertNewElementAfter(menuOption, atcfPlotsMenuOptDiv);
	}
	if (trackRadialsMenuOptDiv !== null) {
		insertNewElementAfter(menuOption, trackRadialsMenuOptDiv);
	}
	if (trackWindowsMenuOptDiv !== null) {
		insertNewElementAfter(menuOption, trackWindowsMenuOptDiv);
	}
	insertNewElementAfter(menuOption, allStormsMenuOptDiv);
};

export const removeSubMenu = () => {
	// All the submenu options
	const activeStormSubMenu = [
		...document.querySelectorAll(
			`.active-storm-return-menu-option,
			.track-radials-menu-option,
			.track-windows-menu-option,
			.atcf-plots-menu-option,
			.tropical-impacts-menu-option`
		),
	];
	// removing the submenu options
	activeStormSubMenu.forEach((m) => {
		m.remove();
	});

	// removing the impact layer from store, which will remove the submenu
	store.dispatch('removeEyePathLayer', 'tropicalImpacts');
	// Tropical Storms Menu option
	const activeStormsMenuOpt = document.querySelector(
		'.tropical-storms-menu-option'
	);
	// Reactivating the menu option and changing text back
	activeStormsMenuOpt.style.pointerEvents = 'auto';
	activeStormsMenuOpt.innerHTML = `Tropical Storms`;
};

// Submenu Click Events
const returnToActiveStormsView = async () => {
	// Tropical Storms Menu option highlighted again
	const activeStormsMenuOpt = document.querySelector(
		'.tropical-storms-menu-option'
	);
	activeStormsMenuOpt.classList.add('highLight');
	// removing the impact layer from store, which will remove the submenu
	await store.dispatch('removeEyePathLayer', 'tropicalImpacts');
	// This will empty the array in the vuex store so the menu items do not show on initiation.
	await store.dispatch('emptyEyePathLayer');
	// Making the call to deactivate, then activate the Tropical Storms view
	store.dispatch('callWXLayerObject', 'Tropical Storms');
	store.dispatch('callWXLayerObject', 'Tropical Storms');
};
const trackWindowsSelected = (map, item) => {
	// Making the call to deactivate / activate the Windows of Movement Layer
	map.getStyle().layers.forEach((layer) => {
		if (layer.id.includes('trackWindow-')) {
			if (map.getLayoutProperty(layer.id, 'visibility') === 'visible') {
				if (store.getters.getEyePathLayers.includes('windows')) {
					store.dispatch('removeEyePathLayer', 'windows');
				}
				map.setLayoutProperty(layer.id, 'visibility', 'none');
				item
					.querySelector('.flyout-menu__content-subItems')
					.classList.remove('highLight');
			} else {
				if (!store.getters.getEyePathLayers.includes('windows')) {
					store.dispatch('setEyePathLayer', 'windows');
				}
				if (map.getZoom() > 3) {
					map.setLayoutProperty(layer.id, 'visibility', 'visible');
				}
				item.querySelector('.flyout-menu__content-subItems').classList.add('highLight');
			}
		}
	});
};
const trackRadialsSelected = (map, item) => {
	// Making the call to deactivate / activate the Wind Radials Layer
	map.getStyle().layers.forEach((layer) => {
		if (layer.id.includes('trackRadials-')) {
			if (map.getLayoutProperty(layer.id, 'visibility') === 'visible') {
				if (store.getters.getEyePathLayers.includes('windRadials')) {
					store.dispatch('removeEyePathLayer', 'windRadials');
				}
				map.setLayoutProperty(layer.id, 'visibility', 'none');
				item
					.querySelector('.flyout-menu__content-subItems')
					.classList.remove('highLight');
			} else {
				if (!store.getters.getEyePathLayers.includes('windRadials')) {
					store.dispatch('setEyePathLayer', 'windRadials');
				}
				if (map.getZoom() > 3) {
					map.setLayoutProperty(layer.id, 'visibility', 'visible');
				}
				item.querySelector('.flyout-menu__content-subItems').classList.add('highLight');
			}
		}
	});
};
const atcfPlotsSelected = (map, item) => {
	// Making the call to deactivate / activate the ATCF Plots Layer
	map.getStyle().layers.forEach((layer) => {
		if (layer.id.includes('atcfPlots-')) {
			if (map.getLayoutProperty(layer.id, 'visibility') === 'visible') {
				if (store.getters.getEyePathLayers.includes('atcf')) {
					store.dispatch('removeEyePathLayer', 'atcf');
				}
				map.setLayoutProperty(layer.id, 'visibility', 'none');
				item
					.querySelector('.flyout-menu__content-subItems')
					.classList.remove('highLight');
			} else {
				if (!store.getters.getEyePathLayers.includes('atcf')) {
					store.dispatch('setEyePathLayer', 'atcf');
				}
				map.setLayoutProperty(layer.id, 'visibility', 'visible');
				item.querySelector('.flyout-menu__content-subItems').classList.add('highLight');
			}
		}
	});
};
const tropicalImpactsSelected = (map, item) => {
	// Making the call to deactivate / activate the Tropical Impacts Layer
	if (
		item.querySelector('.flyout-menu__content-subItems').classList.contains('highLight')
	) {
		if (store.getters.getEyePathLayers.includes('tropicalImpacts')) {
			store.dispatch('removeEyePathLayer', 'tropicalImpacts');
			store.dispatch('removeAllImpactLayers');
		}
		item.querySelector('.flyout-menu__content-subItems').classList.remove('highLight');
	} else {
		if (!store.getters.getEyePathLayers.includes('tropicalImpacts')) {
			store.dispatch('setEyePathLayer', 'tropicalImpacts');
		}
		item.querySelector('.flyout-menu__content-subItems').classList.add('highLight');
	}
};
