<template>
	<!-- This is the Custom Map Pop-Out that has a Input Box for the user to add a custom map style they made in MapBox Studio -->
	<div
		v-if="customMapModalOpen"
		class="custom-modal"
	>
		<p class="custom-modal-title">
			Custom Map ID
		</p>
		<p class="custom-modal-input-label">
			Style ID
		</p>
		<input
			v-model="customMapStyleId"
			class="custom-modal-input"
			type="text"
			placeholder="mapbox://styles/environment/mapID"
		/>
		<div class="custom-modal-button-container">
			<button
				class="custom-modal-button-container-close-button"
				@click="closeCustomMapModal"
			>
				Close
			</button>
			<button
				class="custom-modal-button-container-send-button"
				@click="callToSetCustomMapStyle"
			>
				Send
			</button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			customMapModalOpen: false,
			customMapStyleId: '',
		};
	},
	computed: {
		customMapBoxShow() {
			return this.$store.getters.getCustomMapModalVisibility;
		},
		shareBoxShow() {
			return this.$store.getters.getShareModalVisibility;
		},
	},
	watch: {
		customMapBoxShow() {
			this.customMapModalOpen = this.customMapBoxShow;
		},
		shareBoxShow() {
			if (this.shareBoxShow) {
				this.$store.dispatch('setCustomMapModalVisibility', false);
			}
		},
	},
	methods: {
		closeCustomMapModal() {
			this.$store.dispatch('setCustomMapModalVisibility', false);
		},
		callToSetCustomMapStyle() {
			// Try to set the new custom mapStyle
			this.$store.dispatch('setCustomMapStyle', this.customMapStyleId);
			this.customMapStyleId = '';
			this.$store.dispatch('setCustomMapModalVisibility', false);
		},
	},
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

.custom-modal {
	position: absolute;
	display: flex;
	flex-direction: column;
	left: 38%;
	top: 5rem;
	background-color: rgb(74, 74, 74, 0.9);
	color: #fff;
	border: 1px solid #000;
	border-radius: 5px;
	padding: 1rem;

	@include tablet {
		left: 30%;
	}

	@include desktop {
		left: 35%;
	}

	&-title {
		font-size: 1rem;
		margin: -1rem -1rem 1rem -1rem;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.7);
		padding: 1rem;
	}

	&-input-label {
		text-align: left;
		margin: 0 0 0.2rem 0;
		font-size: 0.9rem;
	}

	&-input {
		height: 2rem;
		margin-bottom: 1rem;
		border: 1px solid #000;
		border-radius: 5px;
		padding: 0.5rem;
		font-size: 0.8rem;

		@include tablet {
			min-width: 25rem;
		}
	}

	&-button-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		button {
			width: 25%;
			border: 1px solid #000;
			border-radius: 5px;

			@include tablet {
				width: 15%;
			}
		}

		&-send-button {
			background-color: #24a0ed;
		}

		&-close-button {
			color: #680808;
			margin-right: 0.2rem;
		}
	}
}
</style>
