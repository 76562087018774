<template>
	<div
		ref="controlCenterContainer"
		class="control-center-container"
	>
		<PlayBarComponent
			:show-playbar="showPlaybar"
			:play-bar-times="playBarTimes"
			:dev-env="devAPIEnvironment"
			:active-layers="activeLayers"
			:map-style="mapStyle"
			:animation-index="animationIndex"
		/>
		<div
			v-show="showControlCenterBottom"
			ref="controlCenterBottom"
			class="control-center-container_bottom"
		>
			<div class="control-center-container_bottom-left">
				<OpacitySlider
					:play-bar-times="playBarTimes"
					:dev-env="devAPIEnvironment"
					:active-layers="activeLayers"
					:map-style="mapStyle"
					:animation-index="animationIndex"
				/>
				<LegendComponent
					:active-layers="activeLayers"
					:tropical-impacts="tropicalImpacts"
					:tropical-impact-selected="tropicalImpactSelected"
				/>
			</div>
			<div
				v-show="showControlBottomRight"
				class="control-center-container_bottom-right"
			>
				<div class="control-center-container_bottom-right_container">
					<div class="control-center-container_bottom-right_container-time">
						{{ currentPlayBarTimeDay.time }}
					</div>
					<div class="control-center-container_bottom-right_container-day-name">
						{{ currentPlayBarTimeDay.day }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PlayBarComponent from './PlayBarComponent.vue';
import OpacitySlider from './OpacitySlider.vue';
import LegendComponent from './LegendComponent.vue';
export default {
	name: 'ControlCenter',
	components: { PlayBarComponent, OpacitySlider, LegendComponent },
	data() {
		return {
			showPlaybar: false,
			showControlCenterBottom: false,
			showControlBottomRight: false,
			activeLayers: [],
			tropicalImpacts: false,
			mapStyle: '',
			topCorners: '',
		};
	},
	computed: {
		//parent
		currentMapProjection() {
			return this.$store.getters.getMapProjection;
		},
		// parent --> playbar & Opacity
		animationIndex() {
			return this.$store.getters.getCurrentFrameIndex;
		},
		// parent --> playbar & Opacity
		playBarTimes() {
			return this.$store.getters.getSynchedData;
		},
		// parent
		currentPlayBarTimeDay() {
			if (this.playBarTimes?.length) {
				const data = this.playBarTimes;
				return {
					time: new Date(data[this.animationIndex]).toLocaleTimeString(
						'en-US',
						{
							hour: 'numeric',
							minute: 'numeric',
							hour12: true,
						}
					),
					day: new Date(data[this.animationIndex]).toLocaleDateString('en-US', {
						weekday: 'long',
					}),
				};
			}
			return {};
		},
		// parent --> playbar & Opacity
		devAPIEnvironment() {
			return this.$store.getters.getDevEnvironment;
		},
		// parent --> legend
		currentEyePathLayers() {
			return this.$store.getters.getEyePathLayers;
		},
		// parent --> legend
		tropicalImpactSelected() {
			return this.$store.getters.getSelectedImpactType;
		},
	},

	watch: {
		currentMapProjection() {
			if (
				this.activeLayers.length &&
				this.activeLayers[0].name === 'Wind Flow'
			) {
				this.currentMapProjection === 'globe'
					? (this.$refs.controlCenterContainer.style.display = 'none')
					: (this.$refs.controlCenterContainer.style.display = 'flex');
			}
		},
		activeLayers() {
			this.$refs.controlCenterContainer.style.display = 'flex';
		},
		currentEyePathLayers() {
			if (this.currentEyePathLayers.includes('tropicalImpacts')) {
				this.tropicalImpacts = true;
			} else {
				this.tropicalImpacts = false;
				if (this.activeLayers[0].name === 'Tropical Storms') {
					this.showControlCenterBottom = false;
				}
			}
		},
		tropicalImpactSelected() {
			if (this.tropicalImpactSelected && this.tropicalImpacts) {
				this.showControlCenterBottom = true;
			} else {
				this.showControlCenterBottom = false;
			}
		},
	},

	beforeCreate() {
		this.layerChange = this.$store.watch(
			(state, getters) => getters.getActiveLayers,
			(layers) => {
				this.$store.dispatch('clearFrameIndex');
				this.activeLayers = layers;

				if (this.activeLayers?.[0]?.animates) {
					this.showPlaybar = true;
					this.showControlBottomRight = true;
					this.$refs.controlCenterBottom.classList.remove(
						'control-bottom-rounded-top'
					);
				} else {
					this.showPlaybar = false;
					this.showControlBottomRight = false;
					this.$refs.controlCenterBottom.classList.add(
						'control-bottom-rounded-top'
					);
				}

				this.activeLayers?.[0]?.legend || this.activeLayers?.[0]?.opacity
					? (this.showControlCenterBottom = true)
					: (this.showControlCenterBottom = false);
			}
		);
		this.styleChange = this.$store.watch(
			(state, getters) => getters.getMapStyleName,
			(style) => {
				this.mapStyle = style;
			}
		);
	},
};
</script>

<style lang="scss">
// Style imports
@import '@/assets/styles/mixins.scss';

.control-center-container {
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 8vh;
	left: 5vw;
	width: calc(95% - 1rem);

	@include phablet {
		width: calc(70% - 1rem);
	}

	@include tablet {
		left: 17vw;
		width: calc(70% - 1.2rem);
	}

	@include desktop {
		width: calc(70% - 1.5rem);
	}

	.control-bottom-rounded-top {
		border-radius: 1rem 1rem 1rem 1rem;
	}

	&_bottom {
		z-index: 2;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		background-color: #fff;
		border-radius: 0rem 0rem 1rem 1rem;
		width: 90%;
		left: 5%;
		padding-top: 0.2rem;
		padding-bottom: 0.2rem;

		@include phablet {
			width: 94%;
			left: 3%;
		}

		&-left {
			min-width: 80%;
		}
		&-right {
			width: 20%;
			margin-right: 0.5rem;
			display: flex;
			align-items: center;
			justify-content: center;

			&_container {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 0.8rem;

				&-time {
					font-weight: 500;
				}
				&-day-name {
					text-align: center;
					width: 100%;
					font-size: 0.7rem;
				}

				@include phablet {
					font-size: 1.5rem;

					&-time {
						font-weight: 500;
					}
					&-day-name {
						width: 100%;
						font-size: 1rem;
					}
				}
			}
		}
	}
}
</style>
