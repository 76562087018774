let uriParams;

export function makeCustomView(store) {
	const map = window.mapObj;
	const [productName, mapStyle, zoomLevel, lat, lng, hiddenMenu] =
		getURIParams();
	if (uriParams.has('mapStyle')) {
		map.setStyle(mapStyle);
	}
	map.setCenter([lng, lat]);
	map.setZoom(zoomLevel);
	store.dispatch('setFlyOutMenuHidden', hiddenMenu);
	// Due to race condition, map must load first then add layer
	map.on('load', () => {
		store.getters.getWxLayers.forEach((layer) => {
			if (layer.name === productName) {
				store.dispatch('callWXLayerObject', productName);
			}
		});
	});
}

function getURIParams() {
	uriParams = new URLSearchParams(`${window.location.search}`);
	const productName = uriParams.get('productName');
	const mapStyle = uriParams.get('mapStyle');
	const zoomLevel =
		uriParams.has('zoomLevel') && Number(uriParams.get('zoomLevel'))
			? uriParams.get('zoomLevel')
			: 4;
	const lat =
		uriParams.has('lat') && Number(uriParams.get('lat'))
			? uriParams.get('lat')
			: 37.8;
	const lng =
		uriParams.has('lng') && Number(uriParams.get('lng'))
			? uriParams.get('lng')
			: -96;
	const hideMenu = uriParams.has('menuHidden')
		? uriParams.get('menuHidden')?.toLowerCase() === 'true'
		: false;
	return [productName, mapStyle, zoomLevel, lat, lng, hideMenu];
}
