// create a vue component that reads from this.$store and displays the current environment
<template>
    <div v-if="showDevEnvModal" class="devEnvModal">
        <p class="devEnvModal-title">Development Env.</p>
    </div>
  
</template>

<script>
export default {
    data() {
		return {
			showDevEnvModal: false
		};
	},
	computed: {
		environment() {
			return this.$store.getters.getAPIEnvironmentURL;
		}
	},
	watch: {
		environment(env) {
            if (env === "api.accuweather.com") {
                this.showDevEnvModal = false;
            } else {
                this.showDevEnvModal = true
            }
		},
	},
}
</script>

<style lang="scss" scoped>
.devEnvModal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 1.5rem;
    position: absolute;
    bottom: 2.5rem;
    right: 1.5rem;
    background-color: #fff;
    border: 1px solid #f05514;
    border-radius: 5px;

     &-title {
        font-size: 0.8rem;
        margin: 0;
        padding: 0;
     }
}

</style>