<template>
	<div class="tropical-impacts-legend">
		<div class="legend-title">
			<b>{{ title }}</b>
		</div>
		<div
			class="legend-item"
			:class="impactType.replaceAll(' ', '-').toLowerCase()"
		>
			<span
				v-for="colorBx in colorBoxes"
				:key="colorBx"
				class="color-rectangle"
				:class="colorBx"
			></span>
		</div>
		<div class="legend-footer">
			<span
				v-for="count in colorText"
				:key="count"
				class="footer-text"
			>{{
				count
			}}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ImpactsLegend',
	props: {
		impactType: String,
	},
	data() {
		return {
			title: this.impactType,
			colorBoxes: this.legendColorBoxes(),
			colorText: this.legendColorText(),
		};
	},
	watch: {
		impactType() {
			this.colorBoxes = this.legendColorBoxes();
			this.colorText = this.legendColorText();
		},
	},
	methods: {
		legendColorBoxes() {
			let count;
			let type;
			let countArray = [];

			switch (this.impactType) {
				case 'RISK TO LIFE PROPERTY':
					this.title = 'Risk to Life and Property';
					count = 4;
					type = 'rtlp';
					break;
				case 'MAXIMUM SUSTAINED WINDS':
					this.title = 'Max Sustained Winds';
					count = 7;
					type = 'msw';
					break;
				case 'MAXIMUM WIND GUSTS':
					this.title = 'Max Wind Gusts';
					count = 7;
					type = 'mwg';
					break;
				case 'STORM SURGE':
					this.title = 'Storm Surge';
					count = 5;
					type = 'ss';
					break;
				case 'RAINFALL':
					this.title = 'Rainfall';
					count = 4;
					type = 'rf';
					break;
			}

			for (let index = 1; index <= count; index++) {
				countArray.push(`${type}-${index}`);
			}

			return countArray;
		},
		legendColorText() {
			let text = [];
			switch (this.impactType) {
				case 'RISK TO LIFE PROPERTY':
					text = ['Some', 'Moderate', 'High', 'Extreme'];
					break;
				case 'MAXIMUM SUSTAINED WINDS':
				case 'MAXIMUM WIND GUSTS':
					text = ['39', '56', '74', '96', '111', '130', '>157 mph'];
					break;
				case 'STORM SURGE':
					text = ['1', '3', '6', '10', '>15 ft'];
					break;
				case 'RAINFALL':
					text = ['1', '2', '4', '>8'];
					break;
			}
			return text;
		},
	},
};
</script>
