import mapboxgl from 'mapbox-gl';

import { getFirstSymbolLayer, getMapTheme } from '../../utils/helpers';
import BaseLayer from '../wx-layers/parent-layers/base-layer';
// TURF.js IMPORTS
import circle from '@turf/circle';
import destination from '@turf/destination';
import { point } from '@turf/helpers';

export default class RangeRingsLayer extends BaseLayer {
	constructor(store) {
		super(store);
		this.layerId = 'range-rings';
		this.name = 'Range Rings';
		this.type = 'GeoJSON';
		this.category = 'options';
	}

	addToMap(map) {
		// getting the current map theme to determine the color of the rings
		const ringColor =
			getMapTheme(this.store) ===
			'mapbox://styles/accuweather-inc/cjknc24na2o5u2sqoy0t8ku8a'
				? '#000'
				: '#fff';

		const labelUnit = 'mi';
		const optionsC = { steps: 500, units: 'miles' };
		const bearing = 0;
		const optionsD = { units: 'miles' };
		const centerPoint = point([map.getCenter().lng, map.getCenter().lat]);

		const rangeRings = {
			ring: [
				circle(centerPoint, 30, optionsC),
				circle(centerPoint, 10, optionsC),
				circle(centerPoint, 0.1, optionsC),
			],
			label: [
				destination(centerPoint, 30, bearing, optionsD),
				destination(centerPoint, 10, bearing, optionsD),
			],
		};

		for (const property in rangeRings) {
			if (property === 'ring') {
				rangeRings[property].forEach((r, index) => {
					// To replicate the current version this is needed as the data set would be different
					if (index === 2) {
						r = {
							type: 'Feature',
							properties: { radius: 0 },
							geometry: {
								type: 'Point',
								coordinates: centerPoint.geometry.coordinates,
							},
						};
					}
					// Adding the range ring layers dynamicaly
					map.addLayer(
						{
							id: `${this.layerId}-${index}`,
							type: index === 2 ? 'circle' : 'line',
							source: {
								type: 'geojson',
								data: r,
							},
							minzoom: index === 1 ? 6 : 5,
							paint:
								index === 2
									? { 'circle-color': ringColor, 'circle-opacity': 0.8 }
									: { 'line-color': ringColor, 'line-width': 2 },
						},
						getFirstSymbolLayer(map)
					);
				});
			} else {
				rangeRings[property].forEach((l, index) => {
					// creating range ring labels
					const rangeRingDiv = document.createElement('div');
					rangeRingDiv.className = `${this.layerId}-label`;
					const ringLabel = rangeRingDiv.appendChild(
						document.createElement('span')
					);
					map.getZoom() >= 6.5
						? (rangeRingDiv.style.display = 'flex')
						: (rangeRingDiv.style.display = 'none');
					ringLabel.className = `${this.layerId}-label-text`;
					ringLabel.innerHTML =
						index === 0 ? `30 ${labelUnit}` : `10 ${labelUnit}`;

					map.on('zoom', () => {
						map.getZoom() >= 6.5
							? (rangeRingDiv.style.display = 'flex')
							: (rangeRingDiv.style.display = 'none');
					});
					new mapboxgl.Marker(rangeRingDiv)
						.setLngLat([l.geometry.coordinates[0], l.geometry.coordinates[1]])
						.addTo(map);
				});
			}
		}
	}

	removeFromMap(map) {
		map.getStyle().layers.forEach((layer) => {
			if (layer.id.includes(`${this.layerId}`)) {
				map.removeLayer(layer.id);
				map.removeSource(layer.id);
			}
		});
		const rangeRingLabels = [
			...document.querySelectorAll(`.${this.layerId}-label`),
		];
		rangeRingLabels.forEach((m) => {
			m.remove();
		});
	}
}
