import BaseLayer from './base-layer';
import { accuWeatherLayerApiKey } from '../../../utils/api-keys';

export default class RasterLayerLegacy extends BaseLayer {
	constructor(mapCon, store) {
		super(mapCon, store);
		this.type = 'Raster';
		this.changeOpacity = true;
		this.frames = [];
		this.apiKey = accuWeatherLayerApiKey;
	}

	async getApiData(data) {
		try {
			const response = await fetch(
				`https://${this.store.getters.getAPIEnvironmentURL}/maps/v1/${data.url}`
			);
			return await response.json();
		} catch (err) {
			console.error(`${this.layerId} API Call Failed`, err);
		}
	}

	async sendToStore(name, times) {
		super.sendToStore(times);
	}
	removeLayer() {
		super.removeLayer();
	}

	// Animation Control
	previousLayer(map, time) {
		if (this.frames.find((t) => t.includes(time))) {
			map.setPaintProperty(`${this.layerId}-${time}`, `raster-opacity`, 0);
		}
	}

	currentLayer(map, time) {
		if (this.frames.find((t) => t.includes(time))) {
			map.setPaintProperty(
				`${this.layerId}-${time}`,
				`raster-opacity`,
				this.store.getters.getOpacity
			);
		}
	}
}
