import { accuWeatherLayerApiKey } from '../../../utils/api-keys';
import RasterLayer from '../parent-layers/raster-layer-legacy';
import {
	addContoursLayer,
	addTemperatureValuesLayer,
	updateDataPoints,
	getDataPoints,
} from './temperature-map-layers';

let eventPassedMove;
let eventPassedClick;

export default class TemperaturePickerLayer extends RasterLayer {
	constructor() {
		super();
		this.layerId = 'temperature-picker';
		this.name = 'Temperature Picker';
		this.category = 'wx';
		this.legend = true;
		this.opacity = 0.7;
	}

	async addToMap(map) {
		await super
			.getApiData({
				url: `models/gfs/preferred_product_frames?products=26-1010&apikey=${accuWeatherLayerApiKey}`,
			})
			.then((layerMeta) => {
				const currTime = layerMeta.frames.filter(
					(t) =>
						new Date(t).getUTCHours() === new Date().getUTCHours() &&
						new Date(t).getUTCDay() === new Date().getUTCDay()
				);
				this.temperatureFrames = `${layerMeta.url}zxy/${currTime}/{z}/{x}/{y}.png?apikey=${accuWeatherLayerApiKey}&display_products=26-1010`;
				// Adding the data point values layer
				addTemperatureValuesLayer(map, false, this.layerId);
				// Adding contour map layer
				addContoursLayer(map, this.temperatureFrames, this.layerId);
			});
		// Getting the data points on layer creation
		await updateDataPoints(map, this.layerId);

		eventPassedMove = () => updateDataPoints(map, this.layerId);
		map.on('moveend', eventPassedMove);
		eventPassedClick = (e) => getDataPoints(map, this.layerId, e);
		map.on('click', eventPassedClick);
	}

	removeFromMap(map) {
		let pin = document.querySelector('.pin');
		if (pin) {
			pin.remove();
		}

		map.off('moveend', eventPassedMove);
		map.off('click', eventPassedClick);

		map.getStyle().layers.forEach((layer) => {
			if (layer.id.includes(`${this.layerId}`)) {
				map.removeLayer(layer.id);
				map.removeSource(layer.source);
			}
		});
		super.removeLayer(this.name);
	}
}
