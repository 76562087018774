const eyePathState = {
	activeEyePathLayers: [],
	impactType: null,
};

// Mutations
const mutations = {
	SET_EYE_PATH_LAYER(state, layer) {
		state.activeEyePathLayers.push(layer);
	},
	REMOVE_EYE_PATH_LAYER(state, layer) {
		state.activeEyePathLayers = state.activeEyePathLayers.filter(
			(l) => l !== layer
		);
	},
	EMPTY_EYE_PATH_LAYER(state) {
		state.activeEyePathLayers = [];
	},
	SELECT_IMPACT_TYPE(state, type) {
		state.impactType === type
			? (state.impactType = null)
			: (state.impactType = type);
	},
	REMOVE_ALL_IMPACT_LAYERS() {
		return '';
	},
};

const actions = {
	setEyePathLayer({ commit }, layer) {
		commit('SET_EYE_PATH_LAYER', layer);
	},
	removeEyePathLayer({ commit }, layer) {
		commit('REMOVE_EYE_PATH_LAYER', layer);
	},
	emptyEyePathLayer({ commit }) {
		commit('EMPTY_EYE_PATH_LAYER');
	},
	selectImpactType({ commit }, type) {
		commit('SELECT_IMPACT_TYPE', type);
	},
	removeAllImpactLayers({ commit }) {
		commit('REMOVE_ALL_IMPACT_LAYERS');
	},
};

const getters = {
	getEyePathLayers(state) {
		return state.activeEyePathLayers;
	},
	getSelectedImpactType(state) {
		return state.impactType;
	},
};

export default {
	state: eyePathState,
	mutations,
	actions,
	getters,
};
