import { getFirstSymbolLayer } from '../../utils/helpers';
import { accuWeatherLayerApiKey } from '../../utils/api-keys';
import RasterLayer from './parent-layers/raster-layer-legacy';

export default class WWALayer extends RasterLayer {
	constructor() {
		super();
		this.layerId = 'wwa';
		this.name = 'Severe Weather';
		this.category = 'wx';
		this.opacity = 1;
		this.legend = true;
	}
	async addToMap(map) {
		let tileUrl = `https://${this.store.getters.getAPIEnvironmentURL}/maps/v1/alerts/globalWarnings/zxy/{z}/{x}/{y}.png?apikey=${accuWeatherLayerApiKey}&borders=False`;
		this.mapLayer = map.addLayer(
			{
				id: this.layerId,
				source: {
					tiles: [`${tileUrl}`],
					type: 'raster',
					tileSize: 256,
				},
				type: 'raster',
			},
			getFirstSymbolLayer(map)
		);
	}

	removeFromMap(map) {
		map.getStyle().layers.forEach((layer) => {
			if (layer.id.includes(this.layerId)) {
				map.removeLayer(layer.id);
				map.removeSource(layer.source);
			}
		});
		super.removeLayer(this.layerId);
	}
}
