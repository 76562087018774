import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import zoomPlus from '../../assets/images/zoom-plus.png';
import zoomMinus from '../../assets/images/zoom-minus.png';
import {
	disableMenuItem,
	enableMenuItem,
} from '../../components/utils/helpers';
import { accuWeatherMapApiKey } from '../../components/utils/api-keys';
import store from '../../store/index';
import { getLocationInformationAsync } from '../api/accuweather';
import { addMarkerToLocation } from '../../components/location/location-search-service';

const projection = localStorage.getItem('mapProjection') || 'mercator';
let globe = projection === 'mercator' ? false : true;
let currentGlobeColor = globe ? '#f05514' : '#fff';
const style =
	localStorage.getItem('mapStyle') ||
	'mapbox://styles/accuweather-inc/cjknbnj4m04hn2snt04lpb2v5';
const center = getMapCenterArray(store.getters.getLastSearchedLocation);
let zoom = store.getters.getLastSearchedLocation.length > 0 ? 6 : 4;

export async function createMap(el) {
	mapboxgl.accessToken = `${accuWeatherMapApiKey}`;
	const map = new mapboxgl.Map({
		container: el,
		style: style,
		center: center,
		zoom: zoom,
		attributionControl: false,
		projection: { name: projection },
	});

	map.once('load', () => {
		// If in globe view then the map will need to have fog set
		if (projection === 'globe') {
			map.setFog({
				color: 'rgb(186, 210, 235)', // Lower atmosphere
				'high-color': 'rgb(36, 92, 223)', // Upper atmosphere
				'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
				'space-color': 'rgb(11, 11, 25)', // Background color
				'star-intensity': 0.6, // Background star brightness (default 0.35 at low zoooms )
			});
		}
		if (store.getters.getSavedLocations.length) {
			paintSavedLocations(store.getters.getSavedLocations);
		}
	});

	// This is to disable windflow in flyout menu if the map is created as a globe projection
	globe
		? disableMenuItem('.wind-flow-menu-option')
		: enableMenuItem('.wind-flow-menu-option');

	// Add the attribution control to map
	map.addControl(
		new mapboxgl.AttributionControl({ compact: true }),
		'bottom-left'
	);

	// Adding the zoom controls and the 3D globe icon
	zoomControlButtons(map);

	// Adding logo to bottom right of screen
	addLogo(el);

	// Map obj is created and is now a global obj
	window.mapObj = map;
}

function zoomControlButtons(map) {
	const zoomPlusButton = (onclick) => {
		const imgButton = document.createElement('img');
		imgButton.src = zoomPlus;
		imgButton.addEventListener('click', onclick);
		return imgButton;
	};
	const zoomMinusButton = (onclick) => {
		const imgButton = document.createElement('img');
		imgButton.src = zoomMinus;
		imgButton.addEventListener('click', onclick);
		return imgButton;
	};
	const projectionOptionButtonHTML = (onclick) => {
		const imgButtonDiv = document.createElement('div');
		const globeIcon = imgButtonDiv.appendChild(globeSVG());
		const globeIconText = imgButtonDiv.appendChild(document.createElement('p'));
		imgButtonDiv.className = 'map-projection-div';
		globeIcon.classList.add('map-projection-icon');
		globeIconText.className = 'map-projection-text';
		globeIconText.innerHTML = globe ? '2D' : '3D';
		globeIcon.style.border = `${currentGlobeColor} solid 1px`;
		globeIconText.style.color = currentGlobeColor;
		imgButtonDiv.addEventListener('click', onclick);
		return imgButtonDiv;
	};
	const zoomInButton = zoomPlusButton(() => {
		map.flyTo({
			center: map.getCenter(),
			zoom: map.getZoom() + 1,
		});
	});
	const zoomOutButton = zoomMinusButton(() => {
		map.flyTo({
			center: map.getCenter(),
			zoom: map.getZoom() - 1,
		});
	});
	const projectionOptionButtonEvent = projectionOptionButtonHTML(() => {
		// Send to store the change of map projection
		globe = !globe;
		store.dispatch('setMapProjection', globe ? 'globe' : 'mercator');
		store.dispatch('setProjectionIconState');
	});
	const mapboxglZoomControl = {
		onAdd: () => {
			const zoomContainer = document.createElement('div');
			zoomContainer.classList.add('custom-control', 'mapboxgl-ctrl');
			zoomContainer.appendChild(zoomInButton);
			zoomContainer.appendChild(zoomOutButton);
			zoomContainer.appendChild(projectionOptionButtonEvent);
			return zoomContainer;
		},
		getDefaultPosition: () => {
			return 'top-right';
		},
	};
	map.addControl(mapboxglZoomControl);
}

function addLogo(map) {
	const logobacking = document.createElement('div');
	logobacking.className = 'accuLogoBacking';
	map.appendChild(logobacking);
	const logo = document.createElement('div');
	logo.className = 'accuLogo';
	map.appendChild(logo);
}

function globeSVG() {
	globe ? (currentGlobeColor = '#f05514') : (currentGlobeColor = '#fff');
	var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
	var path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');

	svg.setAttribute('aria-hidden', 'true');
	svg.setAttribute('viewbox', '0 0 50 50');
	svg.setAttribute('width', '1.5rem');
	svg.setAttribute('height', '1.5rem');
	path1.setAttribute('id', 'globe-path');

	path1.setAttribute(
		'd',
		'M17.9,17.39C17.64,16.59 16.89,16 16,16H15V13A1,1 0 0,0 14,12H8V10H10A1,1 0 0,0 11,9V7H13A2,2 0 0,0 15,5V4.59C17.93,5.77 20,8.64 20,12C20,14.08 19.2,15.97 17.9,17.39M11,19.93C7.05,19.44 4,16.08 4,12C4,11.38 4.08,10.78 4.21,10.21L9,15V16A2,2 0 0,0 11,18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'
	);
	path1.setAttribute('fill', currentGlobeColor);

	svg.appendChild(path1);
	return svg;
}

function paintSavedLocations(locations) {
	locations.forEach((location) => {
		getLocationInformationAsync(location).then((loc) => {
			addMarkerToLocation(loc);
		});
	});
}

function getMapCenterArray(storeLoc) {
	if (storeLoc.length > 0) {
		let centerArrayStrings = store.getters.getLastSearchedLocation.split(',');
		return centerArrayStrings.map(function (str) {
			return parseFloat(str);
		});
	} else {
		return [-96, 37.8];
	}
}
