// import Objects for Style Layers, WX Layers, Option Layers
import StyleLayer from '../map-layers/style-layers/style-layer';
import PastRadarLayer from '../map-layers/wx-layers/radar/past-radar';
import FutureRadarLayer from '../map-layers/wx-layers/radar/future-radar';
import LocalRadarLayer from '../map-layers/wx-layers/radar/local-radar';
import AirQualityCurrentLayer from '../map-layers/wx-layers/air-quality/air-quality-current';
import AirQualityPlumeLayer from '../map-layers/wx-layers/air-quality/air-quality-plume';
import LightningLayer from '../map-layers/wx-layers/lightning-layer';
import SmokeLayer from '../map-layers/wx-layers/smoke-layer';
import WWALayer from '../map-layers/wx-layers/wwa-layer';
import WildfirePotentialLayer from '../map-layers/wx-layers/fire/wildfire-layer';
import RealVueSatelliteLayer from '../map-layers/wx-layers/satellite/satellite-layers/realvue-satellite';
import EnhancedRealVueSatelliteLayer from '../map-layers/wx-layers/satellite/satellite-layers/enhanced-realvue-satellite';
import WaterVaporSatelliteLayer from '../map-layers/wx-layers/satellite/satellite-layers/water-vapor-satellite';
import VisibleSatelliteLayer from './wx-layers/satellite/satellite-layers/visible-satellite';
import InfraredSatelliteLayer from './wx-layers/satellite/satellite-layers/infrared-satellite';
import WindFlowLayer from './wx-layers/wind-flow/wind-flow-layer';
import TropicalLayer from '../map-layers/wx-layers/tropical/tropical-layers';
import TemperatureCurrentLayer from './wx-layers/temperature/temperature-current';
import TemperaturePickerLayer from './wx-layers/temperature/temperature-picker';
import PastSnowfall from './wx-layers/winter/past-snowfall-layer';
import IceForecastLayer from '../map-layers/wx-layers/winter/ice-forecast-layer';
import SnowForecastLayer from '../map-layers/wx-layers/winter/snow-forecast-layer';
import RangeRingsLayer from '../map-layers/option-layers/range-rings-layer';

// import Images
// --->	Lightning
import singleStrikeRadarOn from '../../assets/images/Single-Strike-Radar-On.png';
import singleStrikeRadarOff from '../../assets/images/Single-Strike-Radar-Off.png';
import clusterRadarOn from '../../assets/images/Cluster-Radar-On.png';
import clusterRadarOff from '../../assets/images/Cluster-Radar-Off.png';

// This is a list of all the layerObjects to be mounted
export const layerObjectArray = [
	StyleLayer,
	PastRadarLayer,
	FutureRadarLayer,
	LocalRadarLayer,
	AirQualityCurrentLayer,
	AirQualityPlumeLayer,
	LightningLayer,
	SmokeLayer,
	WWALayer,
	WildfirePotentialLayer,
	RealVueSatelliteLayer,
	EnhancedRealVueSatelliteLayer,
	WaterVaporSatelliteLayer,
	VisibleSatelliteLayer,
	InfraredSatelliteLayer,
	WindFlowLayer,
	TropicalLayer,
	TemperatureCurrentLayer,
	TemperaturePickerLayer,
	PastSnowfall,
	IceForecastLayer,
	SnowForecastLayer,
	RangeRingsLayer,
];

// This is for the initiation of the layer objects when we mount the map
export const addLayerObjects = (store) => {
	layerObjectArray.forEach((Layer) => {
		store.dispatch('mountClassObject', new Layer());
	});
};

export const addLightningLayerImages = (map) => {
	if (!map.hasImage('custom-marker-cluster-white')) {
		const images = [
			{
				imageUrl: singleStrikeRadarOn,
				id: 'custom-marker-single-white',
			},
			{
				imageUrl: singleStrikeRadarOff,
				id: 'custom-marker-single-yellow',
			},
			{
				imageUrl: clusterRadarOn,
				id: 'custom-marker-cluster-white',
			},
			{
				imageUrl: clusterRadarOff,
				id: 'custom-marker-cluster-yellow',
			},
		];
		Promise.all(
			images.map(
				(img) =>
					new Promise((resolve) => {
						map.loadImage(img.imageUrl, (error, image) => {
							map.addImage(img.id, image);
							resolve();
						});
					})
			)
		);
	}
};
