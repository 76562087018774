import mapboxgl from 'mapbox-gl';

export async function autoCompleteLookup(key) {
	const response = await fetch(
		`https://www.accuweather.com/web-api/autocomplete?query=${key}&language=en-us`
	);
	return await response.json();
}

export async function addMarkerToLocation(loc) {
	const [locationBoxLine, locationBox] = createLocationBox(loc);

	let clickEvent = (e) => openLocationView(e);
	locationBox.onclick = clickEvent;

	new mapboxgl.Marker(locationBoxLine)
		.setLngLat([loc.GeoPosition.Longitude, loc.GeoPosition.Latitude])
		.addTo(window.mapObj);
	new mapboxgl.Marker(locationBox)
		.setLngLat([loc.GeoPosition.Longitude, loc.GeoPosition.Latitude])
		.addTo(window.mapObj);
}
function createLocationBox(location) {
	let locationBox = document.createElement('div');
	locationBox.className = `location-box`;
	locationBox.id = `location-box-${location.Key}`;
	let locationBoxLine = document.createElement('div');
	locationBoxLine.className = 'location-box_line';
	locationBoxLine.id = `location-box_line-${location.Key}`;

	locationBox.innerHTML = `
	<p class='location-box_text' value=${location.Key}>${location.LocalizedName}</p>
	<p class='location-box_forecast-text' value=${location.Key}>Click for Forecast</p>`;

	return [locationBoxLine, locationBox];
}
function openLocationView(e) {
	window.open(
		`https://www.accuweather.com/web-api/three-day-redirect?key=${e.target.attributes['value'].value}`,
		'_blank'
	);
}
