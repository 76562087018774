import RasterLayer from '../parent-layers/raster-layer';

export default class SatelliteLayer extends RasterLayer {
	constructor() {
		super();

		this.category = 'wx';
		this.animates = true;
		this.opacity = 1;
		this.legend = true;
		this.maxBounds = true;
	}

	async addToMap(data) {
		let satelliteMeta = await super.getApiData({
			name: this.layerId,
			url: data.url,
		});
		this.frames =
			super
				.sortFrames(satelliteMeta?.frames)
				.map((l) =>
					super.mapFrameAPI(
						this.layerId,
						l,
						`${satelliteMeta?.url}`,
						`${data.urlEnd || ''}`
					)
				) || [];
		super.tryAddFrames(data.map, this.frames);
	}

	async removeFromMap(map) {
		await super.removeFromMap(map);
	}
}
