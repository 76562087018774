import RasterLayer from '../parent-layers/raster-layer';

export default class IceForecast extends RasterLayer {
	constructor(apiKey) {
		super(apiKey);
		this.layerId = 'ice-forecast';
		this.name = 'Ice Forecast';
		this.category = 'wx';
		this.animates = true;
		this.legend = true;
		this.opacity = 0.7;
		this.frames = [];
		this.frameTimes = [];
	}

	async addToMap(map) {
		let layerMeta = await super.getApiData({
			name: this.layerId,
			url: `models/ufdb/precip/preferred_product_frames?&apikey=${this.apiKey}&products=111-1040`,
		});
		let dt = new Date(Date.now());
		dt.setMinutes(0, 0, 0);
		const dtISO = dt.toISOString();
		const forecastFrames = layerMeta?.frames.filter(
			(frame) => dtISO <= new Date(frame).toISOString()
		);
		this.frames =
			super
				.sortFrames(forecastFrames)
				.map((l) =>
					super.mapFrameAPI(
						this.layerId,
						l,
						`${layerMeta?.url}`,
						`&display_products=111-1040`
					)
				) || [];
		super.tryAddFrames(map, this.frames);
	}

	async removeFromMap(map) {
		await super.removeFromMap(map);
	}
}

/*
https://www.accuweather.com/web-api/geojson
	?language=en-us
	&upperLeft=61.62171765868237,-80.00857299525231
	&lowerRight=55.578134278459544,-62.50192872972512
	&metric=false
	&limit=200
	&hourlyType=ice
	&timestamp=2022-10-29T03:00:00Z
*/
