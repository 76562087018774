<template>
	<div class="satellite-legend">
		<div class="legend-title">
			<b>{{ title }}</b>
		</div>
		<div
			class="legend-item"
			:class="satType"
		>
			<span
				v-for="count in colorBoxes"
				:key="count"
				class="color-rectangle"
				:class="count"
			></span>
		</div>
		<div class="legend-footer">
			<span class="footer-start">{{ leftText }}</span>
			<span class="footer-end">{{ rightText }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SatelliteLegend',
	props: {
		satType: String,
	},
	data() {
		return {
			title: this.satType === 'water-vapor' ? 'Water Vapor' : 'Clouds',
			leftText: this.satType === 'water-vapor' ? 'Dry' : 'Low',
			rightText: this.satType === 'water-vapor' ? 'Moist' : 'High',
			colorBoxes: this.legendColorBoxes(),
		};
	},
	watch: {
		satType() {
			if (this.satType === 'water-vapor') {
				this.title = 'Water Vapor';
				this.leftText = 'Dry';
				this.rightText = 'Moist';
				this.colorBoxes = this.legendColorBoxes();
			} else {
				this.title = 'Clouds';
				this.leftText = 'Low';
				this.rightText = 'High';
				this.colorBoxes = this.legendColorBoxes();
			}
		},
	},
	methods: {
		legendColorBoxes() {
			let count;
			let countArray = [];
			if (this.satType === 'water-vapor') {
				count = 13;
				for (let index = 1; index <= count; index++) {
					countArray.push(`wv-${index}`);
				}
			}
			if (this.satType === 'real-vue' || this.satType === 'visible') {
				count = 16;
				for (let index = 1; index <= count; index++) {
					countArray.push(`rv-${index}`);
				}
			}
			if (this.satType === 'enhanced-real-vue' || this.satType === 'infrared') {
				count = 12;
				for (let index = 1; index <= count; index++) {
					countArray.push(`erv-${index}`);
				}
			}

			return countArray;
		},
	},
};
</script>
