import {adjustLongitudes} from '../utils/tropical-map-bounds';

// ATCF TRACK LINES
export const addATCFTrackLineToMap = (map, store, storm) => {
	if (!storm?.atcf || storm?.atcf === null) {
		return;
	}

	const colors = [
		'#FF6633',
		'#FFB399',
		'#FF33FF',
		'#FFFF99',
		'#00B3E6',
		'#E6B333',
		'#3366E6',
		'#999966',
		'#99FF99',
		'#B34D4D',
		'#80B300',
		'#809900',
		'#E6B3B3',
		'#6680B3',
		'#66991A',
		'#FF99E6',
		'#CCFF1A',
		'#FF1A66',
		'#E6331A',
		'#33FFCC',
		'#66994D',
		'#B366CC',
		'#4D8000',
		'#B33300',
		'#CC80CC',
		'#66664D',
		'#991AFF',
		'#E666FF',
		'#4DB3FF',
		'#1AB399',
		'#E666B3',
		'#33991A',
		'#CC9999',
		'#B3B31A',
		'#00E680',
		'#4D8066',
		'#809980',
		'#E6FF80',
		'#1AFF33',
		'#999933',
		'#FF3380',
		'#CCCC00',
		'#66E64D',
		'#4D80CC',
		'#9900B3',
		'#E64D66',
		'#4DB380',
		'#FF4D4D',
		'#99E6E6',
		'#6666FF',
	];

	const minDate = subtractDays(new Date(), 1);
	const maxDate = addDays(new Date(), 5);
	storm.atcf.Models.forEach((m, index) => {
		let modelPointCollection = [];
		m.Forecasts.forEach((point) => {
			if (storm.atcf?.Mock) {
				modelPointCollection.push([
					point.Position.Longitude,
					point.Position.Latitude,
				]);
			} else {
				if (
					point.DateTime >= minDate.toISOString() &&
					point.DateTime <= maxDate.toISOString()
				) {
					modelPointCollection.push([
						point.Position.Longitude,
						point.Position.Latitude,
					]);
				}
			}
		});

		// Adjusting the longitudes to be continuous
		adjustLongitudes(modelPointCollection);

		map.addSource(`atcfPlots-${m.Name}`, {
			type: 'geojson',
			data: {
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'LineString',
					coordinates: modelPointCollection,
				},
			},
		});

		map.addLayer({
			id: `tropical-category-layer-atcfPlots-${m.Name}-line`,
			type: 'line',
			minzoom: 2,
			source: `atcfPlots-${m.Name}`,
			layout: {
				'line-join': 'round',
				'line-cap': 'round',
				visibility: 'none',
			},
			paint: {
				'line-color': colors[index],
				'line-width': 2,
			},
		});

		map.addLayer({
			id: `tropical-category-layer-atcfPlots-${m.Name}-label`,
			type: 'symbol',
			minzoom: 2,
			source: `atcfPlots-${m.Name}`,
			layout: {
				'symbol-placement': 'line',
				'text-field': m.Name,
				'text-size': 12,
				visibility: 'none',
			},
			paint: {
				'text-halo-color': 'rgba(0, 0, 0, 1)',
				'text-halo-width': 4,
				'text-color': '#fff',
			},
		});
	});

	// This will set the atcf plots along the forecast track as visible or not with the icons as well
	map.on('zoom', () => {
		if (store.getters.getEyePathLayers.includes('atcf')) {
			map.getStyle().layers.forEach((layer) => {
				if (layer.id.includes('atcfPlots-')) {
					map.setLayoutProperty(layer.id, 'visibility', 'visible');
				}
			});
		} else {
			map.getStyle().layers.forEach((layer) => {
				if (layer.id.includes('atcfPlots-')) {
					map.setLayoutProperty(layer.id, 'visibility', 'none');
				}
			});
		}
	});
};

const addDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
};
const subtractDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() - days);
	return result;
};
