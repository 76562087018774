<template>
	<div
		id="map"
		ref="map"
	></div>
</template>

<script>
import { createMap } from '../services/map-services/map-service';
import { makeCustomView } from '../services/map-services/custom-map-service';

export default {
	data() {
		return {
			uriParams: new URLSearchParams(`${window.location.search}`),
			customMap: this.uriCheck(),
		};
	},
	computed: {
		currentMapStyle() {
			return this.$store.getters.getMapStyleName;
		},
		currentMapProjection() {
			return this.$store.getters.getMapProjection;
		},
	},
	watch: {
		currentMapStyle() {
			if (this.currentMapProjection === 'globe') {
				window.mapObj.on('idle', () => {
					window.mapObj.setFog({
						color: 'rgb(186, 210, 235)', // Lower atmosphere
						'high-color': 'rgb(36, 92, 223)', // Upper atmosphere
						'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
						'space-color': 'rgb(11, 11, 25)', // Background color
						'star-intensity': 0.6, // Background star brightness (default 0.35 at low zoooms )
					});
				});
			}
		},
	},
	mounted() {
		// Mounting map
		createMap(this.$refs.map).then(() => {
			//Check for URI params
			if (this.customMap) {
				makeCustomView(this.$store);
			}
		});
	},
	methods: {
		uriCheck() {
			let hasParams = false;
			window.location.search !== '' ? (hasParams = true) : (hasParams = false);
			return hasParams;
		},
	},
};
</script>
