import mapboxgl from 'mapbox-gl';
import { getImagePath } from '../utils/storm-icon-service';
import { drawWindowData } from '../map-addLayers/add-storm-windows';
import { drawRadialData } from '../map-addLayers/add-wind-radials';
import { addATCFTrackLineToMap } from '../map-addLayers/add-atcf-track-lines';
import { drawImpactPolygons } from '../map-addLayers/add-impact-polygons';
import { setStormBoundingBox } from '../utils/tropical-map-bounds';
import { eyePathTrackData } from '../fep-view/create-eye-path-data';
import { createActiveStormsSubMenu } from '../../../../../flyout-menu/active-storms-submenu';
import { buildSelectedStormObj } from '../fep-view/build-selected-storm-data';
let store;

export const displayActiveStormIcons = (map, passedStore, storms) => {
	store = passedStore;
	storms.forEach((storm) => {
		const stormIconElement = document.createElement('div');
		const stormIconImage = stormIconElement.appendChild(
			document.createElement('img')
		);
		stormIconImage.className = 'active-storm-position';
		storm.current.type = 'forecast';
		storm.current.imagePath = `${getImagePath(storm.current, 'active')}.png`;
		stormIconImage.src = require(`../../../../../../assets/images/tropical/${storm.current.imagePath}`);

		stormIconImage.id = `${storm.info.name}`;

		let eventPassed = (e) => showActiveStormDetailBox(e, map, storm);
		stormIconElement.onclick = eventPassed;

		// Adding Date Box and Icons to map
		new mapboxgl.Marker(stormIconElement)
			.setLngLat([
				storm.current.position.longitude,
				storm.current.position.latitude,
			])
			.addTo(map);
	});
};

const showActiveStormDetailBox = (e, map, storm) => {
	// Look to see if these elements already exist
	const oldDetailedDateBox = document.querySelector(
		'.active-storm-position-date-box-detailed'
	);
	const oldDetailedDateBoxLine = document.querySelector(
		'.active-storm-info-box-line-detailed'
	);
	const oldDetailedDateBoxImage = document.querySelector(
		'.active-storm-position-date-box-detailed-image'
	);

	// If there is a detailed box already showing, then remove it
	if (oldDetailedDateBox !== null) {
		oldDetailedDateBox.remove();
		oldDetailedDateBoxLine.remove();
		oldDetailedDateBoxImage.remove();
	}

	let detailedDateBox = document.createElement('div');
	detailedDateBox.className = 'active-storm-position-date-box-detailed';
	let detailedDateBoxImage = document.createElement('img');
	detailedDateBoxImage.className =
		'active-storm-position-date-box-detailed-image';
	let detailedDateBoxLine = document.createElement('div');
	detailedDateBoxLine.className = 'active-storm-info-box-line-detailed';

	detailedDateBoxImage.src = require(`../../../../../../assets/images/tropical/${storm.current.imagePath}`);

	if (storm.info.name.includes('Potential Tropical Cyclone')) {
		storm.info.name = 'Tropical Rainstorm';
	}

	detailedDateBox.innerHTML = `
	${detailedDateBoxImage.outerHTML}
	<div class='active-storm-position-date-box-detailed-text'>
		<span>${storm.info.name.toUpperCase()}</span>
		<span>${storm.current.status}</span>
	</div>
	<div style='font-size: 1rem; margin-right: .5rem;'>More &#x279C; </div>`;

	let eventPassed = () => showEyePathView(map, storm);
	detailedDateBox.onclick = eventPassed;

	new mapboxgl.Marker(detailedDateBoxLine)
		.setLngLat([
			storm.current.position.longitude,
			storm.current.position.latitude,
		])
		.addTo(map);
	new mapboxgl.Marker(detailedDateBox)
		.setLngLat([
			storm.current.position.longitude,
			storm.current.position.latitude,
		])
		.addTo(map);
};

const showEyePathView = async (map, storm) => {
	const selectedStormInfo = await buildSelectedStormObj(storm);
	// fixating the screen on the selected storm
	setStormBoundingBox(map, selectedStormInfo);
	// Removing the current Tropical Storms from the map to only display the selected storm
	removeActiveStorms();
	/*	Method to build out eye-path Track:
			1. Marks the last historical icon as current
			2. Creates the image path needed for each position icon
				a. Determines if the icon is unmarked or not
			3. Combines historical and forecast icon data and sorts them to get the proper order
				and then displays them on the map
			4. Seprates the sorted track data into historical and future collections and then
				builds track lines for each
	*/
	eyePathTrackData(map, selectedStormInfo);
	// These are created first so the submenu knows what layers are available
	drawWindowData(map, store, selectedStormInfo.forecast);
	drawRadialData(map, store, selectedStormInfo.forecast);
	addATCFTrackLineToMap(map, store, selectedStormInfo);
	drawImpactPolygons(map, store, selectedStormInfo);
	// Create Sub-Menu for Tropical Storms to turn layers on and off and give user the ability to return to see all storms
	createActiveStormsSubMenu(map);
};

// Removing the active storm layers
const removeActiveStorms = () => {
	const activeStormComponents = [
		...document.querySelectorAll(
			`.active-storm-position-date-box-detailed,
			.active-storm-info-box-line-detailed,
			.active-storm-position-date-box-detailed-image,
			.active-storm-position`
		),
	];

	activeStormComponents.forEach((m) => {
		m.remove();
	});
};
