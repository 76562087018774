const layerState = {
	wxLayers: [],
	activeLayers: [],
	nonActiveLayers: [],
	overlayLayer: [],
};

// Mutations
const mutations = {
	/*
		Initial mounted objects pushed into wxLayers collection
	*/
	MAP_LAYER_MOUNT(state, layer) {
		state.wxLayers.push(layer);
	},
	MAP_LAYER_SELECT(state, layer) {
		return layer;
	},
	OVERLAY_LAYER_SELECT(state, layer) {
		return layer;
	},
	CLEAR_OVERLAY_LAYER() {
		return;
	},
};

const actions = {
	/*
		This is the initial mounting of the map and objects call, which then puts the
		objects in the wxLayers collection which will be used to add and remove the map layers using vuex
	*/
	mountWXClasses({ commit }, layer) {
		commit('MAP_LAYER_MOUNT', layer);
	},
	mountClassObject({ commit }, layer) {
		if (layer.name === 'MapStyle') {
			commit('MAP_STYLE_MOUNT', layer);
		} else {
			commit('MAP_LAYER_MOUNT', layer); // Initial mounted objects pushed into mapLayers collection
		}
	},
	/*
		This is the what is called when a wx layer from the menu is selected. This will
		either activate or deactivate a layer, which is used for animation control
	*/
	callWXLayerObject({ commit }, layer) {
		commit('MAP_LAYER_SELECT', layer);
	},
	/*
		This is triggered when an overlay layer option on the opacity-overlay bar is selected
	 */
	setOverlayLayer({ commit }, layer) {
		commit('OVERLAY_LAYER_SELECT', layer);
	},
	clearOverlayLayer({ commit }) {
		commit('CLEAR_OVERLAY_LAYER');
	},
};

const getters = {
	getWxLayers(state) {
		return state.wxLayers;
	},
	// Used in the BaseMap comp to know what layers should be displaying
	getActiveLayers(state) {
		return state.activeLayers;
	},
	// Used in the BaseMap comp to know what layers should NOT be displaying
	getNonActiveLayers(state) {
		return state.nonActiveLayers;
	},
	getOverlayLayers(state) {
		return state.overlayLayer;
	},
};

export default {
	state: layerState,
	mutations,
	actions,
	getters,
};
