import mapboxgl from 'mapbox-gl';

export const createAQIPopUp = (map, location, value) => {
	// Look to see if there is a pin already
	const oldAQIPin = document.querySelector('.pin');
	// If there is a pin already showing, then remove it
	if (oldAQIPin !== null) {
		oldAQIPin.remove();
	}

	const aqiPin = document.createElement('div');
	aqiPin.className = 'pin';
	const aqiPinImage = aqiPin.appendChild(document.createElement('img'));
	aqiPinImage.className = 'pin-image';
	const aqiPinText = aqiPin.appendChild(document.createElement('div'));
	aqiPinText.className = 'pin-text';

	aqiPinText.innerHTML = `<p class='aqi-value'>${value}</p>
	<p class='aqi-text'>AQI</p>`;
	aqiPinImage.src = require(`../../../../assets/images/aqi/pin_click.png`);

	aqiPin.innerHTML = `
	${aqiPinImage.outerHTML}
	${aqiPinText.outerHTML}
	`;

	new mapboxgl.Marker(aqiPin)
		.setLngLat([location.lng, location.lat])
		.addTo(map);
};
