import RadarLayer from './radar-layer';

export default class PastRadarLayer extends RadarLayer {
	constructor(apiKey) {
		super(apiKey);
		this.layerId = 'past-radar';
		this.frames = [];
		this.frameTimes = [];
		this.nowIndex = 0;

		this.name = 'Global Radar';
		this.category = 'wx';
		this.animates = true;
		this.opacity = 1;
		this.legend = true;
	}

	async addToMap(map) {
		await super.addToMap({
			map: map,
			url: `radar/globalSIR/preferred_product_frames?apikey=${this.apiKey}&products=51-1001`,
		});
	}
	async removeFromMap(map) {
		await super.removeFromMap(map);
	}
}
