/*
	This is for building out the flyout menu. Put the layer name you are
	adding in the proper section of the menu and be sure to use the same
	name that the Layer Object is called (this.name)
*/
export const layersArray = [
	{
		menuOption: 'Weather Layers',
		options: [
			{
				smallMenuOption: 'Radar',
				smallOptions: ['Global Radar', 'Single-Site Radar'],
			},
			'Wind Flow',
			{
				smallMenuOption: 'Satellite',
				smallOptions: ['RealVue', 'Enhanced RealVue', 'Water Vapor', 'Visible', 'Infrared'],
			},
			'Air Quality',
			'Surface Smoke',
			{
				smallMenuOption: 'Winter',
				smallOptions: ['Snow Forecast', 'Ice Forecast', 'Past Snowfall'],
			},
			'Tropical Storms',
			'Severe Weather',
			'Lightning',
			'Temperature',
		],
	},
	{
		menuOption: 'Settings',
		options: [
			'Light Basemap',
			'Dark Basemap',
			'Terrain Basemap',
			'Remove Labels',
		],
	},

	{
		menuOption: 'UX/Design Tools',
		options: ['Custom Map', 'Toggle UI'],
	},
	{
		menuOption: 'Developer Tools',
		options: ['Change Environment', 'View Zoom Level'],
	},
];
