<template>
	<div class="winter-legend">
		<div class="legend-title">
			<b>{{ title }}</b>
		</div>
		<div
			class="legend-item"
			:class="winterType"
		>
			<span
				v-for="colorBx in colorBoxes"
				:key="colorBx"
				class="color-rectangle"
				:class="colorBx"
			></span>
		</div>
		<div
			class="legend-footer"
			:class="winterType"
		>
			<span
				v-for="num in colorText"
				:key="num"
				class="footer-text"
			>
				{{ num }}
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WinterLegend',
	props: {
		winterType: String,
	},
	data() {
		return {
			title:
				this.winterType === 'past-snowfall' ? 'Total Snow Accumulation' : '',
			colorBoxes: this.legendColorBoxes(),
			colorText: this.legendColorText(),
		};
	},
	watch: {
		winterType() {
			this.colorBoxes = this.legendColorBoxes();
			this.colorText = this.legendColorText();
		},
	},
	methods: {
		legendColorBoxes() {
			let countArray = [];
			let count;
			let type;

			switch (this.winterType) {
				case 'ice-forecast':
					this.title = '';
					count = 12;
					type = 'if';
					break;
				case 'snow-forecast':
					this.title = '';
					count = 10;
					type = 'wf';
					break;
				case 'past-snowfall':
					this.title = 'Total Snow Accumulation';
					count = 10;
					type = 'wf';
					break;
			}

			for (let index = 1; index <= count; index++) {
				countArray.push(`${type}-${index}`);
			}
			return countArray;
		},
		legendColorText() {
			return this.winterType === 'ice-forecast'
				? ['<0.1', '0.2', '0.4', '0.6', '0.8', '1.0', '>1.2in']
				: ['<1', '2', '4', '8', '18', '>24in'];
		},
	},
};
</script>

<style lang="scss">
// Style imports
@import '@/assets/styles/mixins.scss';

@mixin color-fill-mixin($colors, $name) {
	@for $i from 1 through length($colors) {
		.#{$name}-#{$i} {
			background-color: nth($colors, $i);
		}
	}
}

.winter-legend {
	width: 100%;
	display: flex;
	flex-direction: column;
	font-size: 0.6rem;
	color: #000000;

	@include tablet {
		font-size: 0.8rem;
	}

	.legend-title {
		display: flex;
		justify-content: center;
		margin-bottom: 2px;
	}

	.legend-footer {
		color: #878787;
		display: flex;
		justify-content: space-between;
	}

	.legend-item {
		display: flex;
		margin-bottom: 0.2rem;
		/* Winter Colors */
		$colors: (
			#e4eaf2,
			#c1d9f2,
			#9ec8f2,
			#83bdec,
			#67b2e5,
			#4390e5,
			#1f6de4,
			#144bed,
			#0a2ec4,
			#020096
		);
		.color-rectangle {
			min-width: calc(100% / 10);
			height: 0.5rem;

			&:not(:last-child) {
				margin-right: 0.1rem;
			}
		}
		@include color-fill-mixin($colors, 'wf');

		&.ice-forecast {
			$colors: (
				#ecdede,
				#eac9d5,
				#e5a8c7,
				#e187b8,
				#dc66aa,
				#d04ba2,
				#c33199,
				#af248e,
				#9b1682,
				#771c64,
				#522246,
				#2e2828
			);

			.color-rectangle {
				min-width: calc(100% / 12);
				height: 0.5rem;

				&:not(:last-child) {
					margin-right: 0.1rem;
				}
			}
			@include color-fill-mixin($colors, 'if');
		}
	}
}
</style>
