import RadarLayer from "./radar-layer";

export default class FutureRadarLayer extends RadarLayer {
  constructor(apiKey) {
    super(apiKey);
    this.layerId = "future-radar";
    this.frames = [];
    this.frameTimes = [];

    this.name = "Future Radar";
    this.category = "wx";
    this.animates = true;
    this.opacity = 1;
    this.legend = true;
  }

  async addToMap(map) {
    await super.addToMap({
      map: map,
      url: `radar/futureSIR/preferred_product_frames?apikey=${this.apiKey}&products=51-1100&interval=4`,
      urlEnd: "&interval=4",
    });
  }
  async removeFromMap(map) {
    await super.removeFromMap(map);
  }
}
