<template>
	<div class="location-search-bar">
		<div class="location-search-bar_content">
			<svg
				class="location-search-bar_content-search-icon"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
			>
				<path
					transform="translate(3 3)"
					d="M7.186 13.554c-3.462 0-6.303-2.869-6.303-6.359C.883 3.681 3.724.837 7.186.837c3.437 0 6.278 2.844 6.278 6.358 0 3.49-2.865 6.359-6.278 6.359zm5.323-1.602a7.176 7.176 0 0 0 1.815-4.757c0-3.968-3.2-7.195-7.138-7.195C3.223 0 0 3.227 0 7.195c0 3.944 3.223 7.171 7.186 7.171a7.058 7.058 0 0 0 4.75-1.84L17.427 18l.573-.55-5.49-5.498z"
				/>
			</svg>
			<div class="location-search-bar_content-text">
				<input
					v-model="locationInput"
					type="text"
					placeholder="Search Location"
				/>
			</div>
			<svg
				viewBox="0 0 24 24"
				class="location-search-bar_settings"
				@click="showSearchSettings()"
			>
				<path
					fill="rgb(158, 158, 158)"
					d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"
				/>
			</svg>
		</div>
		<div class="location-search-bar_results">
			<p
				v-for="location in filteredList"
				v-show="showResults"
				:key="location.key"
				@click="searchResultCTA(location)"
			>
				{{ location.localizedName }},
				{{ location.administrativeArea.localizedName }}
			</p>
		</div>
		<div
			v-if="locationInput && !filteredList.length"
			class="location-search-bar_error-results"
		>
			<p>No results found!</p>
		</div>
	</div>
</template>

<script>
import {
	autoCompleteLookup,
	addMarkerToLocation,
} from './location-search-service';
import { getLocationInformationAsync } from '../../services/api/accuweather';
import debounce from 'debounce';

export default {
	name: 'LocationSearchBar',
	data() {
		return {
			locationInput: '',
			showResults: false,
			list: [],
		};
	},
	computed: {
		savedLocations() {
			return this.$store.getters.getSavedLocations;
		},
		filteredList() {
			return this.list;
		},
		addedLocation() {
			return this.$store.getters.getAddedLocation;
		},
		removedLocation() {
			return this.$store.getters.getRemovedLocation;
		},
		removedMaxLocation() {
			return this.$store.getters.getMaxRemovedLocation;
		},
	},
	watch: {
		savedLocations: {
			immediate: true,
			async handler() {},
		},
		locationInput: debounce(async function () {
			this.list = [];
			if (this.locationInput) {
				this.list = await autoCompleteLookup(this.locationInput);
			}
			this.list.length ? (this.showResults = true) : (this.showResults = false);
		}, 250),
		async addedLocation() {
			const loc = await getLocationInformationAsync(this.addedLocation);
			await addMarkerToLocation(loc);
			// Fly to new location marked on map
			const isMobile = window.matchMedia(
				'only screen and (max-width: 768px)'
			).matches;
			window.mapObj.flyTo({
				center: [loc.GeoPosition.Longitude, loc.GeoPosition.Latitude],
				zoom: isMobile ? 8 : 6,
				speed: 1,
				curve: 1,
				easing(t) {
					return t;
				},
			});
			// Clear the input field
			this.locationInput = '';
		},
		async removedLocation() {
			await this.removeMarker(this.removedLocation);
		},
		async removedMaxLocation() {
			await this.markerLimitReached(this.removedMaxLocation);
		},
	},
	methods: {
		showSearchSettings() {
			this.$store.dispatch('setSettingsModalVisibility');
		},
		async searchResultCTA(location) {
			if (this.savedLocations.find((loc) => loc === location.key)) {
				this.sendToast('This marker already exists');
				return;
			}
			this.$store.dispatch('saveSearchedLocation', location.key);
		},
		async removeMarker(key) {
			const paintedMarkers = [
				...document.querySelectorAll(
					`.location-box,
					.location-box_line`
				),
			];
			paintedMarkers.forEach((m) => {
				if (m.id.includes(key)) {
					m.remove();
				}
			});
		},
		async markerLimitReached(key) {
			await this.removeMarker(key);
			this.sendToast(
				'Max locations is 5. Your oldest location pin was removed.'
			);
		},
		sendToast(message) {
			this.$toast.warning(`${message}`, {
				position: 'top-center',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: true,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false,
			});
		},
	},
};
</script>
<style lang="scss">
@import '../../assets/styles/colors.scss';

.location-search-bar {
	font-family: 'Solis';
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 1rem;
	right: 4rem;
	height: 1.8rem;
	border-radius: 4px;
	background: rgba(255, 255, 255, 0.95);
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
	min-width: 14rem;
	max-width: 14rem;

	&_settings {
		cursor: pointer;
		width: 22px;
		height: 22px;
	}

	&_content {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: space-around;

		&-text {
			height: inherit;
			display: flex;

			input {
				background: none;
				border: none;
				font-size: 1rem;
				max-width: 10rem;
			}
		}

		&-search-icon {
			flex-shrink: 0;
			width: 22px;
			height: 22px;
		}
	}

	&_results {
		background: rgba(255, 255, 255, 0.7);
		text-align: center;
		border-radius: 4px;
		font-weight: 500;
		padding: 0 0.5rem 0 0.5rem;
	}

	&_error-results {
		background: rgba(255, 255, 255, 0.7);
		border-radius: 4px;
		text-align: center;
		font-weight: 500;
	}
}

.location-box_line {
	cursor: pointer;
	width: 0.15rem;
	height: 1rem;
	top: -1rem;
	background-color: #fff;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}

.location-box {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: -2.4rem;
	min-width: 4rem;
	height: 2rem;
	border-radius: 25px;
	line-height: 14px;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
	background-color: #fff;
	font-size: 0.7rem;
	font-family: 'Solis';
	padding: 0px 20px;

	p {
		margin: 0px;
	}

	&_text {
		font-weight: 700;
	}

	&_forecast-text {
		color: $blood-orange;
	}
}
</style>
