import { accuWeatherLayerApiKey } from '../../../../../utils/api-keys';

export const drawImpactPolygons = (map, store, storm) => {
	const impactURL = `https://api.accuweather.com/maps/v1/tropical/hurricane/zxy/{z}/{x}/{y}.png?apikey=${accuWeatherLayerApiKey}`;
	const eventKey = `&eventkey=${storm.eventKey}`;
	const impactTypes = [
		'RISK_TO_LIFE_PROPERTY',
		'RAINFALL',
		'MAXIMUM_SUSTAINED_WINDS',
		'MAXIMUM_WIND_GUSTS',
		'STORM_SURGE',
	];

	if (storm.forecast.length > 0) {
		impactTypes.forEach((type) => {
			map.addLayer({
				id: `tropicalImpacts-${type}`,
				source: {
					tiles: [`${impactURL}${eventKey}&categories=${type}`],
					type: 'raster',
					tileSize: 256,
				},
				type: 'raster',
				minzoom: 3,
				maxzoom: 24,
				layout: {
					visibility: 'none',
				},
				paint: {
					'raster-opacity': 0.75,
				},
			});
		});
	}
};
