<template>
	<div
		v-show="showPlaybar"
		ref="playbar"
		class="play-bar"
	>
		<div
			class="play-bar-toggle"
			@click="toggleAnimation"
		>
			<div class="play-bar-icon"></div>
		</div>
		<div
			ref="playBarScrubber"
			class="play-bar-scrubber"
		>
			<div
				v-show="nowTextShow"
				ref="scrubberNowText"
				class="scrubber-now-text"
			>
				Now
			</div>
			<div
				ref="playBarProgress"
				class="play-bar-progress"
				:style="{ width: playbarTrackWidth }"
			></div>
		</div>
	</div>
</template>

<script>
import { debounce } from 'debounce';
import {
	startAnimatingMapLayers,
	stopAnimatingMapLayers,
	tryChangeFrame,
} from '../../services/animation-engine';

export default {
	name: 'PlayBarComponent',
	props: {
		showPlaybar: Boolean,
		playBarTimes: Array,
		devAPIEnvironment: Boolean,
		activeLayers: Array,
		mapStyle: String,
		animationIndex: Number,
	},
	data() {
		return {
			animating: false,
			playbarTrackWidth: null,
			interval: null,
			isDragging: false,
			svgEl: null,
			nowIndex: 0,
			nowTextShow: false,
			changeTickWidth: true,
		};
	},
	computed: {
		overlayLayer() {
			return this.$store.getters.getOverlayLayers;
		},
		tickWidth() {
			this.changeTickWidth;
			return this.playBarTimes?.length > 1
				? this.getScrubberWidth() / (this.playBarTimes.length - 1)
				: 0;
		},
	},

	watch: {
		animationIndex() {
			this.drawTrack();
		},
		overlayLayer() {
			this.drawTrack();
		},
		activeLayers() {
			if (this.svgEl?.children) {
				this.animating = false;
				this.$refs.playbar.classList.remove('is-playing');
				stopAnimatingMapLayers();
				this.removeAllChildNodes(this.svgEl);
			}
		},
		playBarTimes() {
			this.nowTextShow = false;
			if (this.playBarTimes?.length) {
				this.addTrackLines();
			}
		},
		mapStyle() {
			if (this.svgEl?.children) {
				this.$refs.playbar.classList.remove('is-playing');
				this.animating = false;
				stopAnimatingMapLayers();
				this.$store.dispatch('clearFrameIndex');
				this.removeAllChildNodes(this.svgEl);
			}
		},
		devAPIEnvironment() {
			if (this.svgEl?.children) {
				this.animating = false;
				this.$refs.playbar.classList.remove('is-playing');
				stopAnimatingMapLayers();
				this.removeAllChildNodes(this.svgEl);
			}
		},
	},

	created() {
		window.addEventListener('resize', this.handleWindowResize);
	},

	methods: {
		// UTIL METHODS
		getScrubberWidth() {
			return this.$refs.playBarScrubber.clientWidth;
		},
		// getting index of current time
		getNowIndex() {
			const currTime = new Date().toISOString().split('.')[0] + 'Z';
			let filteredTimes = this.playBarTimes.filter((t) => t < currTime);
			let nowTime = filteredTimes[filteredTimes.length - 1];
			this.playBarTimes.forEach((time, index) => {
				if (time === nowTime) {
					this.nowIndex = index;
				}
			});
		},
		removeAllChildNodes(parent) {
			while (parent.firstChild) {
				parent.removeChild(parent.firstChild);
			}
		},
		handleWindowResize: debounce(function () {
			if (this.showPlaybar) {
				if (this.animating) {
					this.toggleAnimation();
				}
				this.changeTickWidth = !this.changeTickWidth;
				this.playbarTrackWidth = `${
					this.animationIndex * this.tickWidth + 1
				}px`;
				this.addTrackLines();
			}
		}, 50),

		// ANIMATION CONTROL
		toggleAnimation() {
			this.animating = !this.animating;
			if (this.animating) {
				this.$refs.playbar.classList.add('is-playing');
				startAnimatingMapLayers();
			} else {
				this.$refs.playbar.classList.remove('is-playing');
				stopAnimatingMapLayers();
			}
		},

		// DRAWING THE TRACK LINES, NOW TEXT AND PROGRESS BAR
		addTrackLines() {
			if (this.svgEl === null) {
				const trackClicked = (e) => this.trackOnClick(e);
				this.svgEl = document.createElementNS(
					'http://www.w3.org/2000/svg',
					'svg'
				);
				this.svgEl.classList.add('play-bar-track');
				this.svgEl.addEventListener('mousedown', trackClicked);
				this.$refs.playBarScrubber.appendChild(this.svgEl);
			}

			// resetting the playbar ticks to empty before adding new ticks
			this.removeAllChildNodes(this.svgEl);

			this.playBarTimes.forEach((time, index) => {
				let x = this.lineXValue(index);
				let line = document.createElementNS(
					'http://www.w3.org/2000/svg',
					'line'
				);
				line.setAttribute('x1', x);
				line.setAttribute('x2', x);
				line.setAttribute('y1', 0);
				line.setAttribute('y2', '100%');
				this.svgEl.appendChild(line);
			});
			this.getNowIndex();
			this.positionNowText();
		},
		positionNowText() {
			this.$refs.scrubberNowText.style.left = `${
				this.nowIndex * this.tickWidth
			}px`;
			this.nowTextShow = true;
		},
		lineXValue(index) {
			return index * this.tickWidth + 0.5;
		},
		drawTrack() {
			// console.log('draw track');
			const playBarScrubber = this.$refs.playBarScrubber;
			const dragStart = (e) => this.progressBarDragStart(e);
			const dragEnd = (e) => this.progressBarDragEnd(e);
			const drag = (e) => this.progressBarDrag(e);

			playBarScrubber.addEventListener('mousedown', dragStart, false);
			playBarScrubber.addEventListener('mouseup', dragEnd, false);
			playBarScrubber.addEventListener('mousemove', drag, false);
			playBarScrubber.addEventListener('mouseleave', dragEnd, false);

			this.playbarTrackWidth = `${this.animationIndex * this.tickWidth + 1}px`;
		},

		// PLAYBAR CLICK AND DRAG EVENTS
		trackOnClick(e) {
			const x =
				e.clientX - this.$refs.playBarScrubber.getBoundingClientRect().left;
			// console.log(`x = ${x}`);
			const frameIndex = Math.round(x / this.tickWidth);
			tryChangeFrame(frameIndex);
		},
		dragHandler(e) {
			const playBarScrubber = this.$refs.playBarScrubber;
			let x;
			x = e.clientX - playBarScrubber.getBoundingClientRect().left;

			// To Stop the animation and reflect that to user
			this.$refs.playbar.classList.remove('is-playing');
			this.animating = false;
			stopAnimatingMapLayers();

			const frameIndex = Math.round(x / this.tickWidth);
			tryChangeFrame(frameIndex);
		},
		progressBarDragStart(e) {
			// console.log('start drag');
			this.dragHandler(e);
			this.isDragging = true;
		},
		progressBarDragEnd() {
			// console.log('end drag');
			this.isDragging = false;
		},
		progressBarDrag(e) {
			if (this.isDragging) {
				// console.log('progress drag');
				e.preventDefault();
				this.dragHandler(e);
			}
		},
	},
};
</script>
<style lang="scss">
// Style imports
@import '@/assets/styles/mixins.scss';
.play-bar {
	display: flex;
	align-items: center;
	color: #000;
	font-size: 0.8rem;
	position: relative;
	z-index: 3;
	height: 3rem;
	border-radius: 23px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
	background-color: #e5eaf1;
	width: 100%;

	&.is-playing {
		.play-bar-progress {
			background-color: #000;
		}
	}

	.play-bar-toggle {
		width: 3rem;
		height: 3rem;
		border-radius: 23px;
		background-color: #ffffff;
		margin-right: 0.5rem;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.play-bar-icon {
		width: 0.5rem;
		height: 0.6rem;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		border-left: 7px solid #000;
	}
	&.is-playing .play-bar-icon {
		display: flex;
		justify-content: space-between;
		border: 0;
		&:before,
		&:after {
			content: '';
			width: 0.2rem;
			background: #000;
		}
	}
	.play-bar-current-time,
	.play-bar-end-time {
		margin-right: 0.5rem;
		width: 3rem;
		flex-shrink: 0;
		white-space: nowrap;
	}
	.play-bar-end-time {
		width: 3.5rem;
		text-align: right;
	}
	.day-name {
		font-size: 0.6rem;
	}
	.play-bar-scrubber {
		display: flex;
		position: relative;
		margin-left: 0.2rem;
		margin-right: 1.5rem;
		padding: 1rem 0;
		cursor: default;
		flex-grow: 1;

		.scrubber-now-text {
			bottom: 0;
			color: #666c72;
			font-size: 0.6rem;
			position: absolute;
			transform: translateX(-50%);
			white-space: nowrap;
		}
	}
	.play-bar-progress {
		height: 0.5rem;
		background: #9fa3a8;
		position: absolute;
		top: 1rem;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		&:after {
			content: '';
			width: 1px;
			height: 1rem;
			background: inherit;
		}
	}
	.play-bar-track {
		height: 0.5rem;
		width: 100%;
		overflow: visible;
		line {
			stroke-width: 1px;
			stroke: #9fa3a8;
			shape-rendering: geometricPrecision;
			&.now-marker {
				stroke: #9fa3a8;
			}
		}
	}
}
</style>
