<template>
	<div class="wind-flow-legend">
		<div class="legend-title">
			<b>{{ title }}</b>
		</div>
		<div class="legend-item">
			<span
				v-for="colorBx in colorBoxes"
				:key="colorBx"
				class="color-rectangle"
				:class="colorBx"
			></span>
		</div>
		<div class="legend-footer">
			<span
				v-for="count in colorText"
				:key="count"
				class="footer-text"
			>{{
				count
			}}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WindFlowLegend',
	data() {
		return {
			title: 'Wind (mph)',
			colorBoxes: this.legendColorBoxes(),
			colorText: this.legendColorText(),
		};
	},
	methods: {
		legendColorBoxes() {
			let count;
			let countArray = [];
			count = 9;
			for (let index = 1; index <= count; index++) {
				countArray.push(`wf-${index}`);
			}
			return countArray;
		},
		legendColorText() {
			return ['<7', '7', '13', '20', '30', '40', '50', '60', '>70'];
		},
	},
};
</script>
