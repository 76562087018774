import { accuWeatherLayerApiKey } from '../../components/utils/api-keys';

const sdkP = require('accunodesdk');
// const sdkI = require('accunodeinternalsdk');
const apiSettings = new sdkP.ApiSettings(accuWeatherLayerApiKey);
apiSettings.timeoutPerTry = 5000; // increase default timeout
const serviceProvider = sdkP.ServiceModule.create(
	new sdkP.SdkSettings(apiSettings)
);

// From The Weather Services
const weatherService = serviceProvider.weatherService;
// From The Location Services
const locationService = serviceProvider.locationService;

export async function getCurrentConditionsAsync() {
	const request = new sdkP.CurrentConditionsByLocationKeyRequest(
		'en-us',
		'331603'
	); // 'WYCASP'
	request.details = true;
	const response = await weatherService.getCurrentConditionsByLocationKey(
		request
	);
	if (response.hasError) {
		console.log('null');
		return null;
	}
	// return response.data;
	console.log(response.data);
}

export async function getLocationInformationAsync(key) {
	const request = new sdkP.LocationByKeyRequest();
	(request.language = 'en-us'),
		(request.locationKey = key),
		(request.details = true);
	const response = await locationService.getLocationByKey(request);
	if (response.hasError) {
		console.error(
			'Error in the getLocationInformationAsync call',
			response.error
		);
		return response.error;
	}
	return response.data;
}

export async function getLocationInformationByLatLngAsync(coords) {
	const request = new sdkP.CityByGeopositionRequest();
	(request.language = 'en-us'),
		(request.latitude = coords[0]),
		(request.longitude = coords[1]),
		(request.details = true);
	const response = await locationService.getCityByGeoposition(request);
	if (response.hasError) {
		console.error(
			'Error in the getLocationInformationByLatLngAsync call',
			response.error
		);
		return response.error;
	}
	return response.data;
}

export default {
	getCurrentConditionsAsync,
	getLocationInformationAsync,
	getLocationInformationByLatLngAsync,
};
