export const getFirstSymbolLayer = (map) => {
	let firstSymbolLayerID;
	map.getStyle().layers.some((layer) => {
		if (layer.type === 'symbol') {
			firstSymbolLayerID = layer.id;
			return true;
		}
		return false;
	});
	return firstSymbolLayerID;
};

export const baseLayerFirst = (map) => {
	const layers = map.getStyle().layers;
	for (const layer of layers) {
		if (
			layer.source !== 'composite' &&
			layer.type !== 'symbol' &&
			layer.type !== 'background'
		) {
			map.moveLayer(layer.id);
		}
	}
};

export const latLonToValidString = (bounds, isNorthWest) => {
	let lat = Math.floor(bounds.lat);
	let lng = Math.ceil(bounds.lng);
	if (isNorthWest) {
		lat = Math.ceil(bounds.lat);
		lng = Math.floor(bounds.lng);
	}
	lat = Math.min(lat, 85);
	lat = Math.max(lat, -85);
	lng = Math.min(lng, 179);
	lng = Math.max(lng, -179);
	return `${lat},${lng}`;
};

export const getMapTheme = (store) => {
	const mapStyle = store.getters.getMapStyle;
	return mapStyle[0].longStyle;
};

// Accepts both the className/classId or the element itself
export const disableMenuItem = (el) => {
	const element = typeof el === 'string' ? document.querySelector(`${el}`) : el;
	element.style.pointerEvents = 'none';
	element.style.color = '#3D3D3D';
};
// Accepts both the className/classId or the element itself
export const enableMenuItem = (el) => {
	const element = typeof el === 'string' ? document.querySelector(`${el}`) : el;
	element.style.pointerEvents = 'auto';
	element.style.color = '';
};
