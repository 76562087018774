import { accuWeatherLayerApiKey } from '../../utils/api-keys';
import { addLightningLayerImages } from '../layers';
import VectorLayerLegacy from './parent-layers/vector-layer-legacy';

export default class LightningLayer extends VectorLayerLegacy {
	constructor() {
		super();
		this.layerId = 'lightning';
		this.name = 'Lightning';
		this.category = 'wx';
		this.animates = true;
		this.frames = [];
		this.opacity = 1;
	}
	async addToMap(map) {
		// Adding images
		// map.on('styleimagemissing', () => {
		addLightningLayerImages(map);
		// });
		await super
			.vectorCallback({
				url: `lightning/vector/data/tiles.json?apikey=${accuWeatherLayerApiKey}`,
			})
			.then((layerMeta) => {
				this.lightningURL = layerMeta.tiles[0];
				this.frames = layerMeta.vector_layers
					.sort((a, b) => (a.description > b.description ? 1 : -1))
					.filter((i, index) => index >= layerMeta.vector_layers.length - 7);
				super.sendToStore(this.frames);

				this.frames.forEach((frame, index) => {
					map.addLayer({
						id: `${this.layerId}-${frame.description}`,
						source: {
							type: 'vector',
							tiles: [`${this.lightningURL}?apikey=${accuWeatherLayerApiKey}`],
						},
						'source-layer': frame.id,
						type: 'symbol',
						layout: {
							// This is the textual representation of the data
							'text-size': 16,
							'text-font': ['Solis Bold', 'Arial Unicode MS Regular'],
							'text-field': [
								'case',
								['>', ['to-number', ['get', 'point_count']], 9],
								['get', 'point_count'],
								'',
							],
							'text-anchor': 'left',
							'text-offset': [-0.35, 0.15],
							'text-allow-overlap': true,
							'text-ignore-placement': true,
							// This is the lightning Icons
							'icon-image': [
								'case',
								['has', 'point_count'],
								[
									'case',
									['>', ['to-number', ['get', 'point_count']], 9],
									'custom-marker-cluster-white',
									'custom-marker-single-white',
									// !this.active
									// 	? 'custom-marker-cluster-white'
									// 	: 'custom-marker-cluster-yellow',
									// !this.active
									// 	? 'custom-marker-single-white'
									// 	: 'custom-marker-single-yellow',
								],
								['!', ['has', 'point_count']],
								'custom-marker-single-white',
								'custom-marker-cluster-white',
								// !this.active
								// 	? 'custom-marker-single-white'
								// 	: 'custom-marker-single-yellow',
								// !this.active
								// 	? 'custom-marker-cluster-white'
								// 	: 'custom-marker-cluster-yellow',
							],
							'icon-size': 0.5,
							'icon-allow-overlap': true,
							'icon-ignore-placement': true,
						},
						paint: {
							// This is the textual representation of the data
							'text-color': 'white',
							'text-opacity': this.active && index === 0 ? 1 : 0,
							'text-opacity-transition': {
								duration: 500,
							},
							// This is the lightning icon
							'icon-opacity': this.active && index === 0 ? 1 : 0,
							'icon-opacity-transition': {
								duration: 500,
							},
						},
					});
					if (
						!this.active &&
						frame.description ===
							this.store.getters.getSynchedData[
								this.store.getters.getCurrentFrameIndex
							]
					) {
						map.setPaintProperty(
							`${this.layerId}-${frame.description}`,
							`text-opacity`,
							1
						);
						map.setPaintProperty(
							`${this.layerId}-${frame.description}`,
							`icon-opacity`,
							1
						);
					}
				});
			});
	}

	removeFromMap(map) {
		map.getStyle().layers.forEach((layer) => {
			if (layer.id.includes(`${this.layerId}`)) {
				map.removeLayer(layer.id);
				map.removeSource(layer.id);
			}
		});
		super.removeLayer(this.layerId);
	}

	// Animation Control
	previousLayer(map, time, lastFrame) {
		if (
			map.getStyle().layers.some((l) => l.id.includes('lightning')) &&
			this.frames.find((t) => t.description.includes(time))
		) {
			if (lastFrame === true) {
				map.setPaintProperty(
					`${this.layerId}-${time}`,
					'icon-opacity-transition',
					{ duration: 250 }
				);
			} else {
				map.setPaintProperty(
					`${this.layerId}-${time}`,
					'icon-opacity-transition',
					{ duration: 1000 }
				);
			}
			map.setPaintProperty(`${this.layerId}-${time}`, 'text-opacity', 0);
			map.setPaintProperty(`${this.layerId}-${time}`, 'icon-opacity', 0);
		}
	}

	currentLayer(map, time) {
		if (
			map.getStyle().layers.some((l) => l.id.includes('lightning')) &&
			this.frames.find((t) => t.description.includes(time))
		) {
			map.setPaintProperty(
				`${this.layerId}-${time}`,
				'icon-opacity-transition',
				{ duration: 500 }
			);
			map.setPaintProperty(`${this.layerId}-${time}`, 'text-opacity', 1);
			map.setPaintProperty(`${this.layerId}-${time}`, 'icon-opacity', 1);
		}
	}
}
