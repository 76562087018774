export default class StyleLayer {
	constructor() {
		this.name = 'MapStyle';
		this.category = 'style';
		this.longStyle =
			localStorage.getItem('mapStyle') ||
			'mapbox://styles/accuweather-inc/cjknbnj4m04hn2snt04lpb2v5';
		this.styleName = localStorage.getItem('styleName') || 'Dark Basemap';
	}
	changeStyle(name) {
		switch (name) {
			case 'Light Basemap':
				this.styleName = name;
				this.longStyle =
					'mapbox://styles/accuweather-inc/cjknc24na2o5u2sqoy0t8ku8a';
				localStorage.setItem('mapStyle', this.longStyle);
				localStorage.setItem('styleName', this.styleName);
				break;
			case 'Dark Basemap':
				this.styleName = name;
				this.longStyle =
					'mapbox://styles/accuweather-inc/cjknbnj4m04hn2snt04lpb2v5';
				localStorage.setItem('mapStyle', this.longStyle);
				localStorage.setItem('styleName', this.styleName);
				break;
			case 'Terrain Basemap':
				this.styleName = name;
				this.longStyle =
					'mapbox://styles/accuweather-inc/ckw6m428f1ay614o3hb5wyoj1';
				localStorage.setItem('mapStyle', this.longStyle);
				localStorage.setItem('styleName', this.styleName);
				break;
		}
	}
}
