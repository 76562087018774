import acuSDKTropical from '../../../../../../services/api/tropical-service';
import { buildFEPMockData } from '../mock-data/active-storms-mock-data';

export const buildSelectedStormObj = async (storm) => {
	let selectedStormInfo = [];
	let modelTime = '';
	let atcfStorm = {};

	// Guard Clause to look for Mock Data
	if (storm?.info?.isMock) {
		let [historical, forecast, actf] = buildFEPMockData();
		selectedStormInfo.push({
			historical: historical,
			forecast: forecast,
			atcf: { Mock: true, Models: actf },
		});
		return selectedStormInfo[0];
	}

	// Check to see if storm is in the EP, CP or AL Basin
	if (
		storm.info.basinId === 'EP' ||
		storm.info.basinId === 'CP' ||
		storm.info.basinId === 'AL'
	) {
		const atcfStorms = await acuSDKTropical.internalATCFStormsAsync();

		if (atcfStorms.length > 0) {
			let modelRunTimes;
			let atcfStormObj = atcfStorms.find((l) => l.AtcfID === storm.info.govId);
			if (atcfStormObj) {
				modelRunTimes = atcfStormObj?.ModelInitializationTimes;
				modelTime = new Date(modelRunTimes[modelRunTimes?.length - 1]);
				atcfStorm = await acuSDKTropical.internalStormATCFTracksAsync(
					storm.info,
					modelTime.toUTCString()
				);
			}
		}
	}

	const historical = await acuSDKTropical.stormPositionsAsync(storm.info);
	const forecast = await acuSDKTropical.internalStormsForecastAsync(storm.info);
	// const impactPolygons = await acuSDKTropical.internalStormsForecastAsync(storm.info);

	selectedStormInfo.push({
		historical: historical,
		forecast: forecast,
		atcf: Object.keys(atcfStorm).length === 0 ? null : atcfStorm,
		// impacts: Object.keys(impactPolygons).length === 0 ? null : impactPolygons,
		eventKey: storm.info.eventKey,
	});

	return selectedStormInfo[0];
};
