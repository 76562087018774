const locations = {
	savedLocations: JSON.parse(localStorage.getItem('savedLocations')) || [],
	lastSearchedLocation: localStorage.getItem('lastSearchedLocation') || [],
	locationAdded: '',
	locationRemoved: '',
	maxLocationRemoved: '',
};

// Mutations
const mutations = {
	SAVE_LOCATION(state, key) {
		state.locationAdded = key;
		if (state.savedLocations.length === 5) {
			state.maxLocationRemoved = state.savedLocations[0];
			state.savedLocations.shift();
		}
		state.savedLocations.push(key);
		localStorage.setItem(
			'savedLocations',
			JSON.stringify(state.savedLocations)
		);
	},
	REMOVE_SAVED_LOCATION(state, key) {
		// updating which location was removed
		state.locationRemoved = key;
		// Removing location from the savedLocations Array
		if (state.savedLocations.length) {
			state.savedLocations = state.savedLocations.filter((loc) => loc !== key);
		}
		// Updating local storage
		localStorage.setItem(
			'savedLocations',
			JSON.stringify(state.savedLocations)
		);
	},
};

const actions = {
	saveSearchedLocation: ({ commit }, key) => commit('SAVE_LOCATION', key),
	removeSavedLocation: ({ commit }, key) =>
		commit('REMOVE_SAVED_LOCATION', key),
};

const getters = {
	getSavedLocations: (state) => state.savedLocations,
	getAddedLocation: (state) => state.locationAdded,
	getLastSearchedLocation: (state) => state.lastSearchedLocation,
	getRemovedLocation: (state) => state.locationRemoved,
	getMaxRemovedLocation: (state) => state.maxLocationRemoved,
};

export default {
	state: locations,
	mutations,
	actions,
	getters,
};
