<template>
	<div
		v-show="settingsBoxShow"
		class="location-settings-modal"
	>
		<div class="location-settings-modal_close-btn">
			<svg
				style="width: 24px; height: 24px"
				viewBox="0 0 24 24"
				@click="closeSettingsModal()"
			>
				<path
					fill="currentColor"
					d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
				/>
			</svg>
		</div>
		<div
			v-if="!savedLocationList.length"
			class="location-settings-modal_no-locations-text"
		>
			<p>There are no saved locations...</p>
		</div>
		<div
			v-for="location in savedLocationList"
			:key="location.id"
			class="location-settings-modal_location-list"
		>
			<svg
				style="width: 20px; height: 20px"
				viewBox="0 0 24 24"
				class="remove-location-btn"
				@click="removeSavedLocation(location.id)"
			>
				<path
					fill="rgba(170, 0, 0, 0.95)"
					d="M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
				/>
			</svg>
			<hr />
			<p
				class="location-name"
				@click="goToLocation(location.coords)"
			>
				{{ location.city }}, {{ location.country }}
			</p>
		</div>
	</div>
</template>

<script>
import { getLocationInformationAsync } from '../../services/api/accuweather';

export default {
	data() {
		return {
			savedLocationList: [],
		};
	},
	computed: {
		savedLocations() {
			return this.$store.getters.getSavedLocations;
		},
		settingsBoxShow() {
			return this.$store.getters.getSettingsModalVisibility;
		},
	},
	watch: {
		savedLocations: {
			immediate: true,
			handler() {
				this.savedLocationList = [];
				this.savedLocations.forEach((location) => {
					getLocationInformationAsync(location).then((loc) => {
						const obj = {
							city: loc.LocalizedName,
							country: loc.Country.LocalizedName,
							id: location,
							coords: [loc.GeoPosition.Longitude, loc.GeoPosition.Latitude],
						};
						this.savedLocationList.push(obj);
					});
				});
			},
		},
	},
	methods: {
		closeSettingsModal() {
			this.$store.dispatch('setSettingsModalVisibility');
		},
		removeSavedLocation(key) {
			this.$store.dispatch('removeSavedLocation', key);
		},
		goToLocation(coords) {
			// Fly to new location marked on map
			const isMobile = window.matchMedia(
				'only screen and (max-width: 768px)'
			).matches;
			window.mapObj.flyTo({
				center: coords,
				zoom: isMobile ? 8 : 6,
				speed: 1,
				curve: 1,
				easing(t) {
					return t;
				},
			});
		},
	},
};
</script>
<style lang="scss">
.location-settings-modal {
	position: absolute;
	display: flex;
	flex-direction: column;
	padding: 0.2rem;
	right: 1rem;
	bottom: 15rem;
	min-width: 12rem;
	min-height: 15rem;
	background: rgba(255, 255, 255, 0.95);
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
	border-radius: 4px;
	font-size: 0.8rem;

	&_close-btn {
		display: flex;
		justify-content: flex-end;
		padding-bottom: 1rem;
	}

	&_no-locations-text {
		height: 10rem;
		display: flex;
		flex-direction: column;
		font-weight: 500;
		text-align: center;
		justify-content: center;
	}

	&_location-list {
		display: flex;

		.remove-location-btn {
			height: 100%;
			min-width: 2rem;
			display: flex;
			text-align: center;
			justify-content: center;
		}

		.location-name {
			padding-left: 0.2rem;
			min-width: 10rem;
			font-weight: 500;
		}
	}
}
</style>
