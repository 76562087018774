import { accuWeatherLayerApiKey } from '../../../utils/api-keys';
import RasterLayer from '../parent-layers/raster-layer-legacy';
import {
	addContoursLayer,
	addAQIValuesLayer,
	updateDataPoints,
	getDataPoints,
} from './air-quality-map-layers';

let eventPassedMove;
let eventPassedClick;

export default class AirQualityPlumeLayer extends RasterLayer {
	constructor() {
		super();
		this.layerId = 'air-quality-plume';
		this.name = 'Air Quality Plume';
		this.category = 'wx';
		this.legend = true;
		this.opacity = 0.7;
	}

	async addToMap(map) {
		await super
			.getApiData({
				url: `current/airquality/PlumeIndex/preferred_product_frames?apikey=${accuWeatherLayerApiKey}`,
			})
			.then((layerMeta) => {
				layerMeta.frames.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
				this.aqFrames = `${layerMeta.url}zxy/${layerMeta.frames[0]}/{z}/{x}/{y}.png?apikey=${accuWeatherLayerApiKey}`;

				// Adding the data point values layer
				addAQIValuesLayer(map, false, this.layerId);
				// Adding contour map layer
				addContoursLayer(map, this.aqFrames, this.layerId);
			});
		// Getting the data points on layer creation
		await updateDataPoints(map, this.layerId);

		eventPassedMove = () => updateDataPoints(map, this.layerId);
		map.on('moveend', eventPassedMove);
		// Call when map moves
		eventPassedClick = (e) => getDataPoints(map, this.layerId, e);
		map.on('click', eventPassedClick);
	}

	removeFromMap(map) {
		let pin = document.querySelector('.pin');
		if (pin) {
			pin.remove();
		}
		map.off('moveend', eventPassedMove);
		map.off('click', eventPassedClick);

		map.getStyle().layers.forEach((layer) => {
			if (layer.id.includes(`${this.layerId}`)) {
				map.removeLayer(layer.id);
				map.removeSource(layer.source);
			}
		});
		super.removeLayer(this.layerId);
	}
}
