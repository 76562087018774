/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log("Service worker is active.");
  },
  registered(registration) {
    console.log("Service worker has been registered.");

    // Checking for an update manually every 1 hour
    setInterval(() => {
      registration.update().then(() => {
        console.log("Service Worker update checked");
      });
    }, 1000 * 60 * 60);

    // Force update on first load from a closed state
    if (navigator.serviceWorker.controller) {
        const listener = () => {
          // Reload the page to activate the new service worker
          registration.update();
        };
  
        // Add an event listener for the service worker state change
        navigator.serviceWorker.controller.onstatechange = listener;
      }
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading...");
  },
  updated() {
    console.log(
      "New content is available, please refresh the page or reload the app."
    );
    window.dispatchEvent(new Event("newcontent"));
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
});

}
