import {adjustPolygonLongitudes, adjustMultiPolygonLongitudes} from '../utils/tropical-map-bounds';

const defaultColor = 'rgb(225, 240, 255)';
const indexedColorDictionary = {
	0: 'rgb(35, 55, 75)',
	1: 'rgb(45, 65, 85)',
	2: 'rgb(60, 80, 100)',
	3: 'rgb(80, 100, 125)',
	4: 'rgb(95, 120, 150)',
	5: 'rgb(115, 145, 180)',
	6: 'rgb(160, 180, 200)',
	7: 'rgb(180, 200, 225)',
	8: 'rgb(200, 225, 240)',
};

export const drawWindowData = (map, store, forecastPoints) => {
	// Short circuit logic
	if (!forecastPoints || forecastPoints.length === 0) {
		return;
	}

	forecastPoints.forEach((coords, index) => {
		// Missing data logic
		if (coords?.window) {
			const color = indexedColorDictionary[index] || defaultColor;
			coords.window.geometry = coords.window.geometry.type == 'MultiPolygon' ? adjustMultiPolygonLongitudes(coords.window.geometry) : adjustPolygonLongitudes(coords.window.geometry);

			if (coords.window.geometry.type == 'MultiPolygon') {
				coords.window.geometry.coordinates.forEach((subPolygon, index2) => {
					addLayerToMap(map, `${index}.${index2}`, subPolygon, color);
				});
			} else {
				addLayerToMap(map, index, coords.window.geometry.coordinates, color);
			}
		}
	});

	// This will set the windows along the forecast track as visible or not with the icons as well
	map.on('zoom', () => {
		if (store.getters.getEyePathLayers.includes('windows')) {
			map.getStyle().layers.forEach((layer) => {
				if (layer.id.includes('trackWindow-')) {
					map.setLayoutProperty(layer.id, 'visibility', 'visible');
				}
			});
		} else {
			map.getStyle().layers.forEach((layer) => {
				if (layer.id.includes('trackWindow-')) {
					map.setLayoutProperty(layer.id, 'visibility', 'none');
				}
			});
		}
	});
};

function addLayerToMap(map, index, coords, color) {
	map.addLayer({
		id: `trackWindow-${index}`,
		type: 'fill',
		minzoom: 3,
		source: {
			type: 'geojson',
			data: {
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'Polygon',
					coordinates: coords,
				},
			},
		},
		paint: {
			'fill-color': color,
			'fill-opacity': 0.5,
			'fill-outline-color': '#fff',
		},
	});
}
