import * as util from '../../../../utils/web-gl-utils';

import windFragmentSrc from './shaders/wind/fragment.frag';
import windVertexSrc from './shaders/wind/vertex.vert';

import WindLayer from './wind-layer';

import mapboxgl from 'mapbox-gl';

Math.clamp = function (number, min, max) {
	return Math.max(min, Math.min(number, max));
};

export default class WindSpeedLayer extends WindLayer {
	constructor(id, windData) {
		super(id, windData);

		this.maxZoom = 12;
		this.opacity = 0.85;
		this.enabled = true;
	}

	onAdd(map, gl) {
		super.onAdd(map, gl);

		this.windProgram = util.createProgram(gl, windVertexSrc, windFragmentSrc);
		gl.vertexAttribPointer(
			this.windProgram.a_tex_coord,
			2,
			gl.FLOAT,
			false,
			0,
			0
		);

		gl.enableVertexAttribArray(this.windProgram.a_tex_coord);
	}

	render(gl) {
		if (!this.enabled) {
			return;
		}
		if (!this.ready) {
			return;
		}

		const bounds = this.map.getBounds();
		const northWest = mapboxgl.MercatorCoordinate.fromLngLat(
			bounds.getNorthWest()
		);
		const southEast = mapboxgl.MercatorCoordinate.fromLngLat(
			bounds.getSouthEast()
		);

		this.northWest = [northWest.x, northWest.y];
		this.southEast = [southEast.x, southEast.y];
		this.setView([northWest.x, northWest.y, southEast.x, southEast.y]);

		util.bindTexture(gl, this.previousWindTexture, 0);
		util.bindTexture(gl, this.windTexture, 1);
		util.bindTexture(gl, this.colorRampTexture, 2);

		const program = this.windProgram;
		gl.useProgram(program.program);

		gl.uniformMatrix4fv(program.u_matrix, false, this.matrix);

		gl.uniform1i(program.u_wind_prev, 0);
		gl.uniform1i(program.u_wind_next, 1);
		gl.uniform1i(program.u_color_ramp, 2);
		gl.uniform2f(program.u_north_west, this.northWest[0], this.northWest[1]);
		gl.uniform2f(program.u_south_east, this.southEast[0], this.southEast[1]);

		util.bindAttribute(gl, this.quadBuffer, program.program.a_tex_coord, 2);
		gl.uniform2f(program.u_wind_res, this.height, this.width);
		gl.uniform1f(program.u_opacity, this.opacity);
		gl.uniform2f(program.u_wind_min, this.uDelta[0], this.vDelta[0]);
		gl.uniform2f(program.u_wind_max, this.uDelta[1], this.vDelta[1]);
		if (this.animationEnd) {
			const transition = (this.animationEnd - Date.now()) / this.frameDuration;
			gl.uniform1f(program.u_transition, Math.clamp(1 - transition, 0, 1));
		}

		gl.enable(gl.BLEND);
		gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
		gl.drawArrays(gl.TRIANGLES, 0, 6);
	}
}
