import RasterLayer from '../parent-layers/raster-layer-legacy';
import { accuWeatherLayerApiKey } from '../../../utils/api-keys';
import { windFlowMapLayerCreate } from './wind-flow';
import {
	createWindFlowSubMenu,
	disablewindFlow,
} from '../../../flyout-menu/wind-flow-sub-menu';

export default class WindFlowLayer extends RasterLayer {
	constructor(store) {
		super(store);
		this.layerId = 'wind-flow';
		this.name = 'Wind Flow';
		this.category = 'wx';
		this.animates = true;
		this.legend = true;
		this.changeOpacity = false;
		this.type = 'Custom';
	}

	async addToMap(map) {
		try {
			const response = await fetch(
				`https://${this.store.getters.getAPIEnvironmentURL}/maps/v1/models/gfs/preferred_product_frames?products=26-1020&apikey=${accuWeatherLayerApiKey}`
			);
			const responseJSON = await response.json();
			const respFrames = responseJSON.frames.filter((time, i) => i <= 24);
			super.sendToStore(this.layerId, respFrames);
			let frames = [];
			respFrames.forEach((timestamp) => {
				frames.push({
					url: `${responseJSON.url}custom?topmerc=3.1415&bottommerc=-3.1415&rightmerc=3.1415&leftmerc=-3.1415&imgheight=360&imgwidth=360&display_mode=10&blend=1&display_products=26-1020&frametime=${timestamp}&apikey=${accuWeatherLayerApiKey}`,
					timestamp: timestamp,
					DateTime: new Date(timestamp).toUTCString(),
				});
			});

			this.frames = frames;

			windFlowMapLayerCreate(map, this.frames);
			createWindFlowSubMenu(map);
		} catch (error) {
			console.log(error);
		}
	}

	removeFromMap(map) {
		disablewindFlow(map);
		super.removeLayer(this.layerId);
	}
}
