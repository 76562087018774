import store from '../../../../store';

export default class BaseLayer {
	constructor() {
		this.active = false;
		this.store = store;
	}

	async sendToStore(times) {
		if (this.active) {
			await this.store.dispatch('setBaseFrameTimes', {
				layerId: this.layerId,
				typeOfLayer: this.type,
				timeFrames: times,
			});
		} else {
			await this.store.dispatch('setTopFrameTimes', {
				layerId: this.layerId,
				typeOfLayer: this.type,
				timeFrames: times,
			});
		}
	}

	removeLayer() {
		this.active
			? this.store.dispatch('removeBaseFrames', this.layerId)
			: this.store.dispatch('removeTopFrames', this.layerId);
	}

	handleMaxBounds(map) {
		const activeLayer = this.store.getters.getActiveLayers;
		if (activeLayer?.[0]?.maxBounds) {
			if (!map.getMaxBounds()) {
				map.setMaxBounds([
					[-360, -50],
					[360, 65],
				]);
			}
		} else {
			map.setMaxBounds(null);
		}
	}
}
