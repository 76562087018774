// import { baseLayerFirst } from '../../components/utils/helpers';

export default (store) => {
	store.subscribe((mutation, state) => {
		if (mutation.type === 'MAP_LAYER_SELECT') {
			const map = window.mapObj;

			// Remove Current Base && Top layer from map
			if (state.mapLayers.activeLayers.length) {
				if (state.mapLayers.overlayLayer.length) {
					store.commit('CLEAR_OVERLAY_LAYER');
				}
				let baseLayer = state.mapLayers.activeLayers[0];
				baseLayer.removeFromMap(map);
				baseLayer.active = false;
				state.mapLayers.activeLayers = [];
				state.mapLayers.nonActiveLayers = state.mapLayers.wxLayers;

				// If a new Base layer was selected then add to map
				if (mutation.payload !== baseLayer.name) {
					let newBaseLayer = state.mapLayers.wxLayers.find(
						(l) => l.name === mutation.payload
					);
					state.mapLayers.activeLayers.push(newBaseLayer);
					newBaseLayer.active = true;
					newBaseLayer.addToMap(map);
					newBaseLayer.handleMaxBounds(map);
					state.mapLayers.nonActiveLayers = state.mapLayers.wxLayers.filter(
						(l) => l.name !== newBaseLayer.name
					);
				}
				// A new Base Layer is added to map, NO Previous Base layer
			} else {
				let newBaseLayer = state.mapLayers.wxLayers.find(
					(l) => l.name === mutation.payload
				);
				state.mapLayers.activeLayers.push(newBaseLayer);
				newBaseLayer.active = true;
				newBaseLayer.addToMap(map);
				newBaseLayer.handleMaxBounds(map);
				state.mapLayers.nonActiveLayers = state.mapLayers.wxLayers.filter(
					(l) => l.name !== newBaseLayer.name
				);
			}
		}
		if (mutation.type === 'OVERLAY_LAYER_SELECT') {
			const map = window.mapObj;

			const currentOverlayLayer = state.mapLayers.overlayLayer;
			let newOverlayLayer;

			// no current overlay layer
			if (!currentOverlayLayer.length) {
				newOverlayLayer = state.mapLayers.wxLayers.find(
					(l) => l.name === mutation.payload
				);
				// removing old overlay layer and setting up new one
			} else {
				currentOverlayLayer[0].removeFromMap(map);
				state.mapLayers.overlayLayer = [];
				newOverlayLayer = state.mapLayers.wxLayers.find(
					(l) => l.name === mutation.payload
				);
			}
			// Painting on map new overlay layer
			if (newOverlayLayer) {
				state.mapLayers.overlayLayer.push(newOverlayLayer);
				newOverlayLayer.addToMap(map);

				// baseLayerFirst(map);
			}
		}
		if (
			mutation.type === 'CLEAR_OVERLAY_LAYER' &&
			state.mapLayers.overlayLayer.length
		) {
			const map = window.mapObj;
			const currentOverlayLayer = state.mapLayers.overlayLayer;
			currentOverlayLayer[0].removeFromMap(map);
			state.mapLayers.overlayLayer = [];
		}
	});
};
