export const selectedFeatureValuesAvg = (selectedFeatures) => {
	let values = [];
	selectedFeatures.forEach((value) => {
		if (value?.properties?.overallPlumeLabsIndex) {
			values.push(value.properties.overallPlumeLabsIndex);
		}
	});
	// Adding all of array values together
	const reducer = (accumulator, curr) => accumulator + curr;
	const sum = values.reduce(reducer);
	// Return average of values in the array
	return sum / values.length;
};

export const searchBoxFeatures = (e, layerId, map, dist) => {
	const bbox = [
		[e.point.x - dist, e.point.y - dist],
		[e.point.x + dist, e.point.y + dist],
	];

	// Find features inside the bounding box.
	return map.queryRenderedFeatures(bbox, {
		layers: [`${layerId}-data-points-layer`],
	});
};
