<template>
  <div v-if="showUpdateNotification" class="overlay" @click="closeNotification">
    <div class="notification" @click.stop>
      <div class="notification-header">
        <button class="close-button" @click="closeNotification">&times;</button>
      </div>
      <div class="notification-body">
        <p>A new version of the app is available!</p>
        <p>Check it out.</p>
      </div>
      <div class="notification-footer">
        <button class="update-button" @click.stop="updateApp">Update</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showUpdateNotification: false,
    };
  },
  mounted() {
    window.addEventListener("newcontent", () => {
      this.showUpdateNotification = true;
    });
  },
  methods: {
    closeNotification() {
      this.showUpdateNotification = false;
    },
    updateApp() {
      setTimeout(()=>{
        window.location.reload(true);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  .notification {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    max-width: 500px;
    min-height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-header {
      border-radius: inherit;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      padding: 16px;
      background-color: #f05514;
      .close-button {
        background-color: transparent;
        border: none;
        font-size: 1rem;
        padding: 0;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &-body {
      padding: 12px;
      text-align: center;
    }

    &-footer {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 16px;
      .update-button {
        background-color: #f05514;
        border: none;
        border-radius: 4px;
        color: white;
        padding: 8px 16px;

        &:hover {
          cursor: pointer;
          box-shadow: 0px 0px 10px 0px darkorange;
        }
      }
    }
  }
}
</style>
