<template>
	<div class="smoke-legend">
		<div class="legend-title">
			<b>{{ title }}</b>
		</div>
		<div :class="legendItemsDivClass">
			<span
				v-for="colorBx in colorBoxes"
				:key="colorBx"
				class="color-rectangle"
				:class="colorBx"
			></span>
		</div>
		<div class="legend-footer">
			<span
				v-for="count in colorText"
				:key="count"
				class="footer-text"
			>{{
				count
			}}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SmokeLegend',
	data() {
		return {
			title: 'Near Surface Smoke (ug/m3)',
			colorBoxes: this.legendColorBoxes(),
			colorText: this.legendColorText(),
			legendItemsDivClass: 'legend-item',
		};
	},
	computed: {
		devEnvironment() {
			return this.$store.getters.getDevEnvironment;
		},
	},
	watch: {
		devEnvironment: {
			immediate: true,
			handler() {
				this.devEnvironment
					? (this.legendItemsDivClass = 'legend-item_dev')
					: (this.legendItemsDivClass = 'legend-item');
			},
		},
	},
	methods: {
		legendColorBoxes() {
			let count;
			let countArray = [];
			count = 7;
			for (let index = 1; index <= count; index++) {
				countArray.push(`smoke-${index}`);
			}
			return countArray;
		},
		legendColorText() {
			return ['< 9', '10-24', '25-49', '50-99', '100-149', '150-199', '> 200'];
		},
	},
};
</script>
