export default (store) => {
	store.subscribe((mutation) => {
		if (mutation.type === 'SELECT_IMPACT_TYPE') {
			let map = window.mapObj;
			const layerName = mutation.payload.replaceAll(' ', '_');

			map.getStyle().layers.forEach((layer) => {
				if (layer.id.includes(layerName)) {
					if (layer.layout.visibility === 'visible') {
						map.setLayoutProperty(layer.id, 'visibility', 'none');
					}
					if (layer.layout.visibility === 'none') {
						map.setLayoutProperty(layer.id, 'visibility', 'visible');
					}
				}
				if (
					layer.id.includes('tropicalImpacts-') &&
					!layer.id.includes(layerName)
				) {
					map.setLayoutProperty(layer.id, 'visibility', 'none');
				}
			});
		}

		if (mutation.type === 'REMOVE_ALL_IMPACT_LAYERS') {
			let map = window.mapObj;
			map.getStyle().layers.forEach((layer) => {
				if (layer.id.includes('tropicalImpacts-')) {
					map.setLayoutProperty(layer.id, 'visibility', 'none');
				}
			});
		}
	});
};
