import { getImagePath } from '../utils/storm-icon-service';
import { displayEyePathIcons } from '../fep-view/build-eye-path-storm-icons';
import {
	addStormHistoricalTrackLineToMap,
	addStormForecastTrackLineToMap,
} from '../map-addLayers/add-eye-path-track-lines';

// BUILDS TRACK COLLECTIONS
export const eyePathTrackData = (map, stormInfo) => {
	let stormForecastPosCollection = [];
	let stormHistoricalPosCollection = [];
	let date = '';
	let status = '';
	let shortDate = '';

	if (stormInfo.historical.length > 0) {
		stormInfo.historical
			.sort((a, b) => (a.validDateTime > b.validDateTime ? 1 : -1))
			.forEach((s, i) => {
				shortDate = new Date(s.validDateTime).toLocaleDateString('en', {
					month: 'long',
					day: 'numeric',
					year: 'numeric',
				});

				i === stormInfo.historical.length - 1
					? (s.type = 'current')
					: (s.type = 'historical');

				if (date === shortDate && status === s.status && s.type !== 'current') {
					s.imagePath = 'eye-path/historical-unmarked.png';
					s.unmarked = true;
				} else {
					s.imagePath = `${getImagePath(s, 'eye-path')}.png`;
					s.unmarked = false;
				}
				date = shortDate;
				status = s.status;
			});
	}
	if (stormInfo.forecast.length > 0) {
		stormInfo.forecast
			.sort((a, b) => (a.validDateTime > b.validDateTime ? 1 : -1))
			.forEach((s) => {
				shortDate = new Date(s.validDateTime).toLocaleDateString('en', {
					month: 'long',
					day: 'numeric',
					year: 'numeric',
				});

				s.type = 'forecast';

				if (shortDate === date && status === s.status) {
					s.imagePath = 'eye-path/forecast-unmarked.png';
					s.unmarked = true;
				} else {
					s.imagePath = `${getImagePath(s, 'eye-path')}.png`;
					s.unmarked = false;
				}
				date = shortDate;
				status = s.status;
			});
	}

	const eyePathCombSorted = stormInfo.historical.concat(stormInfo.forecast);

	displayEyePathIcons(map, eyePathCombSorted);

	eyePathCombSorted.forEach((pos, index) => {
		if (Object.keys(pos).length !== 0) {
			if (index <= stormInfo.historical.length - 1) {
				stormHistoricalPosCollection.push([
					pos.position.longitude,
					pos.position.latitude,
				]);
				if (index === stormInfo.historical.length - 1) {
					stormForecastPosCollection.push([
						pos.position.longitude,
						pos.position.latitude,
					]);
				}
			} else if (index >= stormInfo.historical.length - 1) {
				stormForecastPosCollection.push([
					pos.position.longitude,
					pos.position.latitude,
				]);
			}
		}
	});

	// Adding storm track lines to the map
	addStormHistoricalTrackLineToMap(map, stormHistoricalPosCollection);
	addStormForecastTrackLineToMap(map, stormForecastPosCollection);
};
