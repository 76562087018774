<template>
	<div class="radar-legend">
		<div class="legend-item rain">
			<span class="legend-text">Rain</span>
			<span class="color-dot radColor-1"></span>
			<span class="color-dot radColor-2"></span>
			<span class="color-dot radColor-3"></span>
			<span class="color-dot radColor-4"></span>
		</div>
		<div class="legend-item snow">
			<span class="legend-text">Snow</span>
			<span class="color-dot radColor-1"></span>
			<span class="color-dot radColor-2"></span>
			<span class="color-dot radColor-3"></span>
			<span class="color-dot radColor-4"></span>
		</div>
		<div class="legend-item ice">
			<span class="legend-text">Ice</span>
			<span class="color-dot radColor-1"></span>
			<span class="color-dot radColor-2"></span>
			<span class="color-dot radColor-3"></span>
			<span class="color-dot radColor-4"></span>
		</div>
		<div class="legend-item mix">
			<span class="legend-text">Mix</span>
			<span class="color-dot radColor-1"></span>
			<span class="color-dot radColor-2"></span>
			<span class="color-dot radColor-3"></span>
			<span class="color-dot radColor-4"></span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RadarLegend',
};
</script>
