import { getFirstSymbolLayer } from '../../../utils/helpers';
import store from '../../../../store';

import RasterLayer from '../parent-layers/raster-layer-legacy';
import { accuWeatherLayerApiKey } from '../../../utils/api-keys';

import LocalRadarMapLayer from './shaders/local-radar-layer';

export default class LocalRadarLayer extends RasterLayer {
	constructor() {
		super();
		this.layerId = 'local-radar';
		this.name = 'Single-Site Radar';
		this.category = 'wx';
		this.animates = false;
		this.legend = false;
		this.changeOpacity = false;
		this.type = 'Custom';
	}

	async addToMap(map) {
		try {
			if (!this.siteList) {
				const tilt = '11001';
				const getSitesTask = await fetch(
					`https://${store.getters.getAPIEnvironmentURL}/maps/v1/radar/usLocalRad/get_sites?tilt=${tilt}&apikey=${accuWeatherLayerApiKey}`
				);
				const sitesJson = await getSitesTask.json();

				const siteList = sitesJson.products.join(',');
				this.siteList = siteList;
			}

			if (!this.siteInfo) {
				const getSiteInfoTask = await fetch(
					`https://${store.getters.getAPIEnvironmentURL}/maps/v1/radar/usLocalRad/get_sites_info?id=${this.siteList}&apikey=${accuWeatherLayerApiKey}`
				);
				const siteInfo = await getSiteInfoTask.json();
				this.siteInfo = siteInfo.sites_info.filter((s) => !s.missing);
			}

			const geojson = {
				type: 'FeatureCollection',
				features: this.siteInfo
					.filter((s) => !!s.site_name)
					.map((site) => {
						return {
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [site.lon, site.lat],
							},
							properties: {
								siteName: site.site_name,
								pid: site.pid,
							},
						};
					}),
			};

			map.addSource('radar-sites', {
				type: 'geojson',
				data: geojson,
			});

			map.addLayer({
				id: 'local-radar',
				type: 'circle',
				source: 'radar-sites',
				paint: {
					'circle-radius': 8,
					'circle-color': '#fff',
				},
			});

			this.mapEvent = map.on('click', 'local-radar', this.handleRadarSelection);
		} catch (error) {
			console.log(error);
		}
	}

	async handleRadarSelection(e) {
		if (this.localRadarLayer) {
			this.removeLayer('highlight');
			this.localRadarLayer = null;
		}

		try {
			const feature = e.features[0];

			const getSiteTimesTask = await fetch(
				`https://${store.getters.getAPIEnvironmentURL}/maps/v1/radar/usLocalRad/get_site_times?id=${feature.properties.pid}&apikey=${accuWeatherLayerApiKey}`
			);
			const siteTimes = await getSiteTimesTask.json();

			const getSiteInfoTask = await fetch(
				`https://${store.getters.getAPIEnvironmentURL}/maps/v1/radar/usLocalRad/get_sites_info?id=${feature.properties.pid}&apikey=${accuWeatherLayerApiKey}`
			);
			const siteInfo = await getSiteInfoTask.json();

			const urls = [];
			siteTimes.times
				.slice(siteTimes.times.length - 9, siteTimes.times.length - 1)
				.forEach((t) => {
					urls.push(
						`https://${store.getters.getAPIEnvironmentURL}/maps/v1/radar/usLocalRad/get_site_data?id=${feature.properties.pid}&frametime=${t}&apikey=${accuWeatherLayerApiKey}`
					);
				});

			const radarLayer = new LocalRadarMapLayer(
				'highlight',
				siteInfo.sites_info[0],
				urls
			);
			this.addLayer(radarLayer, getFirstSymbolLayer(this));
			this.localRadarLayer = radarLayer;
		} catch (err) {
			console.error(err);
			this.removeLayer('highlight');
		}
	}

	removeFromMap(map) {
		super.removeLayer(this.layerId);
		map.removeLayer('highlight');
		map.removeLayer('local-radar');
		map.removeSource('radar-sites');
		map.off('click', 'local-radar', this.handleRadarSelection);
	}
}
