// This sets the bounding box to focus in on the storm that was clicked
export const setStormBoundingBox = (map, selectedStormInfo) => {
  // Variables for getting boundry box edge limits
  let latCollection = [];
  let longCollection = [];

  // For the historical track
  if (selectedStormInfo?.historical?.length > 0) {
    selectedStormInfo.historical.forEach(
      (l) => (
        latCollection.push(l.position.latitude), // push all latitudes into array to find min and max from
        longCollection.push(l.position.longitude) // push all longitudes into array to find min and max from
      )
    );
  }
  // For the future track
  if (selectedStormInfo?.forecast?.length > 0) {
    selectedStormInfo.forecast.forEach(
      (l) => (
        latCollection.push(l.position.latitude), // push all latitudes into array to find min and max from
        longCollection.push(l.position.longitude) // push all longitudes into array to find min and max from
      )
    );
  }

  // Setting variables for the min and max lat/longs for historical and forecast track points
  let maxLat = Math.max(...latCollection);
  let minLat = Math.min(...latCollection);
  let maxLon = Math.max(...longCollection);
  let minLon = Math.min(...longCollection);

  // Getting the margin/padding needed around the storm track
  const marginLat = (maxLat - minLat) * 0.1;
  const marginLon = (maxLon - minLon) * 0.1;
  // Adding the margin to min/max of lat/long
  minLon -= marginLon;
  minLat -= marginLat;
  maxLon += marginLon;
  maxLat += marginLat;

  // Make boundry box to focus in on storm
  setMapBounds(map, minLon, minLat, maxLon, maxLat);
};

// Needed to change the map bounds box
export const setMapBounds = (map, l1, l2, r1, r2) => {
  map.fitBounds([
    [l1, l2], // southwestern corner of the bounds
    [r1, r2], // northeastern corner of the bounds
  ]);
};


/*
Compare current Longitude to previous to see if the current point crosses the 180th meridian.
Example: Previous coord = [179/-179] and Current coord [-179/179].
*/
export const adjustPolygonLongitudes = (polygon) => {
  let coords = polygon?.coordinates[0];
  let previousLng;
  coords = coords.forEach((coord, index) => {
    let currentLng = coord[0];
    if (index > 0) {
      previousLng = coords[index - 1][0];
    }
    currentLng += currentLng - previousLng > 180
		? -360
        : previousLng - currentLng > 180
        ? 360
        : 0;

    coord[0] = currentLng;
  });

  return polygon;
};

export const adjustMultiPolygonLongitudes = (polygons) => {
  polygons.coordinates.forEach((subPolygon) => {
    let coords = subPolygon[0];
    let previousLng;
    coords = coords.forEach((coord, index) => {
      let currentLng = coord[0];
      if (index > 0) {
        previousLng = coords[index - 1][0];
      }
      currentLng += currentLng - previousLng > 180
      ? -360
          : previousLng - currentLng > 180
          ? 360
          : 0;
  
      coord[0] = currentLng;
    });
  
  })
  return polygons;
};

export const adjustLongitudes = (coords) => {
  let previousLng;
  coords.forEach((coord, index) => {
    let currentLng = coord[0];
    if (index > 0) {
      previousLng = coords[index - 1][0];
    }
    currentLng += currentLng - previousLng > 180
		? -360
        : previousLng - currentLng > 180
        ? 360
        : 0;

    coord[0] = currentLng;
  });

  return coords;
};
