import BaseLayer from '../parent-layers/base-layer';
import { buildActiveStormCollection } from './tropical-class-utils/active-storm-view/create-active-storms-data';
import { removeSubMenu } from '../../../flyout-menu/active-storms-submenu';
import { setMapBounds } from './tropical-class-utils/utils/tropical-map-bounds';

export default class TropicalLayer extends BaseLayer {
	constructor(store) {
		super(store);
		this.name = 'Tropical Storms';
		this.type = 'GeoJSON';
		this.category = 'wx';
	}

	/*=====================================

	 Two main class methods: Add/Remove from map

	=====================================*/

	// Call to ACTIVATE the Tropical Storms from MENU
	async addToMap(map) {
		// Setting bounds for map so the inital load shows all oceans
		setMapBounds(map, -240, -60, 120, 72);

		// build out a collection of Tropical Storms returned from the SDK to be placed on the map
		buildActiveStormCollection(map, this.store);

		this.mapInteraction(map);
	}
	// Call to DEACTIVATE the Tropical Storms from MENU
	removeFromMap(map) {
		// remove mapbox layers
		map.getStyle().layers.forEach((layer) => {
			if (
				layer.id.includes('tropical-category-layer-stormTrackLineHistorical') ||
				layer.id.includes('tropical-category-layer-stormTrackLineForecast') ||
				layer.id.includes('atcfPlots-') ||
				layer.id.includes('trackRadials-') ||
				layer.id.includes('trackWindow-') ||
				layer.id.includes('tropicalImpacts-')
			) {
				map.removeLayer(layer.id);
			}
		});
		for (let [key] of Object.entries(map.getStyle().sources)) {
			if (
				key.includes('stormTrackLineHistorical') ||
				key.includes('stormTrackLineForecast') ||
				key.includes('atcfPlots-') ||
				key.includes('trackRadials-') ||
				key.includes('trackWindow-') ||
				key.includes('tropicalImpacts-')
			) {
				map.removeSource(key);
			}
		}

		// remove custom markers
		const stormComponents = [
			...document.querySelectorAll(
				`.current-storm-position,
				.current-storm-position-date-box,
				.current-info-box-line,
				.storm-position-date-box,
				.info-box-line,
				.storm-position,
				.storm-position-date-box-detailed,
				.info-box-line-detailed,
				.active-storm-position-date-box-detailed,
				.active-storm-position-date-box-detailed-image,
				.active-storm-info-box-line-detailed,
				.active-storm-position,
				.mock-data-notification-banner`
			),
		];

		stormComponents.forEach((m) => {
			m.remove();
		});

		// remove submenu
		removeSubMenu();
	}

	/*=====================================

	  Helper functions for MAP INTERACTION

	=====================================*/

	mapInteraction(map) {
		// ON ZOOM
		map.on('zoomstart', () => {
			const oldDetailedDateBox = document.querySelector(
				'.storm-position-date-box-detailed'
			);
			const oldDetailedDateBoxLine = document.querySelector(
				'.info-box-line-detailed'
			);
			if (oldDetailedDateBox !== null) {
				oldDetailedDateBox.remove();
				oldDetailedDateBoxLine.remove();
			}
		});
	}
}
