import mapboxgl from 'mapbox-gl';

let currentlyHiddenDateBox;
let currentlyHiddenDateLine;

let lastLabelDirection;

export const displayEyePathIcons = (map, storm) => {
	storm.forEach((pos, i) => {
		let boxChanges = [];
		const lat = pos.position.latitude;
		const lon = pos.position.longitude;
		const dateMarkerText = new Date(pos.validDateTime).toLocaleDateString(
			'en',
			{ month: 'short', day: 'numeric' }
		);

		let dateBox = document.createElement('div');
		let dateBoxLine = document.createElement('div');
		const stormIconElement = document.createElement('div');
		const stormIconImage = stormIconElement.appendChild(
			document.createElement('img')
		);

		stormIconImage.src = require(`../../../../../../assets/images/tropical/${pos.imagePath}`);
		stormIconImage.style.cursor = 'pointer';
		if (pos.type === 'current') {
			stormIconImage.style.width = '2.5rem';
			stormIconImage.style.height = '2.5rem';
		} else {
			stormIconImage.style.width = pos.unmarked === true ? '.6rem' : '1.8rem';
			stormIconImage.style.height = pos.unmarked === true ? '.6rem' : '1.8rem';
		}

		// Changes the class name for this specific marker which is current position
		let markerInnerHtml = '';
		if (pos.type === 'current') {
			stormIconImage.className = 'current-storm-position';
			stormIconImage.id = `current-storm-position${i}`;
			dateBoxLine.className = 'current-info-box-line';
			dateBoxLine.id = `current-info-box-line${i}`;
			dateBox.className = 'current-storm-position-date-box';
			dateBox.id = `current-storm-position-date-box${i}`;

			dateBox.style.color = '#000';
			markerInnerHtml = `<b>${dateMarkerText.toLocaleUpperCase()}</b>`;
		} else {
			stormIconImage.className = 'storm-position';
			stormIconImage.id = `storm-position${i}`;
			dateBoxLine.className = 'info-box-line';
			dateBoxLine.id = `info-box-line${i}`;
			dateBox.className = 'storm-position-date-box';
			dateBox.id = `storm-position-date-box${i}`;

			if (pos.unmarked === false) {
				dateBox.style.color = '#666c72';
				markerInnerHtml = `<span>${dateMarkerText.toLocaleUpperCase()}</span>`;
			}
		}

		if (pos.unmarked === false) {
			if (i < storm.length - 1) {
				boxChanges = calculatePlacement(
					dateBox,
					dateBoxLine,
					lat, // cLat
					lon, // cLon
					storm[i + 1].position.latitude, // nLat
					storm[i + 1].position.longitude // nLon
				);
			} else {
				boxChanges = calculatePlacement(
					dateBox,
					dateBoxLine,
					storm[i - 1].position.latitude, // cLat
					storm[i - 1].position.longitude, // cLon
					lat, // nLat
					lon // nLon
				);
			}

			dateBox = boxChanges[0];
			dateBoxLine = boxChanges[1];

			dateBoxLine.classList.add('eye-path-date-box-line');

			dateBox.classList.add('eye-path-date-box');
			dateBox.innerHTML = markerInnerHtml;

			// Adding Date Box and Icons to map
			new mapboxgl.Marker(dateBoxLine)
				.setLngLat([pos.position.longitude, pos.position.latitude])
				.addTo(map);
			new mapboxgl.Marker(dateBox)
				.setLngLat([pos.position.longitude, pos.position.latitude])
				.addTo(map);
		}

		let eventPassed = (e) => changeContentToDetailedDateBox(e, map, pos);
		stormIconElement.onclick = eventPassed;

		new mapboxgl.Marker(stormIconElement)
			.setLngLat([pos.position.longitude, pos.position.latitude])
			.addTo(map);
	});

	// This will set the markers as visible or not with the icons as well
	const markers = [
		...document.querySelectorAll(
			'.storm-position-date-box, .info-box-line, .storm-position'
		),
	];

	map.on('zoom', () => {
		markers.forEach((m) => {
			let id = m.id.match(/\d+/g);

			if (id % 2 === 0) {
				if (map.getZoom() <= 3.5) {
					m.style.display = 'none';
				} else {
					m.style.display = 'block';
				}
			} else {
				if (map.getZoom() <= 4.5) {
					m.style.display = 'none';
				} else {
					m.style.display = 'block';
				}
			}
		});
	});
};

const calculatePlacement = (dateBox, dateBoxLine, cLat, cLon, nLat, nLon) => {
	if (cLon - 180 > nLon - 180 && cLat + 90 < nLat + 90) {
		// console.log(`UP`);
		// `Current is on the Top - Right of next` --> UP
		if (lastLabelDirection !== 'up') {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'up'
			);

			lastLabelDirection = 'up';
		} else {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'down'
			);

			lastLabelDirection = 'down';
		}
	} else if (cLon - 180 > nLon - 180 && cLat + 90 > nLat + 90) {
		// console.log(`DOWN`);
		// `Current is on the Bottom - Right of next` --> DOWN
		if (lastLabelDirection !== 'down') {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'down'
			);

			lastLabelDirection = 'down';
		} else {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'up'
			);

			lastLabelDirection = 'up';
		}
	} else if (cLon - 180 < nLon - 180 && cLat + 90 > nLat + 90) {
		// console.log(`RIGHT`);
		// `Current is on the Top - Left of next` --> RIGHT
		if (lastLabelDirection !== 'right') {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'right'
			);

			lastLabelDirection = 'right';
		} else {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'left'
			);

			lastLabelDirection = 'left';
		}
	} else if (cLon - 180 < nLon - 180 && cLat + 90 < nLat + 90) {
		// console.log(`LEFT`);
		// `Current is on the Bottom - Left of next` --> LEFT

		if (lastLabelDirection !== 'left') {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'left'
			);

			lastLabelDirection = 'left';
		} else {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'right'
			);

			lastLabelDirection = 'right';
		}
	} else {
		if (lastLabelDirection === 'up') {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'down'
			);

			lastLabelDirection = 'down';
		} else if (lastLabelDirection === 'right') {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'left'
			);

			lastLabelDirection = 'left';
		} else if (lastLabelDirection === 'left') {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'right'
			);

			lastLabelDirection = 'right';
		} else {
			[dateBox, dateBoxLine] = detailBoxPlacementChanges(
				dateBox,
				dateBoxLine,
				'up'
			);

			lastLabelDirection = 'up';
		}
	}

	return [dateBox, dateBoxLine];
};

const detailBoxPlacementChanges = (box, line, direction) => {
	if (box.className === `current-storm-position-date-box`) {
		box.classList.add(`eye-path-label-${direction}_current`);
		line.classList.add(`eye-path-line-${direction}_current`);
	} else {
		box.classList.add(`eye-path-label-${direction}`);
		line.classList.add(`eye-path-line-${direction}`);
	}

	return [box, line];
};

const changeContentToDetailedDateBox = (e, map, pos) => {
	// Look to see if these elements already exist
	const oldDetailedDateBox = document.querySelector(
		'.storm-position-date-box-detailed'
	);
	const oldDetailedDateBoxLine = document.querySelector(
		'.info-box-line-detailed'
	);

	// If there is a detailed box already showing, then remove it
	if (oldDetailedDateBox !== null) {
		oldDetailedDateBox.remove();
		oldDetailedDateBoxLine.remove();
	}
	// Check if there is a date box or line hidden, if so, bring them back
	if (currentlyHiddenDateBox !== undefined) {
		currentlyHiddenDateBox.style.display = 'block';
		currentlyHiddenDateLine.style.display = 'block';
	}
	// Creating a new detailed date box and line
	let detailedDateBox = document.createElement('div');
	detailedDateBox.className = 'storm-position-date-box-detailed';
	let detailedDateBoxLine = document.createElement('div');
	detailedDateBoxLine.className = 'info-box-line-detailed';

	// The date to show up on the detailed box
	const dateMarkerText = new Date(pos.validDateTime).toLocaleDateString('en', {
		month: 'short',
		day: 'numeric',
	});

	detailedDateBox.innerHTML = `
	<span style="margin-top: auto;">${pos.status}</span>
	<span style="margin-top: auto;">${dateMarkerText}</span>`;

	const last = e.target.id.match(/\d+/g);
	if (pos.unmarked === false) {
		currentlyHiddenDateBox = document.getElementById(
			pos.type === 'current'
				? `current-storm-position-date-box${last}`
				: `storm-position-date-box${last}`
		);
		currentlyHiddenDateBox.style.display = 'none';
		currentlyHiddenDateLine = document.getElementById(
			pos.type === 'current'
				? `current-info-box-line${last}`
				: `info-box-line${last}`
		);
		currentlyHiddenDateLine.style.display = 'none';
	}

	new mapboxgl.Marker(detailedDateBoxLine)
		.setLngLat([pos.position.longitude, pos.position.latitude])
		.addTo(map);
	new mapboxgl.Marker(detailedDateBox)
		.setLngLat([pos.position.longitude, pos.position.latitude])
		.addTo(map);
};
