import { getFirstSymbolLayer } from '../../../utils/helpers';
import WindSpeedLayer from './layers/windspeed-layer';
import WindParticleLayer from './layers/windparticle-layer';

let windSpeedLayer;
let windParticleLayer;
let frames;

export const windFlowMapLayerCreate = (mapCon, windFlowFrames) => {
	const map = mapCon;

	windSpeedLayer;
	windParticleLayer;
	frames = windFlowFrames;

	function getWidth(isFullscreen) {
		const clientWidth = document.documentElement.clientWidth;
		let width;
		if (clientWidth < 768) {
			width = clientWidth;
		} else if (clientWidth < 1024) {
			width = isFullscreen ? clientWidth : 256;
		} else {
			width = isFullscreen ? clientWidth : 512;
		}
		return width;
	}

	if (frames) {
		windSpeedLayer = new WindSpeedLayer('windspeed', frames);
		windSpeedLayer.enabled = true;
		windSpeedLayer.frameDuration = 750;
		map.addLayer(windSpeedLayer, getFirstSymbolLayer(map));

		windParticleLayer = new WindParticleLayer(
			'windparticles',
			frames,
			getWidth(false)
		);
		windParticleLayer.frameDuration = 750;
		windParticleLayer.windEnabled = true;
		map.addLayer(windParticleLayer, getFirstSymbolLayer(map));
	} else {
		console.error('Failed to pull preferred frames');
	}
};

export function changeFrame(frame) {
	if (windSpeedLayer) {
		windSpeedLayer.changeFrame(frame);
	}
	if (windParticleLayer) {
		windParticleLayer.changeFrame(frame);
	}
}

export function windFlowLayerfeatureSelection(contourEnabled) {
	if (windSpeedLayer) {
		windSpeedLayer.enabled = contourEnabled;
	}
	if (windParticleLayer) {
		windParticleLayer.windEnabled = contourEnabled;
	}
}
