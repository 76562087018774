import RasterLayer from '../parent-layers/raster-layer';

export default class SnowForecast extends RasterLayer {
	constructor(apiKey) {
		super(apiKey);
		this.layerId = 'snow-forecast';
		this.name = 'Snow Forecast';
		this.category = 'wx';
		this.legend = true;
		this.animates = true;
		this.opacity = 0.7;
		this.frames = [];
		this.frameTimes = [];
	}

	async addToMap(map) {
		let layerMeta = await super.getApiData({
			name: this.layerId,
			url: `models/ufdb/precip/preferred_product_frames?&apikey=${this.apiKey}&products=111-1010`,
		});
		let dt = new Date(Date.now());
		dt.setMinutes(0, 0, 0);
		const dtISO = dt.toISOString();
		const forecastFrames = layerMeta?.frames.filter(
			(frame) => dtISO <= new Date(frame).toISOString()
		);
		this.frames =
			super
				.sortFrames(forecastFrames)
				.map((l) =>
					super.mapFrameAPI(
						this.layerId,
						l,
						`${layerMeta?.url}`,
						`&display_products=111-1010`
					)
				) || [];
		super.tryAddFrames(map, this.frames);
	}

	async removeFromMap(map) {
		await super.removeFromMap(map);
	}

	// This is being kept as we can revisit this when we come back to add geojson into the layer and animate the values
	/*
		addSnowForecastValuesLayer(map, layerId, frames) {
			// Adding the data values to the map
			map.addSource(`${layerId}-data-points-source`, {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [],
				},
			});

			map.addLayer(
				{
					id: `${layerId}-data-points-layer`,
					type: 'symbol',
					source: `${layerId}-data-points-source`,
					layout: {
						'text-field': [
							'concat',
							['get', `snow-${frames[0]}`],
							'cm',
						],
						'text-size': 18,
						'text-font': ['Solis Bold', 'Arial Unicode MS Regular'],
						'text-ignore-placement': true,
					},
					paint: {
						'text-color': '#fff',
						'text-halo-width': 1,
						'text-halo-color': '#333A42',
					},
				},
				'settlement-label'
			);
		}

		// Everytime map is moved or zoomed the data values will be re-rendered within a bounding box
		async updateDataPoints(map, layerId) {
			// setting max and min for lat lng for error handling
			const bounds = map.getBounds();
			const northWest = latLonToValidString(bounds.getNorthWest(), true);
			const southEast = latLonToValidString(bounds.getSouthEast(), false);

			await this.dataPoints(map, northWest, southEast).then((data) => {
				if (data) {
					map.getSource(`${layerId}-data-points-source`).setData(data);
				}
			});
		}
		async dataPoints(map, nW, sE) {
			let limit = 200;
			const response = await fetch(
				`https://www.accuweather.com/web-api/geojson?language=en-us&upperLeft=${nW}&lowerRight=${sE}&metric=false&limit=${limit}&hourlyType=snow&timestamp=${timeStamp}`
			);
			return await response.json();
		}
	*/
}
