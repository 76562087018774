import acuSDKTropical from '../../../../../../services/api/tropical-service';
import { buildActiveStormsMockData } from '../mock-data/active-storms-mock-data';
import { displayActiveStormIcons } from '../active-storm-view/build-active-storm-icons';
import { notifyUserOfMockData } from '../mock-data/user-notification-of-mock-data';

/*=====================================

	Data Service Call for Tropical Storms

=====================================*/

export async function buildActiveStormCollection(map, store) {
	let activeStorms = [];
	let activeStormsInfo = [];
	activeStorms = await acuSDKTropical.activeStormsAsync().then((data) => {
		return data;
	});

	if (activeStorms.length === 0) {
		notifyUserOfMockData();
		let { info, current } = buildActiveStormsMockData();
		activeStormsInfo.push({
			info: info,
			current: current,
		});
		addActiveStormsToMap(map, store, activeStormsInfo);
		return;
	}

	// Loop to add current storm info and future storm info to map activeStorm obj
	for (let i = 0; i < activeStorms.length; i++) {
		await acuSDKTropical
			.stormCurrentPositionAsync(activeStorms[i])
			.then((data) => {
				activeStormsInfo.push({
					info: activeStorms[i],
					current: data,
				});
			});
	}
	addActiveStormsToMap(map, store, activeStormsInfo);
}

/*=====================================

	Adding Tropical Storms to map

=====================================*/
const addActiveStormsToMap = (map, store, stormInfo) => {
	displayActiveStormIcons(map, store, stormInfo);
};
