import { getFirstSymbolLayer } from '../../../utils/helpers';
import { accuWeatherLayerApiKey } from '../../../utils/api-keys';
import RasterLayer from '../parent-layers/raster-layer-legacy';

export default class WildfirePotentialLayer extends RasterLayer {
	constructor() {
		super();
		this.layerId = 'wildfire-potential';
		this.name = 'Wildfire Potential';
		this.category = 'wx';
		this.animates = true;
		this.opacity = 1;
	}

	async addToMap(map) {
		await super
			.getApiData({
				url: `current/fire/preferred_product_frames?apikey=${accuWeatherLayerApiKey}`,
			})
			.then((layersMeta) => {
				// Fire obj and sorting frames
				let fireMeta = layersMeta;
				this.frames = fireMeta?.frames.sort((a, b) => (a > b ? 1 : +1));

				super.sendToStore(this.layerId, fireMeta.frames);

				this.frames.forEach((frame, index) => {
					let tileURL = `${fireMeta.url}zxy/${frame}/{z}/{x}/{y}.png?apikey=${accuWeatherLayerApiKey}`;

					map.addLayer(
						{
							id: `${this.layerId}-${frame}`,
							source: {
								tiles: [`${tileURL}`],
								type: 'raster',
								tileSize: 256,
							},
							type: 'raster',
							paint: {
								'raster-opacity': index === 0 ? this.opacity : 0,
								'raster-opacity-transition': {
									duration: 0,
								},
							},
						},
						getFirstSymbolLayer(map)
					);
				});
			});
	}

	removeFromMap(map) {
		map.getStyle().layers.forEach((layer) => {
			if (layer.id.includes(this.layerId)) {
				map.removeLayer(layer.id);
				map.removeSource(layer.id);
			}
		});
		super.removeLayer(this.layerId);
	}
}
