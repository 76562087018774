<template>
	<div class="air-quality-legend">
		<div class="air-quality-legend-small">
			<span class="legend-text">Excellent</span>
			<div class="color-bar"></div>
			<span class="legend-text">Dangerous</span>
		</div>
		<div class="air-quality-legend-large">
			<div class="legend-item aqColors">
				<span class="legend-text">Excellent</span>
				<span class="color-line aqColor-excellent"></span>
			</div>
			<div class="legend-item aqColors">
				<span class="legend-text">Fair</span>
				<span class="color-line aqColor-fair"></span>
			</div>
			<div class="legend-item aqColors">
				<span class="legend-text">Poor</span>
				<span class="color-line aqColor-poor"></span>
			</div>
			<div class="legend-item aqColors">
				<span class="legend-text">Unhealthy</span>
				<span class="color-line aqColor-unhealthy"></span>
			</div>
			<div class="legend-item aqColors">
				<span class="legend-text">Very Unhealthy</span>
				<span class="color-line aqColor-very-unhealthy"></span>
			</div>
			<div class="legend-item aqColors">
				<span class="legend-text">Dangerous</span>
				<span class="color-line aqColor-dangerous"></span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AirQualityLegend',
};
</script>
